import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { useAdminClients } from './useAdminClients'
import { DateTimeNow } from '../../../../utils/dates'
import {
  addSubDocument,
  deleteSubCollection,
  getDocument,
  getSubDocument,
  getSubDocuments,
  setSubDocument,
  updateDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { GetUserAuthenticatorFromEmail } from '../../../../firebase/functions'
import { NewUser } from '../../../../utils/requests'
import { resetPassword } from '../../../../firebase/auth'
import { GetStatusClient } from '../_utils'

export const useEditClients = () => {
  const { id } = useParams()
  const { setAlerts, user, setLoading, setFormContext, formContext } = useGlobalContext()

  const { FetchClientInfos } = useAdminClients()

  const status = GetStatusClient(formContext?.status)

  const EditClient = async () => {
    console.log(user.user)
    if (user.user.permissions.clientes_editar) {
      delete formContext.responsavel.uid
      const newClient = {
        razaoSocial: formContext.infoClient.razaoSocial,
        cpfCnpj: formContext.infoClient.cpfCnpj,
        nomeFantasia: formContext.infoClient.nomeFantasia,
        endereco: { ...formContext.endereco },
        responsavel: { ...formContext.responsavel },
        planos: [...Object.keys(formContext.assinaturas)],
      }

      const newHistory = {
        modified_by: user?.user?.nome,
        dt_modified: DateTimeNow(),
      }

      await deleteSubCollection('clientes', id, 'assinaturas')

      const promises = Object.keys(formContext.assinaturas).map(async (idPlano) => {
        await setSubDocument('clientes', id, 'assinaturas', idPlano, {
          ...formContext.assinaturas[idPlano],
        })
      })

      Promise.all(promises)

      await updateDocument('clientes', id, newClient)
      await addSubDocument('clientes', id, 'history', newHistory)
      return window.location.reload()
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para editar clientes',
        },
      ])
    }
  }

  const getClientInfos = async () => {
    // Reseta o contexto do formulário antes de buscar os dados
    await setFormContext({})

    // Obtém informações do cliente e assinaturas
    const [clientInfo, assinaturas] = await Promise.all([
      getDocument('clientes', id),
      getSubDocuments('clientes', id, 'assinaturas'),
    ])

    // Mapeia as assinaturas para um objeto com o ID como chave
    clientInfo.assinaturas = assinaturas.reduce((acc, info) => {
      acc[info.id] = info
      return acc
    }, {})

    // Define as informações principais do cliente
    clientInfo.infoClient = {
      nomeFantasia: clientInfo.nomeFantasia,
      razaoSocial: clientInfo.razaoSocial,
      cpfCnpj: clientInfo.cpfCnpj,
    }

    // Verifica se o e-mail do responsável está presente
    const email = clientInfo?.responsavel?.email
    if (email) {
      // Obtém o UID do responsável e adiciona à informação do responsável
      const uid = await GetUserAuthenticatorFromEmail(email)
      console.log(uid)
      try {
        const infoUserAdmin = await getSubDocument('clientes', id, 'users', uid.uid)
        clientInfo.userAdmin = {
          ...infoUserAdmin,
        }
        console.log(infoUserAdmin)
      } catch (error) {}

      clientInfo.responsavel = {
        ...clientInfo.responsavel,
      }
    }

    // Atualiza o contexto do formulário com os dados do cliente
    await setFormContext(clientInfo)
  }

  const PrintPage = () => {
    if (!user?.user?.permissions?.clientes_imprimir === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você Não Possui permissão para imprimir os dados do cliente',
          type: 'error',
        },
      ])
    }

    window.print()
  }

  const ResetPasswordAdmin = async () => {
    if (!user?.user?.permissions?.clientes_resetarSenhaAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para resetar a senha de administrador do cliente',
          type: 'error',
        },
      ])
    }

    const sendEmailReset = await resetPassword(formContext?.responsavel?.email)
    console.log('sendEmailReset:', sendEmailReset)
    if (sendEmailReset.success) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Resete de Senha Enviado',
          msg: `Um E-mail para trocar senha foi enviado ao e-mail do responsavel: ${formContext?.responsavel?.email}`,
          type: 'success',
        },
      ])
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Erro ao enviar E-mail',
          msg: `erro: ${sendEmailReset?.error}`,
          type: 'error',
        },
      ])
    }
  }

  const addUserAdmin = async () => {
    if (!user?.user?.permissions?.clientes_criarUsuarioAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para Criar um usuário administrador para o cliente',
          type: 'error',
        },
      ])
    }

    let permissionsAdmins
    let SectorId
    try {
      permissionsAdmins = await getSubDocument('clientes', id, 'sectors', 'admin')
      SectorId = permissionsAdmins?.id
    } catch (error) {
      console.log('Não achou o setor, vai criar um novo')
    }

    if (!SectorId) {
      const clientsPermissions = await getDocument('configs', 'clients_permissions')

      const permissions = Object.keys(clientsPermissions)
        .filter((permission) => permission !== 'id')
        .reduce((acc, permission) => {
          Object.keys(clientsPermissions?.[permission] || {}).forEach((key) => {
            acc[`${permission}_${key}`] = clientsPermissions[permission][key]
          })
          return acc
        }, {})

      permissionsAdmins = {
        nome: 'Admin',
        description: 'Permissões padrões de Administrador',
        permissions,
      }

      SectorId = await setSubDocument('clientes', id, 'sectors', 'admin', permissionsAdmins)
    }

    const userPrincipalInfos = {
      nome: formContext?.responsavel?.nome,
      cargo: formContext?.responsavel?.cargo || 'Administrador',
      email: formContext?.responsavel?.email,
      cpf: formContext?.responsavel?.cpf,
      url_foto: '',
      sectorId: 'admin',
      createBy: user.user.nome,
      dtCreated: DateTimeNow(),
      status: true,
      type: 'client',
    }
    await NewUser(userPrincipalInfos, id, setAlerts)
    return window.location.reload()
  }

  const lockAndUnlockClient = async () => {
    if (!user?.user?.permissions?.clientes_bloquearDesbloquear === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para Bloquear/Desbloquear o cliente',
          type: 'error',
        },
      ])
    }
    const getInfosCurrent = await getDocument('clientes', id)
    const status = getInfosCurrent?.status
    console.log('status: ', status)
    if (status === true || status === false) {
      await updateDocument('clientes', id, { status: !status })
      return window.location.reload()
    }
  }

  const FetchLockAndUnlockClient = () => {
    TryCatch(lockAndUnlockClient, setLoading, setAlerts)
  }

  const FetchGetInfosClientsCurrent = () => {
    TryCatch(getClientInfos, setLoading, setAlerts)
  }

  const FetchEditClient = () => {
    TryCatch(EditClient, setLoading, setAlerts)
  }

  const FetchPrintPage = () => {
    TryCatch(PrintPage, setLoading, setAlerts)
  }

  const FetchResetPasswordAdmin = () => {
    TryCatch(ResetPasswordAdmin, setLoading, setAlerts)
  }

  const FetchaddUserAdmin = () => {
    TryCatch(addUserAdmin, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchClientInfos()
    FetchGetInfosClientsCurrent()
  }, [])

  return {
    id,
    status,
    FetchEditClient,
    FetchPrintPage,
    FetchResetPasswordAdmin,
    FetchaddUserAdmin,
    FetchLockAndUnlockClient,
  }
}
