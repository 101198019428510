import React from 'react'

export const CheckboxRoot = ({ children, text = '' }) => {
  return (
    <label className="checkbox">
      {children}
      {text}
    </label>
  )
}
