import React from 'react'
import { Outlet } from 'react-router-dom'

import { Page } from '../../../components/layouts/page'
import { SideBar } from '../../../components/layouts/sidebar'
import { Icon } from '../../../components/icons'
import { Path } from '../../../router/paths'

export const AdminSettingsLayout = () => {
  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            <SideBar.Item title={'Informações'} link={Path.ASettings}>
              <Icon size={30}>info</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Usuarios'} link={Path.AUsers}>
              <Icon size={30}>users</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Setores'} link={Path.ASectors}>
              <Icon size={30}>sectors</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Planos'} link={Path.APlans}>
              <Icon size={30}>bill</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Documentos'} link={Path.ADocuments}>
              <Icon size={30}>doc</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Consentimentos'} link={Path.AConsents}>
              <Icon size={30}>file-check</Icon>
            </SideBar.Item>
            <SideBar.Item title={'Cargos'} link={Path.ACargos}>
              <Icon size={30}>job</Icon>
            </SideBar.Item>
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
