import React from 'react'

import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Page } from '../../../components/layouts/page'
import { Path } from '../../../router/paths'
import { useAdminCollaborators } from './_hooks'
import { Pagination } from '../../../components/ui/pagination'
import { Form } from '../../../components/ui/form'
import { getCollaboratorStatusType } from './_utils/collaboratorStatus'

export const AdminCollaborators = (defaultValues) => {
  const {
    loading,
    fetchCollaboratorData,
    search,
    setSearch,
    dataContext,
    paginationValue,
    setPaginationValue,
    collaboratorsList,
    handleDeleteCollaborator,
    nextPage,
    PreviousPage,
  } = useAdminCollaborators(defaultValues)

  return (
    <>
      <Form.Root className="custom-pagination-page-wrapper" action={fetchCollaboratorData}>
        <Page.Root className="is-fullwidth">
          <Column.Root className="mt-6 pt-6 mx-3">
            <Column.Content>
              <p className="subtitle is-size-3">Colaboradores</p>
            </Column.Content>
            <Column.Content className="has-text-right">
              <Button.Root align="right">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={() => {
                      fetchCollaboratorData(1)
                    }}
                    loading={loading}
                  >
                    <Icon size={15}>sync</Icon>
                  </Button.Bt>
                </Button.Content>
                <Button.Content>
                  <Button.A
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    link={Path.AddAdminCollaborators}
                    loading={loading}
                  >
                    +
                  </Button.A>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mx-3">
            <Column.Content>
              - Arrumar botão de recarregar <br />
              - Verificar o pq nao atualiza os dados ao voltar pra tela ou ao deletar um item <br />
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>
          {loading && (
            <Column.Root className="is-mobile">
              <Column.Content></Column.Content>
              <Column.Content className="is-1">
                <Icon>loading</Icon>
                <p className="is-size-7 has-text-grey">carregando...</p>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>
          )}
          {!loading && (
            <Column.Root className="mx-3">
              <Column.Content>
                {collaboratorsList && collaboratorsList.length > 0 && (
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>E-mail</Table.Cell>
                        <Table.Cell>CPF</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {collaboratorsList &&
                        collaboratorsList
                          .filter((collaborator) => collaborator.id !== 'keapstaff')
                          .map((collaborator) => (
                            <Table.Row key={collaborator.id}>
                              {' '}
                              {/* Adicione uma chave única */}
                              <Table.Cell className="is-size-7 is-vcentered">
                                {collaborator?.status === 0 && (
                                  <p className="tag is-warning is-rounded">
                                    {getCollaboratorStatusType(collaborator?.status)}
                                  </p>
                                )}
                                {collaborator?.status === 1 && (
                                  <p className="tag is-success is-rounded">
                                    {getCollaboratorStatusType(collaborator?.status)}
                                  </p>
                                )}
                                {collaborator?.status === 2 && (
                                  <p className="tag is-danger is-light is-rounded">
                                    {getCollaboratorStatusType(collaborator?.status)}
                                  </p>
                                )}
                                {collaborator?.status === 9 && (
                                  <p className="tag is-danger is-rounded">
                                    {getCollaboratorStatusType(collaborator?.status)}
                                  </p>
                                )}
                              </Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{collaborator.name}</Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{collaborator.email}</Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{collaborator.cpf}</Table.Cell>
                              <Table.Cell className="is-vcentered">
                                <div>
                                  <Button.Root align="centered">
                                    <Button.Content>
                                      <Button.A
                                        size="is-small"
                                        color="is-warning"
                                        link={Path.EditAdminCollaborator(collaborator.id)}
                                        loading={loading}
                                      >
                                        <Icon size={15}>pen</Icon>
                                      </Button.A>
                                    </Button.Content>
                                    <Button.Content>
                                      <Button.Bt
                                        size="is-small"
                                        color="is-danger"
                                        action={() => handleDeleteCollaborator(collaborator.id)}
                                        loading={loading}
                                      >
                                        <Icon size={15}>trash</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  </Button.Root>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                    </Table.Body>
                  </Table.Root>
                )}
                {collaboratorsList.length < 1 && !loading && <p>Ops, não encotramos nenhum resultado</p>}
              </Column.Content>
            </Column.Root>
          )}
        </Page.Root>
        <Page.Root className="is-fullwidth">
          <Pagination.Functional
            nextPage={nextPage}
            PreviousPage={PreviousPage}
            paginationData={dataContext?.colaboradores?.pagination}
            valuePage={paginationValue}
            setValuePage={setPaginationValue}
          ></Pagination.Functional>
        </Page.Root>
      </Form.Root>
    </>
  )
}
