import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../utils/erros'
import { PlansLayout } from '../../../_layouts/administration/plansLayout'
import { updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AEditPlans = () => {
  const { id } = useParams()
  const { setAlerts, plansContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [plano, setPlano] = useState(Object.values(plansContext?.planos || {}).find((plans) => plans.id === id))
  const navigate = useNavigate()
  const [permissions] = useState(plano?.permissions || {})

  const EditarPlano = async () => {
    try {
      setLoading(true)
      const newPlano = { ...plano, permissions: { ...permissions } }
      await updateDocument('planos', id, plano)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Plano Editado',
          msg: `O Plano "${newPlano.nome}" foi editado com sucesso.`,
        },
      ])
      navigate(Path.Aplanos)
      return navigate(Path.APlans)
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          EditarPlano()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Plano</p>
          </Column.Content>
        </Column.Root>
        <PlansLayout loading={loading} values={plano} setValues={setPlano} disabledList={{}} />
      </Form.Root>
    </Page.Section>
  )
}
