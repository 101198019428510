import React from 'react'

export const SidebarSpace = ({ children, size }) => {
  return (
    <li style={{ marginRight: -15, height: size }}>
      <div className="tooltip-container">
        <div>{children}</div>
      </div>
    </li>
  )
}
