import React from 'react'

export const InputLabel = ({ children, className = '', style = {}, htmlFor = '', required = false }) => {
  return (
    <label className={`label ${className}`} style={style} htmlFor={htmlFor}>
      {children}
      {required && <strong className="has-text-danger">{'*'}</strong>}
    </label>
  )
}
