import React, { useState } from 'react'

import { Icon } from '../../icons'

export const SidebarItemButton = ({ children, title = '', action = () => {}, type = 'button', loading = null }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  console.log(loading)
  const tooltipClasse = `tooltip has-background-grey-lighter has-text-dark has-shadow is-size-6 px-5 py-4 ${tooltipVisible ? 'visible' : ''}`

  return (
    <li style={{ marginRight: -15 }}>
      <div className="tooltip-container">
        <p className={tooltipClasse}>{title}</p>
        <div>
          {!loading ? (
            <button
              type={type}
              className="has-text-dark has-text-centered"
              onClick={() => {
                action()
              }}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              {children}
            </button>
          ) : (
            <Icon>{'loading'}</Icon>
          )}
        </div>
      </div>
    </li>
  )
}
