import { InputContentIcon } from './InputContentIcon'
import { InputContents } from './InputContents'
import { InputLabel } from './InputLabel'
import { InputMessageError } from './InputMessageError'
import { InputPrompt } from './InputPrompt'
import { InputReadOnly } from './InputReadOnly'
import { InputRoot } from './InputRoot'
import { InputTextarea } from './InputTextarea'

/**
 * # Root
 *
 * Cria um componente para acoplar toda estrutura do INPUT.
 *
 * @param {React.ReactNode} children - Aceita somente os componentes INPUT.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {React.CSSProperties} style - Recebe um componente de style do react
 * @returns {JSX.Element} O componente React.
 *
 * # Prompt
 *
 *  * Cria um componente de INPUT.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} type - Tipo de entrada INPUT ("text", "email", "date", "datetime-local", "month", "number", "password", "search", "submit", "tel",  "time", "url", "week").
 * @param {string} placeholder - Recebe uma string que aparecerá quando não houver textos digitados.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {string} size - Recebe uma classe de tamanho de botão do bulma  ("is-large", "is-medium", "is-small")
 * @param {boolean} isLoading - reseve um valor booleano, para sinalizar se esta em loading ou não.
 * @param {useState.VALUE} value - Recebe uma variavel para armazenar os valores digitados
 * @param {useState.SETVALUE} setValue - Recebe uma variavel para setar os valores digitados no value
 * @param {string}  field Inicializa ou redefine um campo com um valor null, indicando a ausência de um valor inicial.
 * @param {string}  required Indica que o campo de input não é obrigatório. Se o valor for null, o campo não é obrigatório, mas se for true, o campo se torna obrigatório.
 * @param {string} disabled Indica que o campo de input não está desabilitado. Se o valor for null, o campo está habilitado, mas se for true, o campo se torna desabilitado.
 * @returns {JSX.Element} O componente React.
 *
 * # MessageError
 *
 * Cria um componente de mensagem a baixo do input.
 *
 * @param {React.ReactNode} children - Aceita String ou qualquer componente do react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {React.CSSProperties} style - Recebe um componente de style do react
 * @returns {JSX.Element} O componente React.
 *
 * # Label
 *
 * Cria um componente para acomodar um texto em cima do input.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {React.CSSProperties} style - Recebe um componente de style do react
 * @returns {JSX.Element} O componente React.
 *
 * # Contents
 *
 * Cria um componente CONTROL para acomodar o INPUT_PROMPT e o INPUT_ICON.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} alignIcon - Deixa um expaço para acomodar o icone ('left', 'right')
 * @returns {JSX.Element} O componente React.
 *
 * # ContenntIcon
 *
 * Cria um componente de icone para o input.
 *
 * @param {React.ReactNode} children - Aceita somente ICONE.
 * @param {string} align - Realiza o alinhamento do icone ("is-left", "is-right")
 * @returns {JSX.Element} O componente React.
 */

export const Input = {
  Root: InputRoot,
  Label: InputLabel,
  Contents: InputContents,
  Error: InputMessageError,
  Icon: InputContentIcon,
  Prompt: InputPrompt, // este é o input normal, o que coleta os dados
  Textarea: InputTextarea,
  ReadOnly: InputReadOnly,
}

// exemplo de utilização:
// <Input.Root>
//     <Input.Label>Titulo do INPUT</Input.Label>
//     <Input.Contents alignIcon='left'>
//         <Input.Prompt type='password' placeholder='E-mail' value={valor} setValue={setValor}/>
//         <Input.Icon align='left'>
//             >
//         </Input.Icon>
//     </Input.Contents>
//     <InputMessageError>Aqui sera a mensagem de Error</InputMessageError>
// </Input.Root>
