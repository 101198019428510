import React from 'react'
import { Outlet } from 'react-router-dom'

import { Page } from '../../../components/layouts/page/index'
import { AlertFunctional } from '../../../components/ui/alert/AlertFunctional'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { Hero } from '../../../components/layouts/hero/index'
import { Column } from '../../../components/layouts/columns/index'
import keapPatternBackground from '../../../assets/keapPattern.png'

export const LoginLayout = () => {
  const { alerts, setAlerts } = useGlobalContext()

  return (
    <Hero.Root
      size="is-fullheight is-fullwidth"
      style={{
        width: '100%',
        backgroundImage: `url(${keapPatternBackground})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Hero.Body>
        <Page.Root>
          <Column.Root className="is-centered">
            <Column.Content size="is-5">
              <Outlet />
            </Column.Content>
          </Column.Root>
        </Page.Root>
      </Hero.Body>
      <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
    </Hero.Root>
  )
}
