import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../utils/erros'
import { updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'
import { DocumentsLayout } from '../../../_layouts/administration/documentsLayout'

export const AEditDocuments = () => {
  const { id } = useParams()
  const { setAlerts, documentsContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [documento, setDocumento] = useState(
    Object.values(documentsContext?.documentos || {}).find((doc) => doc.id === id),
  )
  const navigate = useNavigate()
  const [permissions] = useState(documento?.permissions || {})

  const EditarDocumento = async () => {
    try {
      setLoading(true)
      const newDocumento = { ...documento, permissions: { ...permissions } }
      await updateDocument('documentos', id, documento)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Documento Editado',
          msg: `O Documento "${newDocumento.nome}" foi editado com sucesso.`,
        },
      ])
      navigate(Path.ADocumentos)
      return navigate(Path.ADocuments)
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          EditarDocumento()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Documento</p>
          </Column.Content>
        </Column.Root>
        <DocumentsLayout loading={loading} values={documento} setValues={setDocumento} disabledList={{}} />
      </Form.Root>
    </Page.Section>
  )
}
