import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { TransformationforMask } from '../../../../utils/masks'

export const ClientInfoEnterprise = () => {
  const { user } = useGlobalContext()
  const cliente = user?.client || ''

  return (
    <>
      <strong>{TransformationforMask(cliente?.id, 'title')}</strong>

      <Page.Section className="is-fullheight-with-navbar">
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-5">
              <strong>{TransformationforMask(cliente?.razaoSocial, 'title')}</strong>
            </p>
          </Column.Content>
        </Column.Root>

        <Column.Root>
          <Column.Content>
            <Input.Root>
              <Input.Label className="has-text-grey-light">CPF / CNPJ</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{cliente?.cpfCnpj}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>

            <Input.Root>
              <Input.Label className="has-text-grey-light">Situação</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{cliente?.status ? 'Ativo' : 'Inativo'}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>

            <Column.Root>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Logradouro</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.logradouro}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Número</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.numero}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Complemento</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.complemento}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>

            <Column.Root>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Bairro</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.bairro}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Municipio</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.municipio}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Cep</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.endereco?.cep}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>

            <Input.Root>
              <Input.Label className="has-text-grey-light">Telefone</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{cliente?.responsavel.telefone}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>

            <Input.Root>
              <Input.Label className="has-text-grey-light">E-mail Principal</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{cliente?.responsavel.email}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
      </Page.Section>
    </>
  )
}
