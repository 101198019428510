import React from 'react'

export const ModalRoot = ({ children, active = false }) => {
  const classe = `modal ${active ? 'is-active' : ''}`

  return (
    <div className={classe}>
      <div className="modal-background"></div>
      {children}
    </div>
  )
}
