import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Path } from '../../../router/paths'
import { ClientsFormAddteams } from './_form/clientsFormAddTeams'
import { useClientAddTeams } from './_hooks/useClientAddTeams'

export const ClientAddTeams = () => {
  const { loading, formContext, setFormContext, HandleAddTeams } = useClientAddTeams()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Nova Equipe</p>
        </Column.Content>
      </Column.Root>

      <ClientsFormAddteams
        formContext={formContext}
        setFormContext={setFormContext}
        disabledList={{}}
      ></ClientsFormAddteams>

      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.ClientTeams} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                className=""
                color="is-success"
                colorText="has-text-white"
                type={'button'}
                loading={loading}
                action={() => {
                  HandleAddTeams()
                }}
              >
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
