export const FilteredQuery = (query) => {
  console.log(query)
  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(
      ([_, value]) =>
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0),
    ),
  )
  console.log(filteredQuery)
  return filteredQuery
}
