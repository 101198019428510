export const SetItemSession = ({ key, value }) => {
  const valueStrinfy = JSON.stringify(value)
  console.log(valueStrinfy)
  return sessionStorage.setItem(key, valueStrinfy)
}

export const GetItemSession = (key, returnDefault = []) => {
  const item = sessionStorage.getItem(key)
  return item ? JSON.parse(item) : returnDefault
}

export const RemoveItemSession = ({ key }) => {
  return sessionStorage.removeItem(key)
}
