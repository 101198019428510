import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { DataComTraçoParaDataComBarra } from '../../../../utils/dates'
import { Select } from '../../../../components/ui/select'
import { Input } from '../../../../components/ui/input'
import { Column } from '../../../../components/layouts/columns'
import { Icon } from '../../../../components/icons'
import { Form } from '../../../../components/ui/form'
import { Table } from '../../../../components/ui/table'
import { statusStaff } from '../_utils/statusCandidate'

export const ModalSelectCandidacy = ({
  loading,
  active = true,
  setActive,
  dataContext,
  filter,
  setFilter,
  FormAction,
  infosContext,
  actions,
}) => {
  if (active) {
    return (
      <div>
        <Modal.Root active={active}>
          <Modal.Contents witdh={1000}>
            <Modal.Header title={'Selecionar Candidatura'}>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>

            <Modal.Body>
              <Form.Root
                action={() => {
                  FormAction()
                }}
              >
                <Column.Root className="is-mobile">
                  <Column.Content>
                    <Input.Root>
                      <Input.Contents alignIcon={'left'}>
                        <Input.Prompt
                          value={filter}
                          setValue={setFilter}
                          field={'search'}
                          placeholder="Persquisar..."
                        ></Input.Prompt>
                        <Input.Icon align="is-left">
                          <Icon size={20}>search</Icon>
                        </Input.Icon>
                      </Input.Contents>
                    </Input.Root>
                  </Column.Content>
                </Column.Root>
                <Column.Root className="is-mobile">
                  <Column.Content>
                    <Input.Root>
                      <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                        Data
                      </Input.Label>
                      <Select.Root>
                        <Select.Content
                          color="is-grey"
                          className="is-fullwidth is-rounded is-small"
                          value={filter}
                          setValue={setFilter}
                          field={'date'}
                        >
                          <Select.Option value={'todos'} title={'Todos'} />
                          {infosContext?.filters?.dates.map((date) => {
                            return (
                              <Select.Option
                                key={`date-${date}`}
                                value={date}
                                title={DataComTraçoParaDataComBarra(date)}
                              />
                            )
                          })}
                        </Select.Content>
                      </Select.Root>
                    </Input.Root>
                  </Column.Content>
                  <Column.Content>
                    <Input.Root>
                      <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                        Vaga
                      </Input.Label>
                      <Select.Root>
                        <Select.Content
                          color="is-grey"
                          className="is-fullwidth is-rounded is-small"
                          value={filter}
                          setValue={setFilter}
                          field={'idPosition'}
                        >
                          <Select.Option value={'todos'} title={'Todos'} />
                          {infosContext?.filters?.positions.map((position) => {
                            return (
                              <Select.Option
                                key={`position-${position}`}
                                value={position}
                                title={infosContext?.positionsById[position]}
                              />
                            )
                          })}
                        </Select.Content>
                      </Select.Root>
                    </Input.Root>
                  </Column.Content>
                  <Column.Content>
                    <Input.Root>
                      <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                        Status
                      </Input.Label>
                      <Select.Root>
                        <Select.Content
                          color="is-grey"
                          className="is-fullwidth is-rounded is-small"
                          value={filter}
                          setValue={setFilter}
                          field={'status'}
                        >
                          <Select.Option value={'todos'} title={'Todos'} />
                          {Object.keys(statusStaff).map((status) => {
                            console.log()
                            return (
                              <Select.Option
                                key={`status-${status}`}
                                value={status}
                                title={`${status} - ${statusStaff[status].status}`}
                              />
                            )
                          })}
                        </Select.Content>
                      </Select.Root>
                    </Input.Root>
                  </Column.Content>
                  <Column.Content>
                    <Button.Root align="is-right">
                      <Button.Content>
                        <Button.Bt
                          className="mt-4 is-rounded"
                          color="is-light"
                          size={'is-small'}
                          loading={loading}
                          title={'Enviar Convite'}
                          type="button"
                          action={() => {}}
                        >
                          Limpar
                        </Button.Bt>
                      </Button.Content>
                      <Button.Content>
                        <Button.Bt
                          className="mt-4 is-rounded"
                          color="is-dark"
                          size={'is-small'}
                          loading={loading}
                          title={'Enviar Convite'}
                          action={() => {
                            FormAction()
                          }}
                        >
                          Filtrar
                        </Button.Bt>
                      </Button.Content>
                    </Button.Root>
                  </Column.Content>
                </Column.Root>
              </Form.Root>

              {(dataContext?.data || []).length > 0 && (
                <>
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                    <Table.Head>
                      <Table.Row key={'tableEventsHead'}>
                        <Table.Cell>Data</Table.Cell>
                        <Table.Cell>Vaga</Table.Cell>
                        <Table.Cell>Colaborador</Table.Cell>
                        <Table.Cell>CPF</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(dataContext?.data || []).map((candidacy, index) => {
                        const idJobOffers = candidacy?.idJobOffers || null
                        const positionName = infosContext?.jobOffers[idJobOffers].positionName || null
                        const date = infosContext?.jobOffers[idJobOffers].date || null
                        if (idJobOffers && positionName && date) {
                          return (
                            <Table.Row key={`candidacyRow${candidacy.id}`}>
                              <Table.Cell>{DataComTraçoParaDataComBarra(date)}</Table.Cell>
                              <Table.Cell>{positionName}</Table.Cell>
                              <Table.Cell>{candidacy.name}</Table.Cell>
                              <Table.Cell>{candidacy.cpf}</Table.Cell>
                              <Table.Cell>
                                <div className={`tag ${statusStaff[candidacy.status].color}`}>
                                  {statusStaff[candidacy.status].status}
                                </div>
                              </Table.Cell>
                              <Table.Cell>
                                <Button.Bt
                                  className="is-rounded"
                                  color="is-dark"
                                  size={'is-small'}
                                  loading={loading}
                                  title={'Selecionar'}
                                  action={() => {
                                    actions.select({ ...candidacy, date, positionName })
                                  }}
                                >
                                  <Icon size={15}>arrowRight</Icon>
                                </Button.Bt>
                              </Table.Cell>
                            </Table.Row>
                          )
                        } else {
                          return <></>
                        }
                      })}
                    </Table.Body>
                  </Table.Root>
                </>
              )}

              {loading ? (
                <Column.Root>
                  <Column.Content className="has-text-centered"></Column.Content>
                  <Column.Content className="is-1">
                    <Icon>loading</Icon>
                  </Column.Content>
                  <Column.Content className="has-text-centered"></Column.Content>
                </Column.Root>
              ) : (
                <></>
              )}
            </Modal.Body>
          </Modal.Contents>
        </Modal.Root>
      </div>
    )
  } else {
    return <></>
  }
}
