export const TranslatePermissions = (value) => {
  value = String(value).toLocaleLowerCase()
  switch (value) {
    case 'view':
      return 'Visualizar'
    case 'update':
      return 'Atualizar / Editar'
    case 'delete':
      return 'Excluir'
    case 'add':
      return 'Adicionar'
    case 'blockunblock':
      return 'Bloquear / Desbloquear'

    case 'users':
      return 'Usuários'
    case 'events':
      return 'Eventos'
    case 'sectors':
      return 'Setores'
    default:
      return value
  }
}
