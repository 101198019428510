import { httpsCallable } from 'firebase/functions'

import { functions } from '.'
import { FilteredQuery } from '../screens/client/events/_utils/filteredQuery'

export const GetUserAuthenticatorFromEmail = async (email) => {
  // usa código do FUNTIONS para COLETAR O UID o usuário do authenticator
  const getUserByEmail = httpsCallable(functions, 'getUserByEmail')
  try {
    const result = await getUserByEmail({ email })
    return result.data
  } catch (error) {
    return { error: error.message || 'Erro desconhecido ao criar usuário' }
  }
}

export const DeleteUserAuthenticatorFromUid = async (uid) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const deleteUserByUid = httpsCallable(functions, 'deleteUserByUid')
  try {
    const result = await deleteUserByUid({ uid })
    return result.data.message
  } catch (error) {
    return { error: error.message || 'Erro desconhecido ao criar usuário' }
  }
}
/**
 *
 * Função para usar backend para paginas os resultados da colletion
 *
 * @param {Number} page Numero da pagina buscada
 * @param {Number} limit limite por pagina buscada
 * @param {String} idEvent id do evento buscado
 * @param {Object} query query de pesquisa: { campoBuscado: valorBuscado }
 * @param {Object} orderBy ordena a consulta: {field: campopara ordendar, direction: 'asc'||'desc'}
 * @returns
 */
export const GetPaginationCollection = async (
  page,
  limit,
  collectionName,
  orderBy = null,
  search = null,
  fieldsSearched = [],
) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const paginationColletion = httpsCallable(functions, 'paginateCollections')
  try {
    const result = await paginationColletion({
      page,
      limit,
      orderBy,
      collectionName,
      search,
      fieldsSearched,
    })
    return result.data
  } catch (error) {
    console.error('Error:', error.message)
  }
}

export const GetPaginationStaff = async (page, limit, idEvent, query = {}, orderBy = null) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const paginateEventsStaff = httpsCallable(functions, 'paginateEventsStaff')

  const keysToDelete = ['page', 'filtered', 'date', 'idPosition']

  keysToDelete.forEach((key) => {
    delete query[key]
  })

  const filteredQuery = FilteredQuery(query)

  try {
    const result = await paginateEventsStaff({
      page,
      limit,
      orderBy,
      idEvent,
      query: filteredQuery,
    })
    return result.data
  } catch (error) {
    console.error('Error:', error.message)
  }
}

export const GetPaginationFavoriteCollaborators = async (page, limit, idClient, query = {}, orderBy = null) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const paginateFavoriteCollaborators = httpsCallable(functions, 'paginateFavoriteCollaborators')

  const keysToDelete = ['page', 'filtered']

  keysToDelete.forEach((key) => {
    delete query[key]
  })

  const filteredQuery = FilteredQuery(query)

  try {
    const result = await paginateFavoriteCollaborators({
      page,
      limit,
      orderBy,
      idClient,
      query: filteredQuery,
    })
    return result.data
  } catch (error) {
    console.error('Error:', error.message)
  }
}
