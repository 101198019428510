import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '', errorTitle: '' }
  }

  static getDerivedStateFromError(error) {
    // Atualiza o estado para que a próxima renderização mostre a interface de fallback.
    return {
      hasError: true,
      errorMessage: error.toString(),
      errorTitle: error,
    }
  }

  componentDidCatch(error, errorInfo) {
    // Você pode logar o erro para um serviço de relatórios de erros.
    console.error('ErrorBoundary captured an error', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer interface de fallback.
      return (
        <section className="hero is-fullheight has-background-grey-dark">
          <div className="hero-body ">
            <div>
              <p className="title has-text-white">Oops! Ocorreu um Erro</p>

              <article className="message is-danger">
                <div className="message-body">{this.state.errorMessage}</div>
              </article>
              <a className="button is-danger" href="/">
                Voltar
              </a>
            </div>
          </div>
        </section>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
