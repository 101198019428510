import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../utils/dates'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../utils/erros'
import { DocumentsLayout } from '../../../_layouts/administration/documentsLayout'
import { addDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AAddDocuments = () => {
  const { user, setAlerts } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [documento, setdocumento] = useState({})
  const navigate = useNavigate()

  const criarDocumento = async () => {
    try {
      setLoading(true)
      const newPlan = {
        ...documento,
        created_by: user?.user?.nome,
        dt_created: DateTimeNow(),
      }
      await addDocument('documentos', newPlan)
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Documento Criado',
          msg: 'Novo Documento criado com sucesso.',
        },
      ])
      navigate(Path.ADocumentos)

      return navigate(Path.ADocuments)
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          criarDocumento()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Documento</p>
          </Column.Content>
        </Column.Root>
        <DocumentsLayout loading={loading} values={documento} setValues={setdocumento} disabledList={{}} />
      </Form.Root>
    </Page.Section>
  )
}
