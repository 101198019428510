import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'

export const ClientHome = () => {
  return (
    <>
      <Page.Root>
        <Page.Content style={{ marginTop: 150 }} className="has-text-centered">
          <Icon size={150}>gear</Icon>
          <br />
          Página home do cliente em construção....
          <br />
          <br />
          <Button.Bt action={() => {}}> Voltar </Button.Bt>
        </Page.Content>
      </Page.Root>
    </>
  )
}
