import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const SidebarItemLink = ({
  children,
  title = '',
  link = null,
  textColor = 'has-text-dark',
  activate = false,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const tooltipClasse = `tooltip has-background-grey-lighter has-text-dark has-shadow is-size-6 px-5 py-4 ${tooltipVisible ? 'visible' : ''}`

  return (
    <li style={{ marginRight: -15 }}>
      <div>
        <p className={tooltipClasse}>{title}</p>
        <div>
          <Link
            className={`${textColor} has-text-centered ${activate ? 'has-background-grey-lighter' : ''}`}
            to={link}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            {children}
          </Link>
        </div>
      </div>
    </li>
  )
}
