import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Page } from '../../../../components/layouts/page'
import { Button } from '../../../../components/ui/button'

export const ClientInformations = () => {
  return (
    <>
      <Page.Section className="is-fullheight-with-navbar">
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-5">
              <strong>Keap Staff</strong>
            </p>
            <p className="has-text-grey">Version 1.0.0</p>
          </Column.Content>
        </Column.Root>

        <Column.Root className="mt-6">
          <Column.Content>
            <p className="has-text-grey">
              <strong>Suporte Técnico</strong>
            </p>
            <Button.A>Acessar</Button.A>
          </Column.Content>
        </Column.Root>

        <Column.Root className="mt-6">
          <Column.Content>
            <p className="has-text-grey">
              <strong>Area Financeira</strong>
            </p>
            <Button.A>Acessar</Button.A>
          </Column.Content>
        </Column.Root>
      </Page.Section>
    </>
  )
}
