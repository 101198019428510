import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsLayout } from './_form/clientsLayout'
import { Icon } from '../../../components/icons'
import { useEditClients, useAdminClients } from './_hooks'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'

export const AEditClients = () => {
  const { setFormContext, formContext, loading, setLoading, infosContext } = useGlobalContext()
  const {
    id,
    status,
    FetchEditClient,
    FetchPrintPage,
    FetchResetPasswordAdmin,
    FetchaddUserAdmin,
    FetchLockAndUnlockClient,
  } = useEditClients()
  const { navigate } = useAdminClients()

  return (
    <Page.Section className="is-fullheight-with-navbar  mt-6 pt-6">
      <Form.Root
        action={() => {
          FetchEditClient()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Cliente</p>
          </Column.Content>
        </Column.Root>

        {!formContext?.infoClient ? (
          <Column.Root className="is-mobile">
            <Column.Content></Column.Content>
            <Column.Content className="is-1">
              <Icon>loading</Icon>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        ) : (
          <>
            <Column.Root>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Situação
                  </Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{status}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Column.Root>
                  <Column.Content className="has-text-centered" type="button">
                    <Button.Bt
                      color={formContext?.status ? 'is-danger' : 'is-success'}
                      type="button"
                      className="mx-1"
                      size={'is-small'}
                      loading={loading}
                      action={FetchLockAndUnlockClient}
                    >
                      <Icon>lock</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">{formContext?.status ? 'Bloquear' : 'Ativar'}</p>
                  </Column.Content>

                  {/* Gerar Fatura: Gera uma nova fatura */}
                  <Column.Content className="has-text-centered">
                    <Button.Bt color="is-light" type="button" className="mx-1" size={'is-small'} loading={loading}>
                      <Icon>bill</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">Gerar Fatura</p>
                  </Column.Content>
                  {/* Hitórico financeiro: mostra o histórico financeiro do cliente */}
                  <Column.Content className="has-text-centered" type="button">
                    <Button.Bt color="is-light" type="button" className="mx-1" size={'is-small'} loading={loading}>
                      <Icon>history</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">Histórico Financeiro</p>
                  </Column.Content>

                  <Column.Content className="has-text-centered" type="button">
                    <Button.Bt
                      color="is-light"
                      type="button"
                      className="mx-1"
                      size={'is-small'}
                      action={FetchPrintPage}
                      loading={loading}
                    >
                      <Icon>print</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">Imprimir</p>
                  </Column.Content>

                  {formContext?.userAdmin ? (
                    <Column.Content className="has-text-centered" type="button">
                      <Button.Bt
                        color="is-light"
                        type="button"
                        className="mx-1"
                        size={'is-small'}
                        action={FetchResetPasswordAdmin}
                        loading={loading}
                      >
                        <Icon>password</Icon>
                      </Button.Bt>
                      <p className="is-size-7 has-text-grey">Resetar Senha</p>
                    </Column.Content>
                  ) : (
                    <Column.Content className="has-text-centered" type="button">
                      <Button.Bt
                        color="is-light"
                        type="button"
                        className="mx-1"
                        size={'is-small'}
                        action={FetchaddUserAdmin}
                        loading={loading}
                      >
                        <Icon>add</Icon>
                      </Button.Bt>
                      <p className="is-size-7 has-text-grey">Criar Login</p>
                    </Column.Content>
                  )}
                </Column.Root>
              </Column.Content>
            </Column.Root>

            <ClientsLayout
              navigate={navigate}
              loading={loading}
              setLoading={setLoading}
              values={formContext}
              setValues={setFormContext}
              options={infosContext}
              disabledList={{}}
              type={'edit'}
              clientId={id}
            />
          </>
        )}
      </Form.Root>
    </Page.Section>
  )
}
