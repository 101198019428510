import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { addSubDocument } from '../../../../firebase/firestore'
import { DateTimeNow } from '../../../../utils/dates'
import { Path } from '../../../../router/paths'

export const useClientAddTeams = () => {
  const {
    user,
    setAlerts,
    dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext,
    setLoading,
    loading,
  } = useGlobalContext()

  const clientId = user?.client?.id || null

  const navigate = useNavigate()

  const cleanDataForm = async () => {
    setFormContext({})
  }

  const checkSchemaTeams = async (values) => {
    const name = values?.name || null
    const description = values?.description || ''
    const teams = values?.teams || {}

    console.log(name)

    if (name === null || name === undefined) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: `Campo Faltante`,
          msg: `O Campo " NOME ", está vazio, preencha-o para continuar`,
        },
      ])
      return null
    }

    if (Object.keys(teams).length <= 0) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: `Equipe Vazia`,
          msg: `Selecione ao menos um colaborador para criar um equipe...`,
        },
      ])
      return null
    }

    return {
      name,
      description,
      teams,
    }
  }

  const addTeams = async () => {
    const newTeams = await checkSchemaTeams(formContext)
    if (!newTeams) return

    newTeams.dtCreated = DateTimeNow()
    newTeams.createdBy = user?.user?.nome

    await addSubDocument('clientes', clientId, 'teams', newTeams)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Equipe Adicionada`,
        msg: `Sua nova equipe foi adicionada com sucesso...`,
      },
    ])

    navigate(Path.ClientTeams)
  }

  const FetchCleanDataForm = async () => {
    await TryCatch(cleanDataForm, setLoading, setAlerts)
  }

  const HandleAddTeams = async () => {
    await TryCatch(addTeams, setLoading, setAlerts)
  }

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchCleanDataForm()
  }, [])

  return {
    loading,
    dataContext,
    formContext,
    setFormContext,
    FetchCleanDataForm,
    HandleAddTeams,
  }
}
