import React, { useState } from 'react'

import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Select } from '../../../components/ui/select'
import { Table } from '../../../components/ui/table'
import { Icon } from '../../../components/icons'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { Path } from '../../../router/paths'

export const PositionsLayout = ({ values, setValues, loading, options, disabledList, action }) => {
  const { setAlerts } = useGlobalContext()

  const [doc, setDoc] = useState(null)
  const [cons, setCons] = useState(null)

  const [listDocumentos, setListDocumentos] = useState(
    values?.documentos.map((id) => (options?.documentos || []).find((item) => item?.id === id)),
  ) // coleta as opções pelos ids dentro do array no values
  const [listConsentimentos, SetListConsentimentos] = useState(
    values?.consentimentos.map((id) => (options?.consentimentos || []).find((item) => item?.id === id)),
  ) // coleta as opções pelos ids dentro do array no values

  const documentos = (options?.documentos || []).filter((i) => !(listDocumentos || []).some((j) => i.id === j?.id)) // deleta da lista do select qundo é adicionado
  const consentimentos = (options?.consentimentos || []).filter(
    (i) => !(listConsentimentos || []).some((j) => i.id === j?.id),
  ) // deleta da lista do select qundo é adicionado

  const AdicionarItem = (SetValues, setlist, field, item) => {
    const newItem = options?.[field].find((i) => i.id === item) || {}
    if (item) {
      setlist((prev) => [...prev, newItem])
      return SetValues((prev) => ({
        ...prev,
        [field]: [...(prev?.[field] || []), item],
      }))
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          title: `${field} não selecionado`,
          msg: 'Selecione um item, e tente novamente.',
          type: 'error',
        },
      ])
    }
  }

  const ExcluirItem = (SetValues, setlist, field, item) => {
    setlist((prev) => prev.filter((i) => !i.id === item) || [])
    return SetValues((prev) => ({
      ...prev,
      [field]: [...(prev?.[field].filter((i) => !i === item) || [])],
    }))
  }

  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              {' '}
              Nome{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Experiência Necessária
            </Input.Label>
            <Input.Textarea
              value={values}
              setValue={setValues}
              field={'dica'}
              required={true}
              disabled={disabledList?.dica}
              rows="2"
            />
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Validação de Recrutador
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'validation'}
                disabled={disabledList?.type}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={true} title={'Sim'} />
                <Select.Option value={false} title={'Não'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          <Column.Root className="is-mobile">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Documentações
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={doc}
                    setValue={setDoc}
                    disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    {Object.values(documentos).map((item, index) => {
                      return <Select.Option value={item.id} title={item.nome} key={index} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5"
                color="is-success"
                colorText="has-text-white"
                loading={loading}
                action={() => {
                  AdicionarItem(setValues, setListDocumentos, 'documentos', doc)
                }}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          <div className="box" style={{ marginTop: -40 }}>
            <Table.Root className="is-fullwidth has-text-centered">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>Tipo</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {listDocumentos.map((item, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <span title={item.descricao}>{item.nome}</span>
                      </Table.Cell>
                      <Table.Cell>{item.type}</Table.Cell>
                      <Table.Cell>
                        <Button.Bt
                          className=""
                          color="is-danger"
                          size={'is-small'}
                          colorText="has-text-white"
                          action={() => {
                            ExcluirItem(setValues, setListDocumentos, 'documentos', doc)
                          }}
                          loading={loading}
                        >
                          <Icon size={15}>trash</Icon>
                        </Button.Bt>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </div>

          <Column.Root className="is-mobile">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Consentimentos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={cons}
                    setValue={setCons}
                    disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    {Object.values(consentimentos).map((item, index) => {
                      return <Select.Option value={item.id} title={item.nome} key={index} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5"
                color="is-success"
                colorText="has-text-white"
                action={() => {
                  AdicionarItem(setValues, SetListConsentimentos, 'consentimentos', cons)
                }}
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          <div className="box" style={{ marginTop: -40 }}>
            <Table.Root className="is-fullwidth has-text-centered">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>Assinatura</Table.Cell>
                  <Table.Cell>Expira em (dias)</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {listConsentimentos.map((item, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <span title={item.termo}>{item.nome}</span>
                      </Table.Cell>
                      <Table.Cell>{item.type_assinatura}</Table.Cell>
                      <Table.Cell>{item.revogar}</Table.Cell>
                      <Table.Cell>
                        <Button.Bt
                          className=""
                          color="is-danger"
                          size={'is-small'}
                          colorText="has-text-white"
                          action={() => {
                            ExcluirItem(setValues, SetListConsentimentos, 'consentimentos', cons)
                          }}
                          loading={loading}
                        >
                          <Icon size={15}>trash</Icon>
                        </Button.Bt>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </div>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.ACargos} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                className=""
                color="is-success"
                colorText="has-text-white"
                action={() => {
                  action()
                }}
                loading={loading}
              >
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
