import React from 'react'

export const PaginationInput = ({
  value,
  setValue,
  className = '',
  disabled = null,
  placeholder = '',
  totalValue = '',
}) => {
  const changes = (e) => {
    const v = e.target.value
    if (v <= totalValue) {
      setValue(v)
    } else {
      setValue(totalValue)
    }
  }

  return (
    <li>
      <input
        className={`Input pagination-link ${className}`}
        style={{
          height: 35,
          width: 35,
        }}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={changes}
      ></input>
    </li>
  )
}
