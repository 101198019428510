import React from 'react'

import { ClientFormSectorsLayout } from './_form/clientFormSectorsLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { useClientAddSectors } from './_hooks/useClientAddSectors'

export const ClientAddSectors = () => {
  const {
    loading,
    dataContext,
    formContext,
    setFormContext,
    HandleAddSector,
    permissions,
    setPermissions,
    permissionsList,
    checkAlls,
    setCheckAlls,
  } = useClientAddSectors()

  return (
    <>
      <Form.Root
        className="mt-6 is-fullwidth"
        action={() => {
          HandleAddSector()
        }}
      >
        <Page.Root>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-3">Adicionar Setor</p>
            </Column.Content>
          </Column.Root>
          <Page.Content>
            <ClientFormSectorsLayout
              dataContext={dataContext}
              loading={loading}
              values={formContext}
              setValues={setFormContext}
              permitions={permissions}
              setPermissions={setPermissions}
              permissionsList={permissionsList}
              checkAlls={checkAlls}
              setCheckAlls={setCheckAlls}
            ></ClientFormSectorsLayout>
          </Page.Content>
        </Page.Root>
      </Form.Root>
    </>
  )
}
