import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import { addSubDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'

export const useClientAddSectors = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const [permissions, setPermissions] = useState(infosContext?.globalPermissionsList || {})
  const [checkAlls, setCheckAlls] = useState({})
  const permissionsList = infosContext?.globalPermissions || {}

  const navigate = useNavigate()

  const addSector = async () => {
    const newSector = { ...formContext, permissions: { ...permissions } }
    await addSubDocument('clientes', user.client.id, 'sectors', newSector)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Setor Criado',
        msg: 'Novo Setor criado com sucesso.',
      },
    ])

    await setFormContext({})
    return navigate(Path.ClientSectors)
  }

  const HandleAddSector = async () => {
    await TryCatch(addSector, setLoading, setAlerts)
  }

  return {
    loading,
    dataContext,
    formContext,
    setFormContext,
    HandleAddSector,
    permissions,
    setPermissions,
    permissionsList,
    checkAlls,
    setCheckAlls,
  }
}
