import { AlertMessage } from './AlertMessage'
import { AlertRoot } from './AlertRoot'

/**
 * # Root
 *
 * Cria um componente de colunas que comportará todas as mensagens de alerta.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente do react
 * @returns {JSX.Element} O componente React.
 *
 * # Message
 *
 * Cria um componente de mensagem do alerta.
 *
 * @param {string} title - Aceita qualquer componente do react
 * @param {string} text - Array de objetos com os valores dos alertas | Array: [ {title:"TITULO", msg:"AQUI SERÁ A MENSAGEM QUE VAMOS COLOCAR", type:"error"} ].
 * @param {string} type - variavel SET para manipular o array de objetos passado no values.
 * @param {funtion} close - Função para destruir a mensagem criada ao clicar no botão delete
 * @returns {JSX.Element} O componente React.
 *
 * # Functional
 *
 * Cria um componente funcional de alertas.
 *
 * @param {array} values - Array de objetos com os valores dos alertas | Array: [ {title:"TITULO", msg:"AQUI SERÁ A MENSAGEM QUE VAMOS COLOCAR", type:"error"} ].
 * @param {setArray} setValues - variavel SET para manipular o array de objetos passado no values.
 * @returns {JSX.Element} O componente React.
 */

export const Alert = {
  Root: AlertRoot,
  Message: AlertMessage,
}

// modo de uso:

//     const [alerts, setAlerts] = useState([]);

//     <Button.Bt size='large' colorButton='danger' action={()=>{setAlerts([...alerts, {'title':"Error 403", 'msg':"Um erro teste para vermos o alerta", 'type':'error'}])}}> Alerta de ERRO </Button.Bt>

//     <AlertFunctional values={alerts} setValues={setAlerts}/>
