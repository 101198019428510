import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocuments, getDocumentsWithQuery, setDocument } from '../../../../firebase/firestore'
import { uploadFile } from '../../../../firebase/storage'
import { DateTimeNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { NewUserAuth } from '../../../../utils/requests'

export const useAddAdminCollaborators = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getConsents = await getDocuments('consentimentos')
    const getDocs = await getDocuments('documentos')
    const getPositions = await getDocuments('cargos')

    setInfosContext({
      consentimentos: getConsents,
      documentos: getDocs,
      cargos: getPositions,
    })
  }

  const addNewCollaborator = async () => {
    const newCollaborator = {
      nome: formContext?.nome,
      url_foto: formContext?.url_foto,
      cpf: formContext?.cpf,
      pessoal: {
        nome_social: formContext?.nomeSocial,
        documento: formContext?.documento,
        type_doc: formContext?.tipoDocumento,
        dt_nascimento: formContext?.dtNascimento,
        estado_civil: formContext?.estadoCivil,
      },
      endereco: {
        logradouro: formContext?.logradouro,
        numero: formContext?.numeroEndereco,
        bairro: formContext?.bairro,
        cep: formContext?.cep,
        cidade: formContext?.cidade,
        uf: formContext?.uf,
      },
      contato: { celular: formContext?.celular, emergencia: formContext?.emergencyContacts },
      bancario: {
        conta: formContext?.conta,
        agencia: formContext?.agencia,
        banco: formContext?.banco,
        pix: formContext?.pix,
      },
      consentimentos: { ...formContext?.consentimentos },
      documentos: { ...formContext?.documentos },
      cargos: { ...formContext?.cargos },
      email: formContext?.email,
      dt_created: DateTimeNow(),
      // dt_register: DateTimeNow(),
      // dt_last_login: DateTimeNow(),
      created_by: user?.user?.nome,
      // version_app: '1.0.0',
      status: 1,
      // notification_token: 'teste',
    }

    const setPassword = window.prompt('Digite uma senha padrão para o colaborador realizar o primeiro login', null)

    if (!setPassword) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Senha obrigatória',
          msg: `Para continuar com o cadastro é necessário criar uma senha padrão, ex: keap123.`,
        },
      ])
    }

    const newUserAuthResponse = await NewUserAuth(newCollaborator, setAlerts, setPassword)

    if (newUserAuthResponse.error) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Error de autenticação',
          msg: `Houve um erro na autenticação: ${newUserAuthResponse.error}.`,
        },
      ])
    }

    if (newUserAuthResponse.userExists) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Usuário existente',
          msg: `O usuário já existe .`,
        },
      ])
    }

    const uuid = newUserAuthResponse.uid

    if (!uuid) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'uuid inexistente',
          msg: `O ${uuid} não foi encontrado .`,
        },
      ])
    }

    const cpfExists = await getDocumentsWithQuery('colaboradores', 'cpf', '==', newCollaborator.cpf)

    if (cpfExists.length > 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'CPF já cadastrado',
          msg: `O CPF ${newCollaborator.cpf} informado já existe na base de dados.`,
        },
      ])
    }

    const imageTimestamp = new Date().toISOString()

    const pathUploadFile = `collaborators/${imageTimestamp}/foto_${imageTimestamp}`

    if (newCollaborator.url_foto) {
      const urlFoto = await uploadFile(newCollaborator.url_foto, pathUploadFile)
      newCollaborator.url_foto = urlFoto
    }

    const newCollaboratorId = await setDocument('colaboradores', uuid, newCollaborator)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Colaborador Criado',
        msg: `O colaborador ${newCollaborator.nome} foi criado com sucesso.`,
      },
    ])

    await setFormContext({})

    navigate(`/administracao/colaboradores/editar/${newCollaboratorId}`)
  }

  const handleAddCollaborators = async () => {
    await TryCatch(addNewCollaborator, setLoading, setAlerts)
  }

  const clearFormContextOnRender = () => {
    setFormContext({})
  }

  const FetchInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchInfos()
  }, [])

  useEffect(() => {
    clearFormContextOnRender()
  }, [])

  return {
    loading,
    formContext,
    setFormContext,
    handleAddCollaborators,
  }
}
