import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { getDocument, getSubDocuments, updateDocument } from '../../../../../firebase/firestore'
import { DeleteUser } from '../../../../../utils/requests'
import { TryCatch } from '../../../../../utils/general'

export const useClientUsers = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    setLoading,
    loading,
  } = useGlobalContext()

  const users = dataContext?.usersData || {}
  const sectors = infosContext?.sectors || {}
  const [search, setSearch] = useState('')
  const clientId = user.client.id

  const getDatas = async () => {
    const getclient = await getDocument('clientes', clientId)
    const getUsersStatus = getclient.users
    const getUser = await getSubDocuments('clientes', clientId, 'users')
    const usersData = Object.values(getUser).map((u) => {
      return { ...u, status: getUsersStatus[u.id] }
    })
    const getSectors = await getSubDocuments('clientes', clientId, 'sectors')
    await setDataContext({ usersData })
    await setInfosContext({ sectors: getSectors })
  }

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const deleteUser = (uid, email) => {
    if (!user?.user?.permissions?.users_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para excluir usuarios',
        },
      ])
    }

    if (user?.client?.responsavel?.email === email) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Usuário Administrador',
          msg: 'Você não pode deletar um usuário administrador do sistema.',
        },
      ])
    }
    const userInfo = Object.values(users).find((u) => u.id === uid)
    return DeleteUser(uid, userInfo, clientId, setLoading, setAlerts, getDatas)
  }

  const blockUnblockUser = async (uid, email) => {
    if (!user?.user?.permissions?.users_blockUnblock) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para bloquear usuarios',
        },
      ])
    }
    if (user?.client?.responsavel?.email === email) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Usuário Administrador',
          msg: 'Você não pode bloquear/Desbloquear um usuário administrador do sistema.',
        },
      ])
    }

    const userInfo = Object.values(users).find((u) => u.id === uid)

    await updateDocument('clientes', clientId, {
      [`users.${uid}`]: !userInfo.status,
    })

    if (!userInfo.status) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Usuário Bloqueado',
          msg: `Usuário ${userInfo.nome} Bloqueado com sucesso.`,
        },
      ])
    } else {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Usuário Desbloqueado',
          msg: `Usuário ${userInfo.nome} Desbloqueado com sucesso.`,
        },
      ])
    }

    FetchGetDatas()
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const HandleDeleteUser = async (uid, email) => {
    await TryCatch(
      () => {
        deleteUser(uid, email)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleBlockUnblockUser = async (uid, email) => {
    await TryCatch(
      () => {
        blockUnblockUser(uid, email)
      },
      setLoading,
      setAlerts,
    )
  }

  const usersFiltered =
    search !== ''
      ? Object.values(users).filter((user) => {
          const sectorName = sectors.find((setor) => setor.id === user.sectorId)?.nome || ''
          const status = user?.status ? 'Ativo' : 'Bloqueado'

          const searchString = `${user.nome}|${user.email}|${user.cargo}|${sectorName}|${status}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(users)

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetDatas()
  }, [])

  return {
    loading,
    usersFiltered,
    sectors,
    search,
    setSearch,
    FetchGetDatas,
    HandleDeleteUser,
    HandleBlockUnblockUser,
  }
}
