import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../utils/dates'
import { UsersLayout } from '../../../_layouts/administration/usersLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { NewUser } from '../../../../utils/requests'
import { TratamentoError } from '../../../../utils/erros'

export const AAddUsers = () => {
  const { setAlerts, user, userContext } = useGlobalContext()
  const [userInfos, setUserInfo] = useState({})
  const sectors = userContext?.sectors || []
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const clientId = user.client.id

  const criarUsuario = async () => {
    // deixa os botões em formato loading
    try {
      setLoading(true)
      // cria um objeto inicial para o usuario
      const userInfo = {
        ...userInfos,
        createBy: user.user.nome,
        dtCreated: DateTimeNow(),
        type: 'admin',
      }
      await NewUser(userInfo, clientId, setLoading, setAlerts, navigate)
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          criarUsuario()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Usuário</p>
          </Column.Content>
        </Column.Root>
        <UsersLayout
          loading={loading}
          values={userInfos}
          setValues={setUserInfo}
          permissions={sectors}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
