import React from 'react'

import { ClientFormUserLayout } from './_form/clientFormUserLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { useClientEditUsers } from './_hooks/useClientEditUsers'
import { Icon } from '../../../../components/icons'

export const ClientEditUsers = () => {
  const { uid, sectors, loading, HandleEditUser, formContext, setFormContext } = useClientEditUsers()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6">
      <Form.Root
        action={() => {
          HandleEditUser()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Usuário</p>
          </Column.Content>
        </Column.Root>
        {loading ? (
          <Column.Root>
            <Column.Content></Column.Content>
            <Column.Content>
              <Icon>loading</Icon>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        ) : (
          <ClientFormUserLayout
            loading={loading}
            values={formContext}
            setValues={setFormContext}
            options={sectors}
            disabledList={{
              email:
                'Não é possível alterar o e-mail cadastrado. Caso seja necessário, exclua o usuário atual e crie um novo',
            }} // desabilita campos no formulario
            uid={uid}
          />
        )}
      </Form.Root>
    </Page.Section>
  )
}
