import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Table } from '../../../../../components/ui/table'
import { Checkbox } from '../../../../../components/ui/checkbox'
import { Path } from '../../../../../router/paths'
import { Select } from '../../../../../components/ui/select'
import { useClientFormSectorLayout } from './_hooks/useClientFormSectorLayout'
import { Icon } from '../../../../../components/icons'
import { TranslatePermissions } from '../../../../../utils/translatePermissions'

export const ClientFormSectorsLayout = ({
  dataContext,
  values,
  setValues,
  permitions,
  setPermissions,
  loading,
  permissionsList,
  checkAlls,
  setCheckAlls,
}) => {
  const { HandleCopyPermissionsFromAnotherSector } = useClientFormSectorLayout(permitions, setPermissions, checkAlls)

  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5 ">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Descrição
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'descricao'}
                required={true}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          {!permitions ? (
            <Column.Root className="is-mobile mx-4">
              <Column.Content></Column.Content>
              <Column.Content>
                <Icon>loading</Icon>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>
          ) : (
            <>
              <Column.Root className="is-mobile mr-4">
                <Column.Content>
                  <Select.Root>
                    <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} key="input_copy">
                      Copiar Permissões de:
                    </Input.Label>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={values}
                      setValue={setValues}
                      field={'CopybySector'}
                    >
                      <Select.Option value={null} title={'Selecione uma opção'} />
                      {/* lista de permissões */}
                      {(dataContext?.sectors || []).map((sector) => {
                        console.log(sector)
                        return (
                          <>
                            <Select.Option value={sector.id} title={sector.nome} />
                          </>
                        )
                      })}
                    </Select.Content>
                  </Select.Root>
                </Column.Content>
                <Column.Content size={'is-1'} className="mr-6">
                  <Button.Bt
                    className="mt-5"
                    color="is-success"
                    colorText="has-text-white"
                    type={'button'}
                    loading={loading}
                    action={() => {
                      HandleCopyPermissionsFromAnotherSector(dataContext, values?.CopybySector)
                    }}
                  >
                    Copiar
                  </Button.Bt>
                </Column.Content>
              </Column.Root>

              <Table.Root>
                <Table.Body>
                  {Object.keys(permissionsList)
                    .sort((a, b) => a > b)
                    .filter((key) => {
                      return key !== 'id'
                    })
                    .map((option) => {
                      return (
                        <React.Fragment key={`option_${option}`}>
                          <Table.Row>
                            <Table.Cell>
                              <Checkbox.Root key={`Root_${option}_all`}>
                                <Checkbox.Content
                                  value={checkAlls}
                                  setValue={setCheckAlls}
                                  field={`${option}_checkall`}
                                  key={`check_${option}_all`}
                                />
                                <label className="ml-2">
                                  <strong>{TranslatePermissions(option)}</strong>
                                </label>
                              </Checkbox.Root>
                            </Table.Cell>
                          </Table.Row>
                          {Object.keys(permissionsList[option])
                            .sort((a, b) => TranslatePermissions(a) > TranslatePermissions(b))
                            .map((item, index) => {
                              return (
                                <Table.Row key={`Row_${option}_${index}`}>
                                  <Table.Cell key={`Cell_${option}_${index}`}>
                                    <div className="ml-5">
                                      <Checkbox.Root key={`Root_${option}_${index}`}>
                                        <Checkbox.Content
                                          value={permitions}
                                          setValue={setPermissions}
                                          field={`${option}_${item}`}
                                          key={`check_${option}_${index}`}
                                        />
                                        <label className="ml-2">{TranslatePermissions(item)}</label>
                                      </Checkbox.Root>
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })}
                        </React.Fragment>
                      )
                    })}
                </Table.Body>
              </Table.Root>
            </>
          )}
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.ClientSectors} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
