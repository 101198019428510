import React from 'react'

export const PaginationButton = ({ children, action = () => {}, className = '' }) => {
  return (
    <li>
      <button
        onClick={() => {
          action()
        }}
        className={`pagination-link ${className}`}
      >
        {children}
      </button>
    </li>
  )
}
