import { ButtonContentA } from './ButtonContentA'
import { ButtonContentBt } from './ButtonContentBt'
import { ButtonContents } from './ButtonContents'
import { ButtonRoot } from './ButtonRoot'

/**
 * # Root
 *
 * Cria um componente "control" para criar grupos de botões.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} align - Aceita String para alinhar o grupo de botões ("centered", "left", "right")
 * @returns {JSX.Element} O componente React.]
 *
 * # Contents
 *
 * Cria um componente "control" para criar grupos de botões.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @returns {JSX.Element} O componente React.
 *
 * # ContentBt
 *
 * Cria um componente de botão com ação.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {string} colorText - Recebe uma classe de cor de texto do bulma ("has-text-white", "has-text-black", "has-text-light", "has-text-dark", "has-text-primary", "has-text-link", "has-text-info", "has-text-success", "has-text-warning", "has-text-danger")
 * @param {string} size - Recebe uma classe de tamanho de botão do bulma  ("is-large", "is-medium", "is-small")
 * @param  {string}  disabled - deixa o botão desativado
 * @param  {string}  type - seleciona o tipo do botão ("submit")
 * @param  {string}  loading - deixa o botão em modo loading
 * @param  {string}  msgDisabled - mostra uma mensagem quando passar o mouse assim que o botão estiver desativado
 * @param {funtion} action - Recebe uma função para que o botão execute ao ser precionado.
 * @returns {JSX.Element} O componente React.
 *
 * # ContentA
 *
 * Cria um componente botão de link.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {string} colorText - Recebe uma classe de cor de texto do bulma ("has-text-white", "has-text-black", "has-text-light", "has-text-dark", "has-text-primary", "has-text-link", "has-text-info", "has-text-success", "has-text-warning", "has-text-danger")
 * @param {string} size - Recebe uma classe de tamanho de botão do bulma  ("is-large", "is-medium", "is-small")
 * @param {string} link - Recebe um path que redirecionará o usuário para a paginá desejada ("/caminhoDaPagina")
 * @param {string} loading - deixa o botão em modo loading
 * @param {string} disabled - deixa o botão desativado
 * @param {string} msgDisabled - mostra uma mensagem quando passar o mouse assim que o botão estiver desativado
 * @returns {JSX.Element} O componente React.
 *
 */

export const Button = {
  Root: ButtonRoot,
  Content: ButtonContents,
  Bt: ButtonContentBt,
  A: ButtonContentA,
}

// exemplo de utilização:
// <Button.Root>
//     <Button.Content>
//         <Button.Bt colorButton='danger' colorText={'white'} action={()=>{console.log('apertei o botão 1')}}>
//             Deletar
//         </Button.Bt>
//     </Button.Content>

//     <Button.Content>
//         <Button.A colorButton='success' link="/eventos">
//             Aceitar
//         </Button.A>
//     </Button.Content>

//     <Button.Content>
//         <Button.A colorButton='danger' link="/pagar">
//             Pagar
//         </Button.A>
//     </Button.Content>
// </Button.Root>
