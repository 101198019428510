export const StatusCandidate = (status) => {
  const response = { ...statusStaff[status] }

  return <div className={`tag ${response.color}`}>{response.status}</div>
}

export const statusStaff = {
  0: { status: 'Em Aprovação', color: 'is-light' },
  1: { status: 'Candidatura Recusada', color: 'is-dark' },
  2: { status: 'Aguardando Confirmação de presença', color: 'is-warning' },
  3: { status: 'Presença Confirmada', color: 'is-success' },
  4: { status: 'Presença Recusada', color: 'is-danger' },
  5: { status: 'Check-in Realizado', color: 'is-success' },
  6: { status: 'Falta no Evento', color: 'is-danger' },
  7: { status: 'Check-out Realizado', color: 'is-success' },
  8: { status: 'check-out Não Realizado', color: 'is-warning' },
  9: { status: 'Pagamento Pendente', color: 'is-warning' },
  10: { status: 'Pagamento Realizado', color: 'is-success' },
  11: { status: 'Cancelado', color: 'is-dark' },
}
