import { ModalButtonClose } from './ModalButtonClose'
import { ModalButtonHeaderClose } from './ModalButtonHeaderClose'
import { ModalBody } from './ModalBody'
import { ModalContents } from './ModalContents'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalRoot } from './ModalRoot'

/**
 * # Root
 *
 * Cria um Componente para acomodar os componentes do modal.
 *
 * @param {React.ReactNode} children - Aaceita somente o componente de BOTÃO DE CLOSE.
 * @param {useState.VALUE} active - Aceita o VALUE para deixar ativo ou não o modal
 * @returns {JSX.Element} O componente React.
 *
 * # Header
 *
 * Cria um componente de HEADER do Card no modal.
 *
 * @param {React.ReactNode} children - Aaceita somente o componente de BOTÃO DE CLOSE.
 * @param {string} title - Recebe uma string para ser o titulo do HEADER do card
 * @returns {JSX.Element} O componente React.
 *
 * # Footer
 *
 * Cria um componente de de footer para o card do modal.
 *
 * @param {React.ReactNode} children - Aceita somente componentes de botões.
 * @returns {JSX.Element} O componente React.
 *
 * # Contents
 *
 * Cria um componente de CARD dentro do modal.
 *
 * @param {React.ReactNode} children - Aceita somente componentes do MODAL.
 *
 * @returns {JSX.Element} O componente React.
 *
 * # ButtonHeaderClose
 *
 * Cria um Componente de botão para fechar o MODAL, ele ficará dentro do HEADER do CARD.
 *
 * @param {useState.SETVALUE} setActive - Aceita o SETVALUE para colocar como false e fechar o modal
 * @returns {JSX.Element} O componente React.
 *
 * # ButtonClose
 *
 * Cria um Componente de botão para fechar o MODAL, ele ficará no canto SUPERIOR DIREITO.
 *
 * @param {useState.SETVALUE} setActive - Aceita o SETVALUE para colocar como false e fechar o modal
 * @returns {JSX.Element} O componente React.
 *
 * # Body
 *
 * Cria um componente do corpo do card entro do modal.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente do react.
 * @returns {JSX.Element} O componente React.
 */

export const Modal = {
  Root: ModalRoot,
  Contents: ModalContents,
  Header: ModalHeader,
  ButtonClose: ModalButtonClose,
  HeaderClose: ModalButtonHeaderClose,
  Footer: ModalFooter,
  Body: ModalBody,
}
