import React from 'react'

import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { File } from '../../../../../components/ui/file'
import { Path } from '../../../../../router/paths'
import { Column } from '../../../../../components/layouts/columns'
import { Icon } from '../../../../../components/icons'

export const ClientFormUserLayout = ({
  action = () => {},
  values,
  setValues,
  loading,
  options,
  disabledList,
  uid = null,
}) => {
  return (
    <>
      <Column.Root className="mt-6">
        <Column.Content className="has-text-centered is-5">
          <p>Foto de Perfil</p>
          {values?.url_foto ? (
            String(values?.url_foto).includes('https://') ? (
              <img
                alt="url_foto"
                src={values?.url_foto}
                style={{ width: '50%', borderRadius: '50%' }}
                className="has-shadow"
              ></img>
            ) : (
              <img
                alt="url_foto"
                src={values?.url_foto && URL.createObjectURL(values?.url_foto)}
                style={{ width: '50%', borderRadius: '50%' }}
                className="has-shadow"
              ></img>
            )
          ) : (
            <>
              <Column.Root className="mt-2">
                <Column.Content></Column.Content>
                <Column.Content>
                  {' '}
                  <div className="box has-background-light " style={{ height: 200, width: 200, borderRadius: '100%' }}>
                    <Icon size={130} color={'red'}>
                      user
                    </Icon>
                  </div>
                </Column.Content>
                <Column.Content></Column.Content>
              </Column.Root>
            </>
          )}

          <File.Root className="mt-5">
            <File.Input setFile={setValues} field={'url_foto'} accepts="image/*" />
            <File.Contents>
              <File.Label>
                {values?.url_foto?.name
                  ? String(values?.url_foto?.name)
                      .slice(0, 20)
                      .replace('.png', '')
                      .replace('.jpg', '')
                      .replace('.jpeg', '') +
                    '.' +
                    String(values?.url_foto?.type).replace('image/', '')
                  : 'Escolha uma imagem...'}
              </File.Label>
            </File.Contents>
          </File.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              CPF
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'cpf'}
                required={true}
                disabled={disabledList?.cpf}
                maskValue={'cpf'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              E-mail
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'email'}
                required={true}
                type="email"
                disabled={disabledList?.email}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Cargo
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'cargo'}
                required={true}
                type="text"
                disabled={disabledList?.cargo}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Setor
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'sectorId'}
                disabled={disabledList?.sectorId}
              >
                <Select.Option value={null} title={'Selecione uma opção'} />
                {/* lista de permissões */}
                {options.map((setor) => {
                  return (
                    <>
                      <Select.Option value={setor.id} title={setor.nome} />
                    </>
                  )
                })}
              </Select.Content>
            </Select.Root>
          </Input.Root>
          {!uid && <code>Senha Padrão: keap123</code>}
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.ClientUsers} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
