import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Icon } from '../../../../components/icons'

export const ClientsFormEventsJobOffersLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Data do Evento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'date'}
                required={true}
                disabled={disabledList?.date}
                type={'date'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Inicio
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'hrStart'}
                required={true}
                disabled={disabledList?.hrStart}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Check-in
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'hrCheckin'}
                required={true}
                disabled={disabledList?.hrCheckin}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Horário Check-out
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'hrCheckout'}
                required={true}
                disabled={disabledList?.hrCheckout}
                type={'time'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Tipo de Vaga
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'jobName'}
                disabled={disabledList?.jobName}
                required={true}
              >
                <Select.Option value={null} title={''} />
                {options.map((job) => {
                  return <Select.Option key={`option_${job.id}`} value={job.id} title={job.nome} />
                })}
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Cache
            </Input.Label>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'valueCacheJob'}
                required={true}
                disabled={disabledList?.valueCacheJob}
                type="number"
              ></Input.Prompt>
              <Input.Icon align="is-left">R$</Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Quantidade da Staff
            </Input.Label>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'amountJob'}
                required={true}
                disabled={disabledList?.amountJob}
                type="number"
              ></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>users</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
