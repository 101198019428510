import React from 'react'

import { Input } from '../input'

export const SelectOptionSearch = ({ value, setValue, className = '', placeholder = 'Pesquisar...' }) => {
  return (
    <option value={null}>
      <Input.Prompt
        value={value}
        setValue={setValue}
        className={`is-rounded ${className}`}
        color="is-dark"
        placeholder={placeholder}
      ></Input.Prompt>
    </option>
  )
}
