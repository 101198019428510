export const GetStatusClient = (statusClient) => {
  switch (statusClient) {
    case true:
      return 'Ativo'
    case false:
      return 'Inativo'
    default:
      return 'Não reconhecido'
  }
}
