import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  getAuth,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'

import { auth } from '.'

export const register = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return { uid: user.uid }
  } catch (error) {
    return { error: error.message || 'Erro desconhecido ao criar usuário' }
  }
}

export const logIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return { success: user }
  } catch (error) {
    return { error: error.message || 'Erro desconhecido ao criar usuário' }
  }
}

export const logOut = async () => {
  try {
    await signOut(auth)
  } catch (error) {
    console.error('Erro ao deslogar usuário:', error.message)
  }
}

export const resetPassword = (email) => {
  try {
    sendPasswordResetEmail(auth, email)
    return { success: true }
  } catch (error) {
    return { error }
  }
}

export const sendVerificationEmail = async () => {
  const auth = getAuth()
  sendEmailVerification(auth.currentUser)
    .then((response) => {
      return { sucess: response }
    })
    .catch((error) => {
      return { error }
    })
}

export const getStateAuth = async (setUser) => {
  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    console.log('currentUser: ', currentUser)
    if (currentUser) {
      setUser = currentUser // Usuário autenticado
    } else {
      setUser(null) // Nenhum usuário autenticado
    }
  })

  // Retorna a função de desinscrição para ser usada no cleanup
  return unsubscribe
}
