import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Page } from '../page'
import { Icon } from '../../icons'
import { Button } from '../../ui/button'

export const NotPermition = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Page.Section className="mt-6 pt-6">
      <Page.Content>
        <div className="has-text-centered">
          <Icon size={50}>alert</Icon>
          <p>Você não possui permissão para acessar essa página</p>
          <Button.Bt
            action={() => {
              goBack()
            }}
          >
            Voltar
          </Button.Bt>
        </div>
      </Page.Content>
    </Page.Section>
  )
}
