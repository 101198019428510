export const URLFromClickabled = (url, name) => {
  return {
    f: `HYPERLINK("${url}", "[ ${name} ]")`, // Corrigido para "HYPERLINK"
  }
}

export const schemaExportAllStaff = (data, infosAdtional) => {
  if (!Array.isArray(data)) {
    throw new Error('data is not an array of objects')
  }

  // Extrair datas únicas
  const dates = Array.from(new Set(data.map((item) => item.date).filter((date) => date)))

  // Construir resposta para cada data
  const response = dates.map((date) => {
    const sheet = data
      .filter((item) => item.date === date)
      .map((item) => ({
        IDCandidatura: item.id,
        Vaga: item.jobOffersName || '',
        Data: item.date || '',
        Candidatado_em: item.dt_created || '',
        UUID: item.uuid || '',
        Nome: item.name || '',
        CPF: item.cpf || '',
        Email: item.email || '',
        Foto: URLFromClickabled(item.url_foto, 'Acessar') || '',
        Celular: item.contact?.celular || '',
        Conta: item.payment?.conta || '',
        Agencia: item.payment?.agencia || '',
        Banco: item.payment?.banco || '',
        Pix: item.payment?.pix || '',
        Status: item.statusPlus?.status || '',
        Motivo: item.motivo || '',
        Data_Atualizacao: item.dtUpdate || '',
        Atualizado_Por: item.userUpdate || '',
      }))

    return { nameSheet: date, sheet }
  })

  return response
}

export const schemaExportAllStaffPayment = (data) => {
  if (!Array.isArray(data)) {
    throw new Error('data is not an array of objects')
  }

  const history = data
    .flatMap((item) => {
      const valueFull = item?.valueFull || 0
      const financialHistory = item.financialHistory || []

      // Adiciona o objeto `newFinancial` diretamente ao início da resposta
      const response = [
        {
          IDCandidatura: item.id,
          Vaga: item.jobOffersName || '',
          Data: item.date || '',
          UUID: item.uuid || '',
          Nome: item.name || '',
          CPF: item.cpf || '',
          Status: item.statusPlus?.status || '',
          TipoDoAjustes: 'Crédito',
          Descricao: 'Cachê',
          Valor: valueFull,
        },
      ]

      // Mapeia o histórico financeiro e adiciona os resultados ao array `response`
      response.push(
        ...financialHistory.map((financial) => {
          return {
            IDCandidatura: item.id,
            Vaga: item.jobOffersName || '',
            Data: item.date || '',
            UUID: item.uuid || '',
            Nome: item.name || '',
            CPF: item.cpf || '',
            Status: item.statusPlus?.status || '',
            TipoDoAjustes: financial.type === 'D' ? 'Débito' : 'Crédito',
            Descricao: financial.desc,
            Valor: financial.type === 'D' ? -Number(financial.value) : Number(financial.value),
          }
        }),
      )

      return response
    })
    .sort((a, b) => a.CPF >= b.CPF && a.Data <= b.Data)

  const summedValuesByUUID = history.reduce((accumulator, item) => {
    const { UUID, Valor } = item

    // Inicializa o UUID no acumulador se ainda não existir
    if (!accumulator[UUID]) {
      accumulator[UUID] = 0
    }

    accumulator[UUID] += Valor

    return accumulator
  }, {})

  const msgComment = `Digite apenas os seguintes valores: "pago" ou "pendente"`

  const payment = history
    .filter((item, index, self) => index === self.findIndex((t) => t.UUID === item.UUID))
    .map((item) => {
      const UUID = item.UUID
      const valuePay = summedValuesByUUID[UUID]
      const colabInfo = data.filter((value) => value.uuid === UUID)[0]
      return {
        UUID,
        Nome: item.Nome || '',
        CPF: item.CPF || '',
        Status: item.Status || '',
        Conta: colabInfo.payment?.conta || '',
        Agencia: colabInfo.payment?.agencia || '',
        Banco: colabInfo.payment?.banco || '',
        Pix: colabInfo.payment?.pix || '',
        Valor_A_Pagar: valuePay,
        Pagamento_Realizado: { type: 'comment', value: colabInfo.status === 10 ? 'pago' : 'pendente', msg: msgComment },
      }
    })
    .sort((a, b) => a.CPF >= b.CPF)

  console.log(payment)

  const response = [
    { sheet: history, nameSheet: 'Resumo Financeiro' },
    { sheet: payment, nameSheet: 'Pagamentos' },
  ]

  return response
}
