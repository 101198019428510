import React from 'react'

export const AlertRoot = ({ children }) => {
  return (
    <div
      className="columns"
      style={{
        position: 'fixed',
        top: 60,
        right: 0,
        zIndex: 1000,
        minHeight: 100,
        width: 300,
        overlay: 'auto',
      }}
    >
      <div className="column is-half" style={{ height: '100%', width: '100%' }}>
        {children}
      </div>
    </div>
  )
}
