import React from 'react'

import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Table } from '../../../components/ui/table'
import { Checkbox } from '../../../components/ui/checkbox'
import { Path } from '../../../router/paths'

export const SectorsLayout = ({ values, setValues, permitions, setPermissions, loading, permissionsList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Descrição
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'descricao'}
                required={true}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Table.Root>
            <Table.Body>
              {Object.keys(permissionsList).map((option) => {
                if (option !== 'id') {
                  return (
                    <React.Fragment key={`option_${option}`}>
                      <Table.Row>
                        <Table.Cell>
                          <strong>{String(option).toUpperCase()}</strong>
                        </Table.Cell>
                      </Table.Row>
                      {Object.keys(permissionsList[option]).map((item, index) => {
                        return (
                          <Table.Row key={`Row_${option}_${index}`}>
                            <Table.Cell key={`Cell_${option}_${index}`}>
                              <Checkbox.Root key={`Root_${option}_${index}`}>
                                <Checkbox.Content
                                  value={permitions}
                                  setValue={setPermissions}
                                  field={`${option}_${item}`}
                                  key={`content_${option}_${index}`}
                                />
                                <label className="ml-2">
                                  {String(item).slice(0, 1).toUpperCase() + String(item).slice(1)}
                                </label>
                              </Checkbox.Root>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </React.Fragment>
                  )
                }
                // Adicione um retorno explícito para opções não processadas
                return null
              })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.ASectors} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
