export const CreateSearchField = (values) => {
  const name = values?.name || values?.nome || null
  const cpf = values?.cpf || null

  const search = []

  if (name) {
    name.split(' ').forEach((word) => {
      search.push(String(word).toLowerCase().trim())
    })
  }
  if (cpf) {
    search.push(String(cpf).toLowerCase().trim())
  }

  return search
}
