import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'

export const ActionsOfCandidates = ({
  loading,
  status,
  align = 'centered',
  actions = {
    setAccept: () => {},
    setReject: () => {},
    setCheckin: () => {},
    setCheckout: () => {},
    setPay: () => {},
    setCancel: () => {},
    setRecover: () => {},
  },
}) => {
  return (
    <Button.Root align={align}>
      {status === 0 && (
        <>
          <Button.Content>
            <Button.Bt
              colorText={'has-text-white'}
              color="is-success"
              action={() => {
                actions.setAccept()
              }}
              loading={loading}
              title="Aceitar Candidatura"
            >
              <Icon size={20} margin={-5}>
                check
              </Icon>
            </Button.Bt>
          </Button.Content>
          <Button.Content>
            <Button.Bt
              color="is-danger"
              colorText={'has-text-white'}
              type="button"
              action={() => {
                actions.setReject()
              }}
              loading={loading}
              title="Rejeitar Candidatura"
            >
              <Icon size={15} margin={-1}>
                multiply
              </Icon>
            </Button.Bt>
          </Button.Content>
        </>
      )}

      {(status === 1 || status === 11) && (
        <Button.Content>
          <Button.Bt
            colorText={'has-text-white'}
            color="is-info"
            action={() => {
              actions.setRecover()
            }}
            loading={loading}
            title="Recuperar Candidatura"
          >
            <Icon size={20} margin={-5}>
              invite
            </Icon>
            <Icon size={20} margin={-5}>
              arrowUp
            </Icon>
          </Button.Bt>
        </Button.Content>
      )}

      {status === 3 && (
        <Button.Content>
          <Button.Bt
            color="is-success"
            colorText={'has-text-white'}
            type="button"
            action={() => {
              actions.setCheckin()
            }}
            loading={loading}
            title="Check-in"
          >
            <Icon size={15} margin={-1}>
              checkin
            </Icon>
          </Button.Bt>
        </Button.Content>
      )}

      {(status === 5 || status === 8) && (
        <Button.Content>
          <Button.Bt
            color="is-warning"
            colorText={'has-text-white'}
            type="button"
            action={() => {
              actions.setCheckout()
            }}
            loading={loading}
            title="Check-out"
          >
            <Icon size={15} margin={-1}>
              checkout
            </Icon>
          </Button.Bt>
        </Button.Content>
      )}

      {status === 9 && (
        <Button.Content>
          <Button.Bt
            color="is-success"
            colorText={'has-text-white'}
            type="button"
            action={() => {
              actions.setPay()
            }}
            loading={loading}
            title="Pagar Colaborador"
          >
            <Icon size={15} margin={-1}>
              dollar
            </Icon>
          </Button.Bt>
        </Button.Content>
      )}
      {status !== 1 && status !== 4 && status < 5 && (
        <Button.Content>
          <Button.Bt
            color="is-danger"
            colorText={'has-text-white'}
            type="button"
            action={() => {
              actions.setCancel()
            }}
            loading={loading}
            title="Cancelar Candidatura"
          >
            <Icon size={15} margin={-1}>
              trash
            </Icon>
          </Button.Bt>
        </Button.Content>
      )}
    </Button.Root>
  )
}
