import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { updateSubDocument } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { SectorsLayout } from '../../../_layouts/administration/sectorsLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../utils/erros'
import { Path } from '../../../../router/paths'

export const AEditSectors = () => {
  const { id } = useParams()
  const { setAlerts, user, setoresContext } = useGlobalContext()
  const [setor, setSetor] = useState(Object.values(setoresContext?.setores || {}).find((sector) => sector.id === id))
  const [permissions, setPermissions] = useState(setor?.permissions || {})
  const permissionsGlobais = setoresContext?.globalPermissions || {}
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const clientId = user.client.id

  const editarSetor = async () => {
    try {
      setLoading(true)
      const newSetor = { ...setor, permissions: { ...permissions } }
      await updateSubDocument('clientes', clientId, 'sectors', id, newSetor)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Setor Editado',
          msg: `O Setor "${newSetor.nome}" foi salvo com sucesso.`,
        },
      ])
      navigate(Path.ASectors)
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Form.Root
        className="mt-6 is-fullwidth"
        action={() => {
          editarSetor()
        }}
      >
        <Page.Root>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-3">Adicionar Setor</p>
            </Column.Content>
          </Column.Root>
          <Page.Content>
            <SectorsLayout
              loading={loading}
              values={setor}
              permitions={permissions}
              setPermissions={setPermissions}
              setValues={setSetor}
              permissionsList={permissionsGlobais}
            ></SectorsLayout>
          </Page.Content>
        </Page.Root>
      </Form.Root>
    </>
  )
}
