import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useGlobalContext } from '../../../context/ContextGlobal'

export const NavbarLink = ({ children, link = null, colorBackgroud = null, colorText = null, action = () => {} }) => {
  const { setNavBar } = useGlobalContext()

  const location = useLocation()

  const classe = `navbar-item ${colorBackgroud} ${colorText} ${location.pathname === link ? 'has-background-link-dark has-text-grey' : ''}`

  return (
    <Link
      to={link}
      className={classe}
      onClick={() => {
        action()
        setNavBar(false)
      }}
    >
      <p className="mx-2">{children}</p>
    </Link>
  )
}
