import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { logOut } from '../../../firebase/auth'
import { useGlobalContext } from '../../../context/ContextGlobal'
import { RemoveItemSession } from '../../../utils/sessionStorage'

export const ProtectionRedirect = ({ children, permission = null, redirectTo = '/' }) => {
  const navigate = useNavigate()
  const { setUser, setAlerts, alerts } = useGlobalContext()

  useEffect(() => {
    if (!permission) {
      // Se não há permissão, efetue o logout e redirecione
      setAlerts([
        ...alerts,
        {
          type: 'error',
          title: 'Login Expirado',
          msg: 'Por questões de segurança seu login foi expirado, por favor, logue novamente.',
        },
      ])
      logOut()
      RemoveItemSession({ key: 'user' })
      setUser([])
      navigate(redirectTo)
    }
  }, [permission, navigate, setAlerts, alerts, redirectTo, setUser])

  // Se houver permissão, renderize os filhos
  return permission ? children : null
}
