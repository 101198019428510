import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getSubDocuments } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'

export const useClientEventsHistory = () => {
  const {
    // user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const [search, setSearch] = useState('')

  const getDatas = async () => {
    const getHistory = await getSubDocuments('events', id, 'history')

    setDataContext((prev) => ({ ...prev, history: getHistory }))
  }

  const FetchGetdatas = useCallback(async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }, [])

  const dataFiltered =
    search !== ''
      ? (dataContext?.history || []).filter((history) => {
          const date = history?.dtModified || ''
          const modifiedBy = history?.modifiedBy || ''
          const typeModified = JSON.stringify(history?.modifiedBy) || ''
          const info = JSON.stringify(history?.info) || ''
          const searchString = `${date}|${modifiedBy}|${typeModified}|${info}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : dataContext?.history || []

  useEffect(() => {
    FetchGetdatas()
  }, [])

  return {
    id,
    loading,
    search,
    setSearch,
    formContext,
    setFormContext,
    FetchGetdatas,
    infosContext,
    setInfosContext,
    dataContext,
    dataFiltered,
  }
}
