import React from 'react'

export const CardHeader = ({ children, title = '' }) => {
  return (
    <header className="card-header">
      <p className="card-header-title">{title}</p>
      {children}
    </header>
  )
}
