import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Path } from '../../../router/paths'
import { useClientEvents } from './_hooks/useClientEvents'
import { TransformationforMask } from '../../../utils/masks'

export const ClientEvents = () => {
  const {
    navigate,
    loading,
    search,
    setSearch,
    dataFiltered,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteEvent,
    HandleLockUnlockEvent,
  } = useClientEvents()

  return (
    <>
      <Page.Root className="is-fullwidth">
        {/* Titulo e Botões */}
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Eventos</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A color="is-success" colorText="has-text-white" link={Path.ClientAddEvents} loading={loading}>
                  <Icon size={15}>add</Icon>
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        {/* Barra de pesquisa */}
        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        {/* Tabela de dados */}
        <p className="has-text-danger">
          Faltando fazer:
          <br />- adicionar paginação
          <br />- adicionar pesquisa
          <br />- implementar BACKEND enviar notificação ao DELETAR evento e apagar JOBOFFERS E SALVAR INFORMAÇÕES NA
          CANDIDATURA DO COLABORADOR
          <br />- implementar em todas as funções o save do HYSTORY
        </p>

        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row key={'table_events_head'}>
                  <Table.Cell size={300}>Nome</Table.Cell>
                  <Table.Cell>Criado em</Table.Cell>
                  <Table.Cell>Registro</Table.Cell>
                  <Table.Cell>Cidade</Table.Cell>
                  <Table.Cell>UF</Table.Cell>
                  <Table.Cell>Situação</Table.Cell>
                  <Table.Cell size={200}>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {dataFiltered.map((value) => {
                  return (
                    <>
                      <Table.Row
                        key={value.id}
                        onclick={() => {
                          navigate(Path.ClientEditEvents(value.id))
                        }}
                      >
                        <Table.Cell className="is-size-7 is-vcentered">
                          {TransformationforMask(value.name, 'title')}
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.dtCreated}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {value.register ? 'Intermitente' : 'Não Registrado'}
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.address.city}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.address.uf}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          <p className={`tag ${value.status ? 'is-success' : 'is-danger'}`}>
                            {value.status ? 'Aberto' : 'Fechado'}
                          </p>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: 5, marginTop: 5 }}>
                            <Button.Root align="centered">
                              <Button.Content>
                                <Button.Bt
                                  color={value.status ? 'is-danger' : 'is-success'}
                                  colorText={'has-text-white'}
                                  link={Path.ClientEditSectors(value.id)}
                                  loading={loading}
                                  action={() => {
                                    HandleLockUnlockEvent(value.status, value.id, value.name)
                                  }}
                                >
                                  {value.status ? <Icon size={18}>lock</Icon> : <Icon size={18}>unlock</Icon>}
                                </Button.Bt>
                              </Button.Content>

                              <Button.Content>
                                <Button.Bt
                                  color="is-danger"
                                  colorText={'has-text-white'}
                                  action={() => {
                                    HandleDeleteEvent(value.id, value.name)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={18}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Button.Root>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
      </Page.Root>
    </>
  )
}
