import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateNow, SomarDiasAData } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { GetStatusClient } from '../_utils'

export const useFormClients = (values, setValues, options, clientId) => {
  const { setAlerts, setLoading } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [infoClient, setInfoClient] = useState(values?.infoClient || {})
  const [endereco, setEndereco] = useState(values?.endereco || {})
  const [responsavel, setResponsavel] = useState(values?.responsavel || {})
  const [assinaturas, setAssinaturas] = useState(values?.assinaturas || {})
  const [planos, setPlanos] = useState('')
  const status = GetStatusClient(values.status)
  const listplanos = [...(options?.planos || [])].reduce((acc, item) => {
    acc[item.id] = { ...item }
    return acc
  }, {})

  const [statusUserAdmin, setStatusUserAdmin] = useState('')

  const AddPlanInList = (key) => {
    const execute = () => {
      if (Object.keys(assinaturas).includes(key)) {
        return setAlerts((prev) => [
          ...prev,
          {
            title: 'Plano já adicionado',
            msg: 'Somente é permitido inserir planos diferente',
            type: 'error',
          },
        ])
      }

      const dtNow = DateNow()
      const expired = Number(listplanos?.[key]?.type_expired) || 0

      const lastDate = Object.values(assinaturas).reduce((maiorData, assinatura) => {
        const endDate = assinatura.dtEnd
        const AtualDate = new Date(assinatura.dtEnd)
        const maiorDate = new Date(maiorData)
        return AtualDate > maiorDate ? endDate : maiorData
      }, dtNow)

      const dtStart = lastDate
      const dtEnd = SomarDiasAData(expired, dtStart)

      return setAssinaturas((prev) => ({
        ...prev,
        [key]: { ...listplanos[key], dtStart, dtEnd },
      }))
    }
    TryCatch(execute, setLoading, setAlerts)
  }

  const DeletePlansOfList = (key) => {
    const newAssinaturas = { ...assinaturas }
    if (Object.keys(newAssinaturas).length > 0) {
      delete newAssinaturas[key]
      return setAssinaturas(newAssinaturas)
    } else {
      return setAssinaturas({})
    }
  }

  useEffect(() => {
    if (endereco && infoClient && responsavel && assinaturas) {
      setValues({
        endereco,
        infoClient,
        responsavel,
        assinaturas,
        userAdmin: values.userAdmin,
        status: values.status,
      })
    }
  }, [setValues, infoClient, endereco, responsavel, assinaturas, values.status, values.userAdmin])

  useEffect(() => {
    console.log(values?.userAdmin)
    console.log(values?.userAdmin?.authInfos?.emailVerified)

    setStatusUserAdmin(
      clientId
        ? values?.userAdmin
          ? `Usuário criado e vinculado a este cliente, ${values?.userAdmin?.authInfos?.emailVerified ? 'primeiro acesso realizado!' : 'primeiro acesso não realizado, caso necessário clique em "Resetar Senha"'}`
          : 'Nenhum usuário criado e vinculado a este cliente, aperte em "Criar Login"'
        : '',
    )
  }, [values, clientId])

  return {
    search,
    setSearch,
    infoClient,
    setInfoClient,
    endereco,
    setEndereco,
    listplanos,
    status,
    planos,
    setPlanos,
    assinaturas,
    setAssinaturas,
    responsavel,
    setResponsavel,
    AddPlanInList,
    DeletePlansOfList,
    statusUserAdmin,
  }
}
