import { NavbarDivider } from './NavbarDivider'
import { NavbarDropdown } from './NavbarDropdown'
import { NavbarEnd } from './NavbarEnd'
import { NavbarLink } from './NavbarLink'
import { NavbarItemNoClicacked } from './NavbarItemNoClicacked'
import { NavbarRoot } from './NavbarRoot'
import { NavbarStart } from './NavbarStart'
import { NavbarAction } from './NavbarAction'

/**
 * # Root
 *
 * Cria um componente que alocará todo o navbar.
 *
 * @param {React.ReactNode} children - Aceita somente NAVBARSTART ou NAVBAREND.
 * @param {useState.Value} active - Variavel que controla se o navbar mobile esta ativo ou não (true ou false).
 * @param {useState.setValue} setActive - Variavel para setar o active.
 * @param {image} logo - Aceita uma imagem que mostrará como logo.
 * @param {string} color - Classes de cores do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @returns {JSX.Element} O componente React.
 *
 * # Start
 *
 * Cria um componente que mantem os itens alinhados a esquerda
 *
 * @param {React.ReactNode} children - Aceita somente NAVBARITEM ou NAVBARDROPDOWN.
 * @returns {JSX.Element} O componente React.
 *
 * # Item
 *
 * Cria um componente de item para o navbar.
 *
 * @param {React.ReactNode} children - Só aceita string ou grupos de buttons
 * @param {string} link - Link que redirecionara o usuário para outra pagina (passe o 'path' do ROUTER)
 * @param {string} colorBackgroud - Classes de cores do bulma ("has-background-white", "has-background-black", "has-background-light", "has-background-dark", "has-background-primary", "has-background-link", "has-background-info", "has-background-success", "has-background-warning", "has-background-danger")
 * @param {string} colorBackgroud - Classes de cores do bulma ("has-text-white", "has-text-black", "has-text-light", "has-text-dark", "has-text-primary", "has-text-link", "has-text-info", "has-text-success", "has-text-warning", "has-text-danger")
 * @returns {JSX.Element} O componente React.
 *
 * # End
 *
 * Cria um componente que mantem todos os items alinhados a direita
 *
 * @param {React.ReactNode} children - Aceita somente NAVBARITEM ou NAVBARDROPDOWN.
 * @returns {JSX.Element} O componente React.
 *
 * # Dropdown
 *
 * Cria um componente ao passar o mouse aparece uma lista.
 *
 * @param {React.ReactNode} children - Aceita somente navBarItens, e ele vai transforma em uma lista
 * @param {String} title - Titulo que aparecerá dentro do item.
 * @returns {JSX.Element} O componente React.
 *
 * # Divider
 *
 * Cria um componente que dividirá a lista do dropdown, é somente usado dentro do NavbarDropdown
 *
 * @returns {JSX.Element} O componente React.
 */

export const Navbar = {
  Root: NavbarRoot,
  Start: NavbarStart,
  End: NavbarEnd,
  Dropdown: NavbarDropdown,
  Divider: NavbarDivider,
  Link: NavbarLink,
  Action: NavbarAction,
  ItemInfo: NavbarItemNoClicacked,
}

//     <Navbar.Root active={active} setActive={setActive} >
//         <Navbar.End>
//             <Navbar.Dropdown title="Eventos">
//                 <Navbar.Item link={"/eventos"}>Abertos</Navbar.Item>
//                 <Navbar.Item link={"/eventos/finalizados"}>Fechados</Navbar.Item>
//                 <Navbar.Item link={"/eventos/criar"}>Criar</Navbar.Item>
//             </Navbar.Dropdown>
//             <Navbar.Dropdown title="Usuarios">
//                 <Navbar.Item link={"/usuarios"}>Lista</Navbar.Item>
//                 <Navbar.Item link={"/usuarios/adicionar"}>Adicionar</Navbar.Item>
//             </Navbar.Dropdown>
//             <Navbar.Item  link={"/configuracao"}>Configurações</Navbar.Item>
//             <Navbar.Item  link={"/"}>Sair</Navbar.Item>
//         </Navbar.End>
//     </Navbar.Root>
