import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
// import { DateTimeNow } from '../../../../../funtions/dates'
import { EditUser } from '../../../../../utils/requests'
import { TryCatch } from '../../../../../utils/general'
import { Path } from '../../../../../router/paths'
// import { Path } from '../../../../../router/paths'

export const useClientEditUsers = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { uid } = useParams()
  // const [userInfos, setUserInfo] = useState(Object.values(userContext?.users || {}).find((user) => user.id === uid))
  const sectors = infosContext?.sectors || []
  const navigate = useNavigate()
  const clientId = user.client.id

  const editUser = async () => {
    if (!user?.user?.permissions?.users_update) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para editar usuarios',
        },
      ])
    }

    delete formContext.id
    const newUserInfos = { ...formContext, uid }
    await EditUser(newUserInfos, clientId, setAlerts)
    await setFormContext({})
    navigate(Path.ClientUsers)
  }

  const setDataInFormContext = async () => {
    await setFormContext(Object.values(dataContext?.usersData || {}).find((user) => user.id === uid))
  }

  const FetchSetDataInFormContext = async () => {
    await TryCatch(setDataInFormContext, setLoading, setAlerts)
  }

  const HandleEditUser = async () => {
    await TryCatch(editUser, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchSetDataInFormContext()
  }, [dataContext])

  return { uid, sectors, loading, FetchSetDataInFormContext, HandleEditUser, formContext, setFormContext }
}
