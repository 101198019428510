import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocument, updateDocument } from '../../../../firebase/firestore'
import { uploadFile } from '../../../../firebase/storage'
// import { DateTimeNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { resetPassword } from '../../../../firebase/auth'

export const useEditAdminCollaborators = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const getDatas = async () => {
    const collaborator = await getDocument('colaboradores', id)
    const collaboratorFormData = {
      nome: collaborator?.nome,
      url_foto: collaborator?.url_foto,
      nomeSocial: collaborator?.pessoal?.nome_social,
      cpf: collaborator?.cpf,
      documento: collaborator?.pessoal?.documento,
      tipoDocumento: collaborator?.pessoal?.type_doc,
      dtNascimento: collaborator?.pessoal?.dt_nascimento,
      estadoCivil: collaborator?.pessoal?.estado_civil,
      logradouro: collaborator?.endereco?.logradouro,
      numeroEndereco: collaborator?.endereco?.numero,
      bairro: collaborator?.endereco?.bairro,
      cep: collaborator?.endereco?.cep,
      cidade: collaborator?.endereco?.cidade,
      uf: collaborator?.endereco?.uf,
      celular: collaborator?.contato?.celular,
      emergencyContacts: collaborator?.contato?.emergencia,
      conta: collaborator?.bancario?.conta,
      agencia: collaborator?.bancario?.agencia,
      banco: collaborator?.bancario?.banco,
      pix: collaborator?.bancario?.pix,
      consentimentos: collaborator?.consentimentos,
      documentos: collaborator?.documentos,
      cargos: collaborator?.cargos,
      email: collaborator?.email,
      // dt_last_login: DateTimeNow(),
      // version_app: '1.0.0',
      status: collaborator?.status,
    }

    setFormContext(collaboratorFormData)
  }

  const editCollaborator = async () => {
    const editedCollaborator = {
      nome: formContext?.nome,
      url_foto: formContext?.url_foto,
      cpf: formContext?.cpf,
      pessoal: {
        nome_social: formContext?.nomeSocial,
        documento: formContext?.documento,
        type_doc: formContext?.tipoDocumento,
        dt_nascimento: formContext?.dtNascimento,
        estado_civil: formContext?.estadoCivil,
      },
      endereco: {
        logradouro: formContext?.logradouro,
        numero: formContext?.numeroEndereco,
        bairro: formContext?.bairro,
        cep: formContext?.cep,
        cidade: formContext?.cidade,
        uf: formContext?.uf,
      },
      contato: { celular: formContext?.celular, emergencia: formContext?.emergencyContacts },
      bancario: {
        conta: formContext?.conta,
        agencia: formContext?.agencia,
        banco: formContext?.banco,
        pix: formContext?.pix,
      },
      consentimentos: { ...formContext?.consentimentos },
      documentos: { ...formContext?.documentos },
      cargos: { ...formContext?.cargos },
      email: formContext?.email,
      // dt_last_login: DateTimeNow(),
      // version_app: '1.0.0',
      status: formContext?.status,
      // notification_token: 'teste',
    }

    const imageTimestamp = new Date().toISOString()

    const pathUploadFile = `collaborators/${imageTimestamp}/foto_${imageTimestamp}`

    if (editedCollaborator.url_foto) {
      const urlFoto = await uploadFile(editedCollaborator.url_foto, pathUploadFile)
      editedCollaborator.url_foto = urlFoto
    }

    await updateDocument('colaboradores', id, editedCollaborator)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Colaborador editado',
        msg: `O colaborador ${editedCollaborator.nome} foi editado com sucesso.`,
      },
    ])
  }

  const handlePrintScreen = () => {
    console.log('teste')

    if (!user?.user?.permissions?.clientes_imprimir === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você Não Possui permissão para imprimir os dados do colaborador',
          type: 'error',
        },
      ])
    }

    window.print()
  }

  const handleResetPassword = () => {
    if (!user?.user?.permissions?.clientes_resetarSenhaAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para resetar a senha de administrador do cliente',
          type: 'error',
        },
      ])
    }

    const sendEmailReset = resetPassword(formContext?.email)
    console.log('sendEmailReset:', sendEmailReset)
    if (sendEmailReset.success) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Resete de Senha Enviado',
          msg: `Um E-mail para trocar senha foi enviado ao e-mail do responsavel: ${formContext?.email}`,
          type: 'success',
        },
      ])
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Erro ao enviar E-mail',
          msg: `erro: ${sendEmailReset?.error}`,
          type: 'error',
        },
      ])
    }
  }

  const handleEditCollaborators = async () => {
    await TryCatch(editCollaborator, setLoading, setAlerts)
  }

  const FetchDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchDatas()
  }, [])

  return {
    loading,
    formContext,
    setFormContext,
    handlePrintScreen,
    handleResetPassword,
    handleEditCollaborators,
  }
}
