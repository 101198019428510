import React from 'react'

export const ModalHeader = ({ children, title = null, style = {} }) => {
  return (
    <header className="modal-card-head" style={style}>
      {title && <p className="modal-card-title">{title}</p>}
      {children}
    </header>
  )
}
