import { PageHeroBody } from './PageHeroBody'
import { PageHeroFoot } from './PageHeroFoot'
import { PageHeroHead } from './PageHeroHead'
import { PageHeroRoot } from './PageHeroRoot'

/**
 * # Root
 *
 * Cria um componente com a classe "hero".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 * @param {string} color - Classes de cores do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {string} size - Classes de tamanhos do bulma ( "is-halfheight", "is-fullheight", "is-fullheight-with-navbar", "is-large", "is-medium", "is-small" )
 *
 * # Head
 *
 * Cria um componente com a classe "hero-head".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 *
 * # Foot
 *
 * Cria um componente com a classe "hero-foot".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 *
 * # Body
 *
 * Cria um componente com a classe "hero-body".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 */

export const Hero = {
  Root: PageHeroRoot,
  Body: PageHeroBody,
  Head: PageHeroHead,
  Foot: PageHeroFoot,
}

//     <Page.Root>
//         <Page.SideBar>
//             <SideBar.Root>
//                  ...
//             </SideBar.Root>
//         </Page.SideBar>

//         <Page.Content>
//              <Page.Section>
//                  <Page.Container>
//                      Aqui ficará o conteúdo
//                  <Page.Container/>
//              <Page.Section/>
//         </Page.Content>
//     </Page.Root>
