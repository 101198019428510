import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import { schemaExportAllStaff, schemaExportAllStaffPayment } from './schemaExports'
import { getDocuments, getSubDocuments } from '../firebase/firestore'
import { GetPaginationStaff } from '../firebase/functions'
import { statusStaff } from '../screens/client/events/_utils/statusCandidate'

export const ExportToExcel = (data, fileName = 'dados.xlsx') => {
  if (!data || data.length === 0) {
    throw new Error('Pesquisa vazia, não conseguimos achar colaborador para criar a planilha de exportação')
  }

  // Cria uma nova pasta de trabalho
  const workbook = XLSX.utils.book_new()
  const enrichedExtras = []
  const ColumnsLetters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
  ]

  data.forEach((values) => {
    const { nameSheet, sheet } = values
    const header = Object.keys(sheet[0])
    const sheetData = sheet.map((row, numberRow) => {
      let rowData = Object.values(row)

      // Verifica se algum valor na linha é um objeto
      if (rowData.some((value) => typeof value === 'object')) {
        rowData = rowData.map((value, index) => {
          if (typeof value === 'object') {
            const { type, value: val, msg } = value
            const col = ColumnsLetters[index]
            const cel = `${col}${numberRow + 2}`
            enrichedExtras.push({ cel, msg, type })

            return val
          }
          return value
        })
      }

      return rowData // Certifique-se de retornar rowData para que o map funcione corretamente
    })
    const sheetDataArray = [header, ...sheetData]
    console.log(sheetData)
    console.log(enrichedExtras)
    // Converte os dados para uma planilha
    // const worksheet = XLSX.utils.json_to_sheet(sheet)

    const worksheet = XLSX.utils.aoa_to_sheet(sheetDataArray)

    enrichedExtras.forEach((config) => {
      console.log(config)
      if (config.type === 'comment') {
        if (!worksheet[config.cel].c) worksheet[config.cel].c = []
        worksheet[config.cel].c.push({ a: 'SheetJS', t: config.msg })
        worksheet[config.cel].c.hidden = true
      }
    })

    const calculateColumnWidths = (data) => {
      const columnWidths = []
      const limitLength = 16

      data.forEach((row) => {
        row.forEach((cell, colIndex) => {
          const cellLength = (cell ? cell.toString().length : 0) + 4

          if (!columnWidths[colIndex] || columnWidths[colIndex].wch < cellLength) {
            columnWidths[colIndex] = { wch: cellLength <= limitLength ? cellLength : limitLength }
          }
        })
      })

      return columnWidths
    }

    const colWidths = calculateColumnWidths(sheetDataArray)

    // Calcula as larguras das colunas
    worksheet['!cols'] = colWidths

    worksheet['!rows'] = Array(1).fill({ hpt: 30 })

    // Adiciona a planilha ao workbook com o nome da aba
    XLSX.utils.book_append_sheet(workbook, worksheet, nameSheet)
  })

  // Converte a pasta de trabalho para um arquivo binário
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })

  // Salva o arquivo como um blob
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  saveAs(blob, fileName)
}

export const ExportAllStaff = async (idEvent, dataContext, infosContext, statusStaff, getDatas, setAlerts) => {
  setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Exportando Planilha`,
      msg: 'Estamos coletando as informações necessárias para gerar sua planilha. Esse processo pode levar alguns minutos...',
    },
  ])
  const newLimit = dataContext?.staff?.pagination?.totalItems || 5000
  const data = await getDatas({ limit: newLimit })
  const jobOffers = infosContext?.jobOffers || null
  const dataEnriched = data.staff.data.map((values) => {
    const idJobOffer = values?.idJobOffers
    const status = values?.status
    return {
      ...values,
      jobOffersName: jobOffers[idJobOffer].positionName,
      date: jobOffers[idJobOffer].date,
      statusPlus: statusStaff[status] || { status: 'Sem status' },
    }
  })

  const response = schemaExportAllStaff(dataEnriched)
  console.log(response)
  ExportToExcel(response, `Staff_${idEvent}.xlsx`)
  console.log(response)
  console.log(data?.staff)
  return setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Planilha Conclúida`,
      msg: 'O Processo de construir sua planilha terminou com exito...',
    },
  ])
}

export const ExportAllStaffPayment = async (idEvent, setAlerts) => {
  setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Exportando Planilha De Pagamentos`,
      msg: 'Estamos coletando as informações necessárias para gerar sua planilha. Esse processo pode levar alguns minutos...',
    },
  ])
  const query = {
    status: 9,
  }
  const data = await GetPaginationStaff(1, 5000, idEvent, query, { field: 'status', direction: 'asc' })
  const getJobOffers = await getSubDocuments('events', idEvent, 'jobOffers')
  const getPositions = await getDocuments('cargos')
  const positionsById = getPositions.reduce((acc, curr) => {
    acc[curr.id] = curr.nome
    return acc
  }, {})
  const jobOffers = getJobOffers.reduce((acc, curr) => {
    acc[curr.id] = { ...curr, positionName: positionsById[curr.idPosition] }
    return acc
  }, {})
  const dataEnriched = data.data.map((values) => {
    const idJobOffer = values?.idJobOffers
    const status = values?.status
    return {
      ...values,
      jobOffersName: jobOffers[idJobOffer].positionName,
      date: jobOffers[idJobOffer].date,
      statusPlus: statusStaff[status] || { status: 'Sem status' },
      valueFull: Number(jobOffers[idJobOffer].value),
    }
  })

  const response = schemaExportAllStaffPayment(dataEnriched)
  console.log(response)
  ExportToExcel(response, `Pagamentos_Staff_${idEvent}.xlsx`)
  console.log(response)
  console.log(data?.staff)
  return setAlerts((prev) => [
    ...prev,
    {
      type: 'success',
      title: `Planilha De Pagamentos Conclúida`,
      msg: 'O Processo de construir sua planilha terminou com exito...',
    },
  ])
}
