import { useEffect } from 'react'

import { TryCatch } from '../../../../../../utils/general'
import { useGlobalContext } from '../../../../../../context/ContextGlobal'

export const useClientFormSectorLayout = (permissions, setPermissions, checkAlls) => {
  const { setAlerts, setLoading } = useGlobalContext()
  const copyPermissions = async (dataContext, permissionsIdToCopy) => {
    console.log(dataContext)
    console.log(permissionsIdToCopy)

    const sectorCopy = (dataContext?.sectors || []).filter((sector) => sector.id === permissionsIdToCopy)[0]
    console.log(sectorCopy)
    if (!permissionsIdToCopy) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Setor não selecionado',
          msg: 'Selecione um setor para ser copiado',
        },
      ])
    }
    console.log(sectorCopy?.permissions)
    if (sectorCopy?.permissions) {
      await setPermissions((prev) => ({ ...prev, ...sectorCopy?.permissions }))
    }
  }

  const HandleCopyPermissionsFromAnotherSector = async (dataContext, permissionsIdToCopy) => {
    await TryCatch(
      () => {
        copyPermissions(dataContext, permissionsIdToCopy)
      },
      setLoading,
      setAlerts,
    )
  }

  // realiza o check total dos campos filhos na lista
  useEffect(() => {
    const updatedPermissions = { ...permissions }

    Object.keys(checkAlls).forEach((checkbox) => {
      if (checkbox.includes('_checkall')) {
        const field = checkbox.split('_')[0]
        const stateCheck = checkAlls[checkbox]
        Object.keys(permissions).forEach((newCheckbox) => {
          if (newCheckbox.includes(field)) {
            updatedPermissions[newCheckbox] = stateCheck
          }
        })
      }
    })

    // Apenas atualiza o estado se houver mudanças
    if (JSON.stringify(updatedPermissions) !== JSON.stringify(permissions)) {
      setPermissions(updatedPermissions)
    }
  }, [checkAlls])

  return { HandleCopyPermissionsFromAnotherSector }
}
