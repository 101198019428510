import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { CollaboratorsForm } from './_components/form'
import { useCollaboratorsForm, useEditAdminCollaborators } from './_hooks'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { getCollaboratorStatusType } from './_utils/collaboratorStatus'

export const EditAdminCollaborator = () => {
  const { loading, formContext, handlePrintScreen, handleResetPassword, handleEditCollaborators } =
    useEditAdminCollaborators()

  const { blockUnblockCollaborator } = useCollaboratorsForm()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
      <Form.Root
        action={() => {
          handleEditCollaborators()
        }}
        className="mt-5"
      >
        <Column.Root>
          <Column.Content>
            <h1 className="subtitle is-size-3">Colaborador</h1>
          </Column.Content>
        </Column.Root>

        {loading && (
          <Column.Root className="is-mobile">
            <Column.Content></Column.Content>
            <Column.Content className="is-1">
              <Icon>loading</Icon>
              <p className="is-size-7 has-text-grey">carregando...</p>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        )}

        {!loading && (
          <>
            <Column.Root>
              <Column.Content>
                <Input.Root className="mb-3">
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Situação
                  </Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">
                      {getCollaboratorStatusType(formContext?.status)}
                    </Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content size="">
                <Column.Root>
                  <Column.Content className="has-text-centered is-one-fifth" type="button">
                    {formContext?.status === 1 && (
                      <>
                        <Button.Bt
                          color="is-danger"
                          type="button"
                          className="mx-1"
                          size={'is-small'}
                          loading={loading}
                          action={blockUnblockCollaborator}
                        >
                          <Icon>lock</Icon>
                        </Button.Bt>
                        <p className="is-size-7 has-text-grey">Bloquear</p>
                      </>
                    )}
                    {formContext?.status === 9 ||
                      (formContext?.status === 2 && (
                        <>
                          <Button.Bt
                            color="is-success"
                            type="button"
                            className="mx-1"
                            size={'is-small'}
                            loading={loading}
                            action={blockUnblockCollaborator}
                          >
                            <Icon>lock</Icon>
                          </Button.Bt>
                          <p className="is-size-7 has-text-grey">Ativar</p>
                        </>
                      ))}
                  </Column.Content>

                  {formContext?.status !== 0 && (
                    <>
                      {/* Gerar Fatura: Gera uma nova fatura */}
                      <Column.Content className="has-text-centered is-one-fifth">
                        <Button.Bt color="is-light" type="button" className="mx-1" size={'is-small'} loading={loading}>
                          <Icon>bill</Icon>
                        </Button.Bt>
                        <p className="is-size-7 has-text-grey">Gerar Recibo</p>
                      </Column.Content>

                      {/* Hitórico financeiro: mostra o histórico financeiro do cliente */}
                      <Column.Content className="has-text-centered is-one-fifth" type="button">
                        <Button.Bt color="is-light" type="button" className="mx-1" size={'is-small'} loading={loading}>
                          <Icon>history</Icon>
                        </Button.Bt>
                        <p className="is-size-7 has-text-grey">Histórico Financeiro</p>
                      </Column.Content>
                    </>
                  )}

                  <Column.Content className="has-text-centered is-one-fifth" type="button">
                    <Button.Bt
                      color="is-light"
                      type="button"
                      className="mx-1"
                      size={'is-small'}
                      action={handlePrintScreen}
                      loading={loading}
                    >
                      <Icon>print</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">Imprimir</p>
                  </Column.Content>

                  <Column.Content className="has-text-centered is-one-fifth" type="button">
                    <Button.Bt
                      color="is-light"
                      type="button"
                      className="mx-1"
                      size={'is-small'}
                      action={handleResetPassword}
                      loading={loading}
                    >
                      <Icon>password</Icon>
                    </Button.Bt>
                    <p className="is-size-7 has-text-grey">Resetar Senha</p>
                  </Column.Content>
                </Column.Root>
              </Column.Content>
            </Column.Root>
            <CollaboratorsForm />
          </>
        )}
      </Form.Root>
    </Page.Section>
  )
}
