import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

// import { RouterProviderRoot } from './router/index';
import { MainRouter } from './router'
import { Context } from './context'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Context.Global>
      <MainRouter />
    </Context.Global>
  </React.StrictMode>,
)
