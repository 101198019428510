import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { getDocuments } from '../../../../firebase/firestore'
import { GetPaginationFavoriteCollaborators } from '../../../../firebase/functions'

export const useClientFormTeams = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    setLoading,
    loading,
    setFormContext,
    formContext,
  } = useGlobalContext()

  const [search, setSearch] = useState('')
  const clientId = user?.client?.id || null

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getCargos = await getDocuments('cargos')
    const positionById = getCargos.reduce((acc, value) => {
      const id = String(value.id)
      const name = value?.name || value?.nome
      acc[id] = name
      return acc
    }, {})
    console.log(positionById)
    setInfosContext({ positionById })
  }

  const getDataQuery = async (query = null) => {
    let newQuery
    if (query) {
      newQuery = { ...query }
    } else {
      newQuery = { search: String(search).toLowerCase().trim() }
    }

    const getcolaborators = await GetPaginationFavoriteCollaborators(1, 100, clientId, newQuery, 'dt_created')
    console.log(getcolaborators)
    setDataContext(getcolaborators)
  }

  const addCollaboratorInTeams = async (colab) => {
    console.log(colab)
    const newColab = { [colab.uuid]: { ...colab, position: colab?.positions[0] || null } }
    await setFormContext((prev) => ({ ...prev, teams: { ...(prev?.teams || {}), ...newColab } }))
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Colaborador Adicionado'}`,
        msg: `O Colaborador foi adicionado a equipe com sucesso...`,
      },
    ])
  }

  const deleteCollaboratorInTeams = async (colab) => {
    await setFormContext((prev) => {
      const newTeams = Object.keys(prev?.teams || {}).reduce((acc, key) => {
        // Se o UUID não for igual ao do colaborador que queremos excluir, adiciona ao novo objeto
        if (prev.teams[key].uuid !== colab.uuid) {
          acc[key] = prev.teams[key]
        }
        return acc
      }, {})

      console.log(newTeams)
      return { ...prev, teams: newTeams }
    })

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Colaborador Deletado`,
        msg: `O colaborador foi removido da equipe com sucesso...`,
      },
    ])
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const FetchGetDataQuery = async (query = null) => {
    await TryCatch(
      () => {
        getDataQuery(query)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleAddCollaboratorInTeams = async (colab) => {
    await TryCatch(
      () => {
        addCollaboratorInTeams(colab)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeleteCollaboratorInTeams = async (colab) => {
    await TryCatch(
      () => {
        deleteCollaboratorInTeams(colab)
      },
      setLoading,
      setAlerts,
    )
  }

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    setDataContext({})
    FetchGetInfos()
    FetchGetDataQuery({ page: 1 })
  }, [])

  useEffect(() => {
    console.log(formContext)
  }, [formContext])

  return {
    loading,
    search,
    setSearch,
    dataContext,
    infosContext,
    FetchGetDataQuery,
    FetchGetInfos,
    HandleAddCollaboratorInTeams,
    HandleDeleteCollaboratorInTeams,
  }
}
