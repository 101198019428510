import { useLocation, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getSubDocuments, updateDocument, updateSubDocument } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { ExportAllStaffPayment } from '../../../../utils/exportToExcel'
import { SetHistoryEvent } from '../../../../utils/requests'
export const useClientEventsSideBar = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const location = useLocation()

  const HandleExportExcelPayment = async () => {
    await TryCatch(
      () => {
        ExportAllStaffPayment(id, setAlerts)
      },
      setLoading,
      setAlerts,
    )
    await SetHistoryEvent(id, user.user.nome, `Planilha de Pagamentos extraida`)
  }

  const reOpenEvent = async () => {
    const reason = window.prompt('justifique a reabertura do Evento: ')
    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa Obrigatória',
          msg: 'Para reabrir o evento, é obrigatório justificativa ou motivo da reabertura',
        },
      ])
    }
    await updateDocument('events', id, { status: true })
    setFormContext((prev) => ({ ...prev, statusEvent: true }))
    await SetHistoryEvent(id, user.user.nome, 'Reabertura do Evento', { Motivo: reason })
    return setAlerts((prev) => [
      ...prev,
      { type: 'success', title: 'Evento Aberto', msg: 'A abertura do evento foi realizado com sucesso' },
    ])
  }

  const openJobsInEvent = async (status) => {
    const jobOffers = await getSubDocuments('events', id, 'jobOffers')
    const promises = jobOffers.map(async (jobOffer) => {
      const newjob = { status }
      await updateSubDocument('events', id, 'jobOffers', jobOffer.id, newjob)
    })

    await Promise.all(promises)
    await updateDocument('events', id, { openAllJobs: status })
    await setFormContext((prev) => ({ ...prev, openAllJobs: status }))
    await SetHistoryEvent(id, user.user.nome, `${status ? 'Abertura total das vagas' : 'Fechamento total das vagas'}`)
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Vagas ${status ? 'Abertas' : 'Fechadas'}`,
        msg: `Todas as vagas desse evento foram ${status ? 'abertas' : 'Fechadas'}`,
      },
    ])
  }

  const HandleReOpenEvent = async () => {
    await TryCatch(reOpenEvent, setLoading, setAlerts)
  }

  const HandleOpenAllJobs = async () => {
    await TryCatch(
      () => {
        openJobsInEvent(true)
      },
      setLoading,
      setAlerts,
    )
  }
  const HandleCloseAllJobs = async () => {
    await TryCatch(
      () => {
        openJobsInEvent(false)
      },
      setLoading,
      setAlerts,
    )
  }

  return {
    loading,
    formContext,
    id,
    location,
    HandleReOpenEvent,
    HandleOpenAllJobs,
    HandleCloseAllJobs,
    HandleExportExcelPayment,
  }
}
