import React from 'react'

import { ClientFormSectorsLayout } from './_form/clientFormSectorsLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { useClientEditSectors } from './_hooks/useClientEditSectors'

export const ClientEditSectors = () => {
  const {
    loading,
    dataContext,
    formContext,
    setFormContext,
    HandleEditSector,
    permissions,
    setPermissions,
    permissionsList,
    checkAlls,
    setCheckAlls,
  } = useClientEditSectors()

  return (
    <>
      <Form.Root
        className="mt-6 pt-5 is-fullwidth"
        action={() => {
          HandleEditSector()
        }}
      >
        <Page.Root>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-3">Editar Setor</p>
            </Column.Content>
          </Column.Root>
          <Page.Content>
            <ClientFormSectorsLayout
              dataContext={dataContext}
              loading={loading}
              values={formContext}
              setValues={setFormContext}
              permitions={permissions}
              setPermissions={setPermissions}
              checkAlls={checkAlls}
              setCheckAlls={setCheckAlls}
              permissionsList={permissionsList}
            ></ClientFormSectorsLayout>
          </Page.Content>
        </Page.Root>
      </Form.Root>
    </>
  )
}
