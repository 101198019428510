import React, { useEffect, useState, useCallback } from 'react'

import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { deleteDocument, getDocuments } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Input } from '../../../../components/ui/input'
import { TratamentoError } from '../../../../utils/erros'
import { Page } from '../../../../components/layouts/page'
import { Path } from '../../../../router/paths'

export const ADocuments = () => {
  const { user, setAlerts, documentsContext, setDocumentsContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const documentos = documentsContext?.documentos || []

  // coleta uma lista de planos
  const getInfos = useCallback(async () => {
    try {
      setLoading(true)
      const getCargos = await getDocuments('cargos')
      const getDocumentos = await getDocuments('documentos')
      const newDocumentos = getDocumentos.map((item) => {
        const inCargo = getCargos.some((cargos) => {
          const documentosIds = cargos?.documentos || []
          const found = documentosIds.includes(item.id)
          return found
        })
        return { ...item, blocked: !inCargo } // Adiciona se ele é bloqueado para deletar ou não
      })

      setDocumentsContext({ documentos: newDocumentos })
    } catch (error) {
      // mostra o alerta de error para o usuario.
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }, [setDocumentsContext, setAlerts])

  // deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
  const excluirDocumento = async (id) => {
    try {
      if (user?.user?.permissions?.documentos_excluir) {
        const blocked = documentos.find((value) => value.id === id)?.blocked
        if (blocked) {
          setLoading(true)
          await deleteDocument('documentos', id)
          await getInfos()
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'success',
              title: 'Documento Excluído',
              msg: 'Documento excluído da sua base de dados',
            },
          ])
        } else {
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'error',
              title: 'Documento em uso',
              msg: 'Você não pode deletar esse documentos, pois ele está em uso por algum cargo',
            },
          ])
        }
      } else {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Permissão Negada',
            msg: 'Você não possui permissão para apagar Documentos',
          },
        ])
      }
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    getInfos()
  }, [getInfos])

  const docsFiltered =
    search !== ''
      ? Object.values(documentos).filter((docs) => {
          const searchString = `${docs.nome}|${docs.descricao}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : Object.values(documentos)

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Documentos</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    getInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A
                  className=""
                  color="is-success"
                  colorText="has-text-white"
                  link={Path.AAddDocuments}
                  loading={loading}
                >
                  +
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>Descrição</Table.Cell>
                  <Table.Cell>Tipo</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {docsFiltered.map((value, index) => {
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell className="is-size-7 is-vcentered">{value.nome}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.descricao}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.type}</Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: -10, marginTop: 5 }}>
                            <Button.Root align="centered">
                              <Button.Content>
                                <Button.A
                                  size="is-small"
                                  color="is-warning"
                                  link={Path.AEditDocuments(value.id)}
                                  loading={loading}
                                >
                                  <Icon size={15}>pen</Icon>
                                </Button.A>
                              </Button.Content>
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-danger"
                                  action={() => {
                                    excluirDocumento(value.id)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Button.Root>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
      </Page.Root>
    </>
  )
}
