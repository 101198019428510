import React, { useEffect, useState, useCallback } from 'react'

import { Page } from '../../../../components/layouts/page'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import {
  deleteSubDocument,
  getDocument,
  getSubDocuments,
  getSubDocumentsWithQuery,
} from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Input } from '../../../../components/ui/input'
import { TratamentoError } from '../../../../utils/erros'
import { Path } from '../../../../router/paths'
// import { useNavigate } from 'react-router-dom';

export const ASectors = () => {
  // adicionar o setAlerts

  const { user, setoresContext, setSetoresContext, setAlerts } = useGlobalContext()
  const setores = setoresContext?.setores || {}
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getInfos = useCallback(async () => {
    try {
      setLoading(true)
      // coleta os setores criados pelo cliente
      const getSetores = await getSubDocuments('clientes', clientId, 'sectors')

      // verifica se o setor está setado em algum usuario, caso sim ele bloqueia o botão de apagar
      const setoresInUse = {}

      const promises = getSetores.map(async (setor) => {
        const getuser = await getSubDocumentsWithQuery(`clientes`, clientId, 'users', 'sectorId', '==', setor.id)

        if (getuser.length > 0) {
          setoresInUse[setor.id] = true
        } else {
          setoresInUse[setor.id] = false
        }
      })

      // Aguarda a resolução de todas as promessas
      await Promise.all(promises)

      // Cria uma nova lista de Setores com o campo 'blocked'
      const sectors = getSetores.map((setor) => {
        return { ...setor, blocked: setoresInUse[setor?.id] || false }
      })

      // coleta as permissões globais em CONFIG/PERMISSIONS
      const getGlobalPermissions = await getDocument('configs', 'permissions')

      // realiza o tratamento da lista colocando o item junto com a permissão ficando assim: "Evento_Criar"

      // como funciona o globalPermissionsList:
      // getGlobalPermissions = {evento:{Criar:true,Vizualizar}, usuarios:{Criar:true,Vizualizar}}
      // objetofinal = {}
      // title = evento ou usuarios
      // getGlobalPermissions[title] = [Criar, Vizualizar]
      // item = criar ou vizualziar ou etc...
      // [`${title}_${item}`] = EVENTO_CRIAR
      // globalPermissionsList = {Evento_criar:false, usuario_criar:false, usuario_Vizualizar:false}

      const globalPermissionsList = Object.keys(getGlobalPermissions).reduce((objetoFinal, title) => {
        Object.keys(getGlobalPermissions[title]).forEach((item) => {
          if (title !== 'id') {
            objetoFinal[`${title}_${item}`] = false
          }
        })
        return objetoFinal
      }, {})

      // salva todas as informações coletadas no context.
      setSetoresContext({
        setores: sectors,
        globalPermissions: getGlobalPermissions,
        globalPermissionsList,
      })
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }, [clientId, setSetoresContext, setAlerts])

  const excluirSetor = async (id) => {
    try {
      if (user?.user?.permissions?.setores_excluir) {
        // se o setor não estiver em uso ele deixa deletar, porém se estiver ele mostra uma mensagem no alerts.
        if (setores.find((setor) => setor.id === id)?.blocked) {
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'error',
              title: 'Setor Bloqueado',
              msg: 'O setor esta em uso por algum usuário, por esse motivo não é possivel deletar',
            },
          ])
        } else {
          setLoading(true)
          await deleteSubDocument('clientes', clientId, 'setores', id)
          await getInfos()
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'success',
              title: 'Setor Excluído',
              msg: 'Setor excluído da sua base de dados',
            },
          ])
        }
      } else {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Permissão Negada',
            msg: 'Você não possui permissão para apagar setores',
          },
        ])
      }
    } catch (error) {
      return setAlerts((prev) => [...prev, { title: 'Error:', msg: TratamentoError(error), type: 'error' }])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInfos()
  }, [getInfos])

  const usersFiltered =
    search !== ''
      ? Object.values(setores).filter((setor) => {
          const searchString = `${setor.nome}|${setor.descricao}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(setores)

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Setores</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    getInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A
                  className=""
                  color="is-success"
                  colorText="has-text-white"
                  link={Path.AAddScetors}
                  loading={loading}
                >
                  +
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row>
                  <Table.Cell>Nome</Table.Cell>
                  <Table.Cell>Descrição</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {usersFiltered.map((value, index) => {
                  return (
                    <>
                      <Table.Row>
                        <Table.Cell className="is-size-7 is-vcentered">{value.nome}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.descricao}</Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: -10, marginTop: 5 }}>
                            <Button.Root align="centered">
                              <Button.Content>
                                <Button.A
                                  size="is-small"
                                  color="is-warning"
                                  link={Path.AEditSectors(value.id)}
                                  loading={loading}
                                >
                                  <Icon size={15}>pen</Icon>
                                </Button.A>
                              </Button.Content>
                              <Button.Content>
                                <Button.Bt
                                  size="is-small"
                                  color="is-danger"
                                  action={() => {
                                    excluirSetor(value.id)
                                  }}
                                  loading={loading}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            </Button.Root>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
        <Page.Content></Page.Content>
      </Page.Root>
    </>
  )
}
