import React from 'react'

import { useGlobalContext } from '../../../context/ContextGlobal'

export const NavbarAction = ({ children, link = null, colorBackgroud = null, colorText = null, action = () => {} }) => {
  const { setNavBar } = useGlobalContext()

  const classe = `navbar-item ${colorBackgroud} ${colorText}`

  return (
    <a
      className={classe}
      onClick={() => {
        action()
        setNavBar(false)
      }}
      href={link}
    >
      <p className="mx-2">{children}</p>
    </a>
  )
}
