import React from 'react'

// o children será o texto que é escrito no botão
// as colores recebe todas as cores do bulma sem o "IS-"
// o action recebe o função que ao apertar o botão ela será executada

export const ButtonContentBt = ({
  children,
  className = '',
  color = 'is-light',
  colorText = null,
  size = null,
  action = () => {},
  disabled = null,
  type = 'button',
  loading = null,
  msgDisabled = '',
  title = '',
}) => {
  return (
    <button
      className={`button ${color} ${size} ${colorText} ${className} ${loading && 'is-loading'}`}
      onClick={(event) => {
        event.stopPropagation()
        action()
      }}
      disabled={disabled}
      type={type}
      title={disabled ? msgDisabled : title}
    >
      {children}
    </button>
  )
}
