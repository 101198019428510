import { FileContents } from './FileContents'
import { FileIcon } from './FileIcon'
import { FileInput } from './FileInput'
import { FileLabel } from './FileLabel'
import { FileRoot } from './FileRoot'

/**
 * # Root
 *
 * Cria um componente "Card" do bulma.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react, mas de preferencial os componentes do CARD.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {React.CSSProperties} style - Recebe um classe de style do REACT
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @returns {JSX.Element} O componente React.
 *
 * # input
 *
 * Cria um componente de INPUT.
 * @param {string} setFile Função usada para definir ou manipular arquivos selecionados pelo usuário em um input de arquivos.
 * @param {string} field Inicializa ou redefine um campo com um valor null, indicando a ausência de um valor inicial.
 * @param {string} accept Atributo do elemento <input type="file"> que especifica os tipos de arquivos aceitos. Utiliza MIME types para definir os formatos permitidos.
 * @param {string} required Indica que o campo de input não é obrigatório. Se o valor for null, o campo não é obrigatório, mas se for true, o campo se torna obrigatório.
 *
 *
 * # Contents
 *
 * Cria um componente para receber todo conteúdo do card.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react, mas de preferencial os componentes do CARD.
 *
 * # Label
 *
 * Cria um componente para acomodar um texto em cima do input.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react, mas de preferencial os componentes do CARD.
 *
 * # Icon
 * Cria um componente de ICONE para o File.
 * @param {React.ReactNode} children - Aceita qualquer componente react, mas de preferencial os componentes do CARD.
 */

export const File = {
  Root: FileRoot,
  Input: FileInput,
  Contents: FileContents,
  Label: FileLabel,
  Icon: FileIcon,
}
