import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { deleteDocument, getDocumentsWithQuery, getSubDocuments, updateDocument } from '../../../../firebase/firestore'
export const useClientEvents = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const events = dataContext?.events || {}
  const assinaturasDatas = (infosContext?.assinaturas || []).sort((a, b) => {
    return new Date(a.dtStart) - new Date(b.dtStart)
  })
  const [search, setSearch] = useState('')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async () => {
    const getEvents = await getDocumentsWithQuery('events', 'clientId', '==', clientId)
    // salva todas as informações coletadas no context.
    setDataContext({ events: getEvents })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getAssinaturas = await getSubDocuments('clientes', clientId, 'assinaturas')

    setInfosContext({ assinaturas: getAssinaturas })
  }

  const deleteEvent = async (idEvent, eventName) => {
    await deleteDocument('events', idEvent)

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Evento Deletado',
        msg: `O Evento ${eventName} foi deletado com sucesso.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetDatas()
  }

  const lockUnlockEvent = async (status, idEvent, eventName) => {
    const newEvent = {
      status: !status,
    }

    await updateDocument('events', idEvent, newEvent)

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: !status ? 'Evento Aberto' : 'Evento Fechado',
        msg: !status
          ? `O evento "${eventName}" está disponível para visualização pelos colaboradores.`
          : `O evento "${eventName}" está invisível para visualização pelos colaboradores.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetDatas()
  }

  const HandleDeleteEvent = async (idEvent, eventName) => {
    await TryCatch(
      () => {
        deleteEvent(idEvent, eventName)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleLockUnlockEvent = async (status, idEvent, eventName) => {
    await TryCatch(
      () => {
        lockUnlockEvent(status, idEvent, eventName)
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const dataFiltered =
    search !== ''
      ? Object.values(events).filter((pay) => {
          const searchString =
            `${pay.data}|${pay.description}|${pay.value}|${pay.status ? 'pago' : 'pendente'}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(events)

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
    setFormContext({})
  }, [])

  return {
    navigate,
    loading,
    search,
    setSearch,
    dataFiltered,
    assinaturasDatas,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteEvent,
    HandleLockUnlockEvent,
  }
}
