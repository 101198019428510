import React from 'react'

import { TransformationforMask } from '../../../utils/masks'

export const InputPrompt = ({
  id = '',
  type = 'text',
  placeholder = '',
  className = '',
  color = 'is-light',
  size = '',
  isLoading = false,
  value,
  setValue,
  field = null,
  required = null,
  disabled = null,
  style = {},
  maskSetValue = 'text',
  maskValue = 'text',
}) => {
  const inputDisabled = !!disabled

  const changes = (e) => {
    const valueTarget = TransformationforMask(e.target.value, maskSetValue)
    if (field) {
      setValue((prev) => ({ ...prev, [field]: valueTarget }))
    } else {
      setValue(valueTarget)
    }
  }

  const valueInput = TransformationforMask(value && field ? value[field] : value, maskValue)

  return (
    <>
      {' '}
      <input
        id={id}
        className={`input ${color} ${size} ${isLoading ? 'is-loading' : ''} ${className}`}
        type={type}
        placeholder={placeholder}
        value={valueInput}
        onChange={changes}
        required={required}
        disabled={inputDisabled}
        style={style}
      />
      {inputDisabled && <p className="has-text-danger is-size-7 ml-3">{disabled}</p>}
    </>
  )
}
