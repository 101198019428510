import React from 'react'

export const PaginationPrevious = ({ children, disabled = false, action = () => {} }) => {
  return (
    <button
      onClick={(e) => {
        action()
      }}
      className={`pagination-previous ${disabled && 'is-disabled'}`}
      type="button"
    >
      {children}
    </button>
  )
}
