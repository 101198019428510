import React from 'react'

export const ModalButtonHeaderClose = ({ setActive }) => {
  const fecharModal = () => {
    setActive(false)
  }

  return (
    <button
      className="delete"
      aria-label="close"
      type="button"
      onClick={() => {
        fecharModal()
      }}
    ></button>
  )
}
