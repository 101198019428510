import React from 'react'
import { Outlet } from 'react-router-dom'

import { Page } from '../../../components/layouts/page'
import { SideBar } from '../../../components/layouts/sidebar'
import { Icon } from '../../../components/icons'
import { Path } from '../../../router/paths'
import { useClientEventsSideBar } from '../../client/events/_hooks/useClientEventsSideBar'

export const ClientLayoutSideBarEvents = () => {
  const {
    id,
    location,
    formContext,
    HandleReOpenEvent,
    HandleOpenAllJobs,
    HandleCloseAllJobs,
    // HandleExportExcelPayment,
    loading,
  } = useClientEventsSideBar()

  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            <SideBar.Item title={'Voltar'} link={Path.ClientEvents}>
              <Icon size={30}>arrowLeft</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Informações'}
              link={Path.ClientEditEvents(id)}
              activate={location.pathname === Path.ClientEditEvents(id)}
            >
              <Icon size={30}>info</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Vagas'}
              link={Path.ClientjobOffersEvents(id)}
              activate={location.pathname === Path.ClientjobOffersEvents(id)}
            >
              <Icon size={30}>job</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Staff'}
              link={Path.ClientStaffEvents(id)}
              activate={location.pathname === Path.ClientStaffEvents(id)}
            >
              <Icon size={30}>users</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Históricos'}
              link={Path.ClientHistoryEvent(id)}
              activate={location.pathname === Path.ClientHistoryEvent(id)}
            >
              <Icon size={30}>history</Icon>
            </SideBar.Item>

            <SideBar.Space size={100}></SideBar.Space>

            <SideBar.Item
              title={formContext?.statusEvent ? 'Finalizar Evento' : 'Finalização do evento'}
              link={Path.ClientStaffFinally(id)}
              activate={location.pathname === Path.ClientStaffFinally(id)}
            >
              <Icon size={30} className={formContext?.statusEvent ? 'has-text-danger' : 'has-text-dark'}>
                checkin
              </Icon>
            </SideBar.Item>

            {!formContext.statusEvent && (
              <>
                {/* <SideBar.Button
                  title="Planilha de pagamento"
                  link={Path.ClientUsers}
                  action={HandleExportExcelPayment}
                  loading={loading}
                >
                  <Icon size={30} className="has-text-success">
                    bill
                  </Icon>
                </SideBar.Button> */}

                <SideBar.Button
                  title="Reabrir Evento"
                  link={Path.ClientUsers}
                  action={HandleReOpenEvent}
                  loading={loading}
                >
                  <Icon size={30} className="has-text-success">
                    checkout
                  </Icon>
                </SideBar.Button>
              </>
            )}

            {formContext.statusEvent && (
              <SideBar.Button
                title={formContext.openAllJobs ? 'Fechar Todas as Candidaturas' : 'Abrir Todas as Candidaturas'}
                link={Path.ClientUsers}
                action={formContext.openAllJobs ? HandleCloseAllJobs : HandleOpenAllJobs}
                loading={loading}
              >
                <Icon size={30} className={formContext.openAllJobs ? 'has-text-danger' : 'has-text-success'}>
                  {formContext.openAllJobs ? 'lock' : 'unlock'}
                </Icon>
              </SideBar.Button>
            )}

            <SideBar.Button title={'Excluir Evento'} link={Path.ClientUsers}>
              <Icon size={30} className={'has-text-danger'}>
                trash
              </Icon>
            </SideBar.Button>
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
