import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Checkbox } from '../../../../components/ui/checkbox'
import { Icon } from '../../../../components/icons'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { UpdateValueInArrayOfObject } from '../../../../utils/handlingArrays'
import { Select } from '../../../../components/ui/select'

export const ClientsFormEventsFinally = ({ loading, formContext, setFormContext, setSelectCandidacy, actions }) => {
  return formContext?.statusEvent ? (
    <>
      <div className="box mt-5">
        <Column.Root className="mx-5 my-2">
          <Checkbox.Root text="Registrar Ajustes/Descontos no pagamentos dos colaboradores">
            <Checkbox.Content
              key={`checkhasFavorites`}
              value={formContext}
              setValue={setFormContext}
              field={'hasDebts'}
            />
          </Checkbox.Root>
        </Column.Root>
        {formContext?.hasDebts ? (
          <>
            <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
            <Column.Root>
              <Column.Content>
                <Column.Root>
                  <Column.Content>
                    <p className="subtitle is-size-5 has-text-grey">
                      <Icon size={20} className={'mr-3'}>
                        bill
                      </Icon>
                      Lista de Ajuste/Descontos
                    </p>
                  </Column.Content>
                  <Column.Content>
                    <Button.Root align="right">
                      <Button.Content>
                        <Button.Bt
                          color="is-success"
                          colorText="has-text-white"
                          loading={loading}
                          action={() => {
                            setSelectCandidacy(true)
                            setFormContext((prev) => ({ ...prev, typeSelectCandidacy: 'debts' }))
                          }}
                        >
                          +
                        </Button.Bt>
                      </Button.Content>
                    </Button.Root>
                  </Column.Content>
                </Column.Root>

                {formContext?.list_debts && (
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                    <Table.Head>
                      <Table.Row key={'tableEventsHead'}>
                        <Table.Cell>Data</Table.Cell>
                        <Table.Cell>Vaga</Table.Cell>
                        <Table.Cell>Colaborador</Table.Cell>
                        <Table.Cell>CPF</Table.Cell>
                        <Table.Cell>Ajuste/Desconto</Table.Cell>
                        <Table.Cell>Valor</Table.Cell>
                        <Table.Cell>Motivo</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(formContext?.list_debts || []).map((candidacy) => {
                        const indexDebt = candidacy.index
                        return (
                          <Table.Row key={`row_${indexDebt}`}>
                            <Table.Cell>{candidacy.date}</Table.Cell>
                            <Table.Cell>{candidacy.positionName}</Table.Cell>
                            <Table.Cell>{candidacy.name}</Table.Cell>
                            <Table.Cell>{candidacy.cpf}</Table.Cell>
                            <Table.Cell size={10}>
                              <Select.Root>
                                <Select.Content
                                  required={true}
                                  color="is-grey"
                                  value={candidacy?.typeDebt}
                                  setValue={(value) => {
                                    setFormContext((prev) => {
                                      const newList = UpdateValueInArrayOfObject(
                                        prev.list_debts,
                                        'index',
                                        indexDebt,
                                        'typeDebt',
                                        value,
                                      )
                                      return {
                                        ...prev,
                                        list_debts: [...newList],
                                      }
                                    })
                                  }}
                                >
                                  <Select.Option value="D" title="Desconto" />
                                  <Select.Option value="C" title="Ajuste" />
                                </Select.Content>
                              </Select.Root>
                            </Table.Cell>
                            <Table.Cell size={150}>
                              <Input.Root>
                                <Input.Contents alignIcon={'left'}>
                                  <Input.Icon align="is-left">R$</Input.Icon>
                                  <Input.Prompt
                                    required={true}
                                    type="number"
                                    color="is-grey"
                                    value={candidacy?.valueDebt}
                                    setValue={(value) => {
                                      setFormContext((prev) => {
                                        const newList = UpdateValueInArrayOfObject(
                                          prev.list_debts,
                                          'index',
                                          indexDebt,
                                          'valueDebt',
                                          value,
                                        )
                                        return {
                                          ...prev,
                                          list_debts: [...newList],
                                        }
                                      })
                                    }}
                                  ></Input.Prompt>
                                </Input.Contents>
                              </Input.Root>
                            </Table.Cell>
                            <Table.Cell>
                              <Input.Root>
                                <Input.Prompt
                                  required={true}
                                  color="is-grey"
                                  value={candidacy?.motivoDebt}
                                  setValue={(value) => {
                                    setFormContext((prev) => {
                                      const newList = UpdateValueInArrayOfObject(
                                        prev.list_debts,
                                        'index',
                                        indexDebt,
                                        'motivoDebt',
                                        value,
                                      )
                                      return {
                                        ...prev,
                                        list_debts: [...newList],
                                      }
                                    })
                                  }}
                                ></Input.Prompt>
                              </Input.Root>
                            </Table.Cell>
                            <Table.Cell>
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.Bt
                                    color="is-light"
                                    loading={loading}
                                    action={() => {
                                      actions.duplicated(candidacy, 'list_debts')
                                    }}
                                    title="Duplicar"
                                  >
                                    <Icon size={15}>copy</Icon>
                                  </Button.Bt>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    color="is-danger"
                                    colorText="has-text-white"
                                    loading={loading}
                                    action={() => {
                                      actions.delete(indexDebt, 'list_debts')
                                    }}
                                    title="Excluir"
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                )}
              </Column.Content>
            </Column.Root>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="box mt-5">
        <Column.Root className="mx-5 my-2">
          <Checkbox.Root text="Quero Favoritar Colaboradores">
            <Checkbox.Content
              key={`checkhasFavorites`}
              value={formContext}
              setValue={setFormContext}
              field={'hasFavorites'}
            />
          </Checkbox.Root>
        </Column.Root>

        {formContext?.hasFavorites ? (
          <>
            <Column.Root className="mx-5 my-2">
              <Checkbox.Root text="Adicionar Toda a Staff aos Favoritos e Criar Equipe Nova">
                <Checkbox.Content
                  key={`checkfavoriteAll`}
                  value={formContext}
                  setValue={setFormContext}
                  field={'favoriteAll'}
                />
              </Checkbox.Root>
            </Column.Root>

            {!formContext?.favoriteAll && (
              <>
                <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
                <Column.Root>
                  <Column.Content>
                    <Column.Root>
                      <Column.Content>
                        <p className="subtitle is-size-5 has-text-grey">
                          <Icon size={20} className={'mr-3'}>
                            favorite
                          </Icon>
                          Lista de Favoritos
                        </p>
                      </Column.Content>
                      <Column.Content>
                        <Button.Root align="right">
                          <Button.Content>
                            <Button.Bt
                              color="is-success"
                              colorText="has-text-white"
                              loading={loading}
                              action={() => {
                                setSelectCandidacy(true)
                                setFormContext((prev) => ({ ...prev, typeSelectCandidacy: 'favorite' }))
                              }}
                            >
                              +
                            </Button.Bt>
                          </Button.Content>
                        </Button.Root>
                      </Column.Content>
                    </Column.Root>

                    {formContext?.list_favorite && (
                      <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                        <Table.Head>
                          <Table.Row key={'tableEventsHead'}>
                            <Table.Cell>Vaga</Table.Cell>
                            <Table.Cell>Colaborador</Table.Cell>
                            <Table.Cell>CPF</Table.Cell>
                            <Table.Cell>Ações</Table.Cell>
                          </Table.Row>
                        </Table.Head>
                        <Table.Body>
                          {(formContext?.list_favorite || []).map((candidacy) => {
                            const index = candidacy.index
                            return (
                              <Table.Row key={`row_favorite_${candidacy.id}${index}`}>
                                <Table.Cell>{candidacy.positionName}</Table.Cell>
                                <Table.Cell>{candidacy.name}</Table.Cell>
                                <Table.Cell>{candidacy.cpf}</Table.Cell>
                                <Table.Cell>
                                  <Button.Root align="centered">
                                    <Button.Content>
                                      <Button.Bt
                                        color="is-danger"
                                        colorText="has-text-white"
                                        loading={loading}
                                        action={() => {
                                          actions.delete(index, 'list_favorite')
                                        }}
                                        title="Excluir"
                                      >
                                        <Icon size={15}>trash</Icon>
                                      </Button.Bt>
                                    </Button.Content>
                                  </Button.Root>
                                </Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Table.Body>
                      </Table.Root>
                    )}
                  </Column.Content>
                </Column.Root>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="box mt-5">
        <Column.Root className="mx-5 my-2">
          <Checkbox.Root text="Quero Bloquear Candidaturas de Colaboradores">
            <Checkbox.Content
              key={`checkhasFavorites`}
              value={formContext}
              setValue={setFormContext}
              field={'hasBlocked'}
            />
          </Checkbox.Root>
        </Column.Root>

        {formContext?.hasBlocked && (
          <>
            <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
            <Column.Root>
              <Column.Content>
                <Column.Root>
                  <Column.Content>
                    <p className="subtitle is-size-5 has-text-grey">
                      <Icon size={20} className={'mr-3'}>
                        block
                      </Icon>
                      Lista de Bloqueados
                    </p>
                  </Column.Content>
                  <Column.Content>
                    <Input.Root>
                      <Input.Label required={true} className="has-text-grey">
                        Motivo padrão
                      </Input.Label>
                      <Input.Prompt
                        required={true}
                        color="is-grey"
                        value={formContext}
                        setValue={setFormContext}
                        field={'motivoDefaultBlocked'}
                        placeholder="ex.: Falta no evento..."
                      ></Input.Prompt>
                      <Input.Error>Será aplicatos a todos com motivos de bloqueios vazios...</Input.Error>
                    </Input.Root>
                  </Column.Content>
                  <Column.Content size="is-1">
                    <Button.Root align="right">
                      <Button.Content>
                        <Button.Bt
                          color="is-success"
                          colorText="has-text-white"
                          loading={loading}
                          action={() => {
                            setSelectCandidacy(true)
                            setFormContext((prev) => ({ ...prev, typeSelectCandidacy: 'blocked' }))
                          }}
                        >
                          +
                        </Button.Bt>
                      </Button.Content>
                    </Button.Root>
                  </Column.Content>
                </Column.Root>

                {formContext?.list_blocked && (
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                    <Table.Head>
                      <Table.Row key={'tableEventsHead'}>
                        <Table.Cell>Vaga</Table.Cell>
                        <Table.Cell>Colaborador</Table.Cell>
                        <Table.Cell>CPF</Table.Cell>
                        <Table.Cell>Motivo</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {(formContext?.list_blocked || []).map((candidacy) => {
                        const index = candidacy.index
                        return (
                          <Table.Row key={`row_blocked_${candidacy.id}${index}`}>
                            <Table.Cell>{candidacy.positionName}</Table.Cell>
                            <Table.Cell>{candidacy.name}</Table.Cell>
                            <Table.Cell>{candidacy.cpf}</Table.Cell>

                            <Table.Cell>
                              <Input.Root>
                                <Input.Prompt
                                  color="is-grey"
                                  value={candidacy?.motivoBlocked}
                                  setValue={(value) => {
                                    setFormContext((prev) => {
                                      const newList = UpdateValueInArrayOfObject(
                                        prev.list_blocked,
                                        'index',
                                        index,
                                        'motivoBlocked',
                                        value,
                                      )
                                      return {
                                        ...prev,
                                        list_blocked: [...newList],
                                      }
                                    })
                                  }}
                                ></Input.Prompt>
                              </Input.Root>
                            </Table.Cell>
                            <Table.Cell>
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.Bt
                                    color="is-danger"
                                    colorText="has-text-white"
                                    loading={loading}
                                    action={() => {
                                      actions.delete(index, 'list_blocked')
                                    }}
                                    title="Excluir"
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table.Root>
                )}
              </Column.Content>
            </Column.Root>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      {/* lista de ajustes */}
      <>
        <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
        <Column.Root>
          <Column.Content>
            <Column.Root>
              <Column.Content>
                <p className="subtitle is-size-5 has-text-grey">
                  <Icon size={20} className={'mr-3'}>
                    bill
                  </Icon>
                  Lista de Ajuste/Descontos
                </p>
              </Column.Content>
            </Column.Root>

            {formContext?.list_debts && (
              <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                <Table.Head>
                  <Table.Row key={'tableEventsHead'}>
                    <Table.Cell>Data</Table.Cell>
                    <Table.Cell>Vaga</Table.Cell>
                    <Table.Cell>Colaborador</Table.Cell>
                    <Table.Cell>CPF</Table.Cell>
                    <Table.Cell>Ajuste/Desconto</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Motivo</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(formContext?.list_debts || []).map((candidacy) => {
                    const indexDebt = candidacy.index
                    return (
                      <Table.Row key={`row_${indexDebt}`}>
                        <Table.Cell>{candidacy.date}</Table.Cell>
                        <Table.Cell>{candidacy.positionName}</Table.Cell>
                        <Table.Cell>{candidacy.name}</Table.Cell>
                        <Table.Cell>{candidacy.cpf}</Table.Cell>
                        <Table.Cell size={10}>
                          <Select.Root>
                            <Select.Content
                              disabled={true}
                              required={true}
                              color="is-grey"
                              value={candidacy?.typeDebt}
                              setValue={(value) => {
                                setFormContext((prev) => {
                                  const newList = UpdateValueInArrayOfObject(
                                    prev.list_debts,
                                    'index',
                                    indexDebt,
                                    'typeDebt',
                                    value,
                                  )
                                  return {
                                    ...prev,
                                    list_debts: [...newList],
                                  }
                                })
                              }}
                            >
                              <Select.Option value="D" title="Desconto" />
                              <Select.Option value="C" title="Crédito" />
                            </Select.Content>
                          </Select.Root>
                        </Table.Cell>
                        <Table.Cell size={150}>
                          <Input.Root>
                            <Input.Contents alignIcon={'left'}>
                              <Input.Icon align="is-left">R$</Input.Icon>
                              <Input.ReadOnly>{candidacy?.valueDebt}</Input.ReadOnly>
                            </Input.Contents>
                          </Input.Root>
                        </Table.Cell>
                        <Table.Cell>
                          <Input.Root>
                            <Input.ReadOnly>{candidacy?.motivoDebt}</Input.ReadOnly>
                          </Input.Root>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            )}
          </Column.Content>
        </Column.Root>
      </>
      {/* lista de Favoritos */}
      <>
        <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
        <Column.Root>
          <Column.Content>
            <Column.Root>
              <Column.Content>
                <p className="subtitle is-size-5 has-text-grey">
                  <Icon size={20} className={'mr-3'}>
                    favorite
                  </Icon>
                  Lista de Favoritos
                </p>
              </Column.Content>
            </Column.Root>

            {formContext?.list_favorite && (
              <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                <Table.Head>
                  <Table.Row key={'tableEventsHead'}>
                    <Table.Cell>Vaga</Table.Cell>
                    <Table.Cell>Colaborador</Table.Cell>
                    <Table.Cell>CPF</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(formContext?.list_favorite || []).map((candidacy) => {
                    const index = candidacy.index
                    return (
                      <Table.Row key={`row_favorite_${candidacy.id}${index}`}>
                        <Table.Cell>{candidacy.positionName}</Table.Cell>
                        <Table.Cell>{candidacy.name}</Table.Cell>
                        <Table.Cell>{candidacy.cpf}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            )}
          </Column.Content>
        </Column.Root>
      </>
      <>
        <Column.Root className="mx-5 my-2">
          <Checkbox.Root text="Adicionar Toda a Staff aos Favoritos e Criar Equipe Nova">
            <Checkbox.Content
              key={`checkfavoriteAll`}
              value={formContext}
              setValue={setFormContext}
              field={'favoriteAll'}
              disabled={true}
            />
          </Checkbox.Root>
        </Column.Root>
      </>
      {/* lista de Bloqueados */}
      <>
        <div style={{ height: 1 }} className="is-fullwidth has-background-grey-lighter my-5"></div>
        <Column.Root>
          <Column.Content>
            <Column.Root>
              <Column.Content>
                <p className="subtitle is-size-5 has-text-grey">
                  <Icon size={20} className={'mr-3'}>
                    block
                  </Icon>
                  Lista de Bloqueados
                </p>
              </Column.Content>
            </Column.Root>

            {formContext?.list_blocked && (
              <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                <Table.Head>
                  <Table.Row key={'tableEventsHead'}>
                    <Table.Cell>Vaga</Table.Cell>
                    <Table.Cell>Colaborador</Table.Cell>
                    <Table.Cell>CPF</Table.Cell>
                    <Table.Cell>Motivo</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(formContext?.list_blocked || []).map((candidacy) => {
                    const index = candidacy.index
                    return (
                      <Table.Row key={`row_blocked_${candidacy.id}${index}`}>
                        <Table.Cell>{candidacy.positionName}</Table.Cell>
                        <Table.Cell>{candidacy.name}</Table.Cell>
                        <Table.Cell>{candidacy.cpf}</Table.Cell>
                        <Table.Cell>
                          <Input.Root>
                            <Input.ReadOnly>{candidacy?.motivoBlocked}</Input.ReadOnly>
                          </Input.Root>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            )}
          </Column.Content>
        </Column.Root>
      </>
    </>
  )
}
