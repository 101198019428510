import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { useClientEventsHistory } from './_hooks/useClientEventsHistory'
// import { Button } from '../../../components/ui/button'
// import { Path } from '../../../router/paths'

export const ClientEventsHistory = () => {
  const { loading, search, setSearch, FetchGetdatas, dataFiltered } = useClientEventsHistory()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <Button.Root align="right">
        <Button.Content>
          <Button.Bt
            className=""
            color="is-dark"
            colorText="has-text-white"
            type={'button'}
            loading={loading}
            action={() => {
              FetchGetdatas()
            }}
          >
            <Icon size={15}>sync</Icon>
          </Button.Bt>
        </Button.Content>
      </Button.Root>

      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-4">
            <Icon size={30} className={'mr-3'}>
              history
            </Icon>
            Histórico
          </p>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Root>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>search</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row key={'tableEventsHead'}>
                <Table.Cell>Data</Table.Cell>
                <Table.Cell>Descrição</Table.Cell>
                <Table.Cell>Informações</Table.Cell>
                <Table.Cell>Usuário</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {(dataFiltered || [])
                .sort((a, b) => a.time >= b.time)
                .map((value) => {
                  console.log(value)
                  return (
                    <>
                      <Table.Row key={value.id}>
                        <Table.Cell className="has-text-grey is-vcentered mx-2">{value.dtModified}</Table.Cell>
                        <Table.Cell className="has-text-grey is-vcentered">
                          {typeof value.typeModified === 'object' ? (
                            value.typeModified.map((item) => {
                              return (
                                <p key={`tyupemodified${item}`} className="is-size-7">
                                  - {item}
                                </p>
                              )
                            })
                          ) : (
                            <p className="is-size-7">{value.typeModified}</p>
                          )}
                        </Table.Cell>
                        <Table.Cell className="has-text-grey is-vcentered">
                          {Object.keys(value.info).map((key) => {
                            return (
                              <>
                                <p className="subtitle is-size-7">{key}</p>
                                {typeof value.info[key] === 'object' ? (
                                  value.info[key].map((item) => {
                                    return (
                                      <p key={`info${item}`} className="is-size-7">
                                        - {item}
                                      </p>
                                    )
                                  })
                                ) : (
                                  <p className="is-size-7">{value.info[key]}</p>
                                )}
                              </>
                            )
                          })}
                        </Table.Cell>
                        <Table.Cell className="has-text-grey is-vcentered">{value.modifiedBy}</Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
