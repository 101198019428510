import { TableBody } from './TableBody'
import { TableCell } from './TableCell'
import { TableHead } from './TableHead'
import { TableRoot } from './TableRoot'
import { TableRow } from './TableRow'
import { TableRowClickable } from './TableRowClickable'

/**
 * # Root
 * Cria um componente de linha clicável.
 *
 * @param {string} className - Aceita qualquer classe do Bulma.
 * @param {React.CSSProperties}style - Aceita estilos inline do React.
 * @returns {JSX.Element} O componente React.
 *
 * # Head
 * Cria um componente do head da TABLE.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @param {string} className - Aceita qualquer classe do Bulma.
 * @returns {JSX.Element} O componente React.
 *
 * # Body
 * Cria Cria um componente do body da TABLE.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @returns {JSX.Element} O componente React.
 *
 * # Row
 * Cria um componente a linha da tabela.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react
 * @param {string} className - Aceita classes do bulma ("is-bordered", "is-striped", "is-narrow", "is-hoverable", "is-fullwidth")
 * @param {React.CSSProperties} style - Aceita classes de style do react
 * @returns {JSX.Element} O componente React.
 *
 * # RowClickable
 * Cria um componente de linha clicável.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente React.
 * @param {string} className - Aceita qualquer classe do Bulma.
 * @param {React.CSSProperties}style - Aceita estilos inline do React.
 * @param {function} action - Função executada ao clicar.
 * @param {string} link - Path do React Router ("/CaminhoDaPagina").
 * @returns {JSX.Element} O componente React.
 *
 * # Cell
 * Cria um componente para acomodar os componentes do o Select.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {number} size - Define o WIDTH da celula.
 * @param {number} borderWidth - Define o BorderWIDTH da celula.
 * @returns {JSX.Element} O componente React.
 *
 */

export const Table = {
  Root: TableRoot,
  Head: TableHead,
  Body: TableBody,
  Row: TableRow,
  RowClickable: TableRowClickable,
  Cell: TableCell,
}

// exemplo de utilização:

// <Table.Root>
//     <Table.Head>
//         <Table.Row>
//             <Table.Cell>Header 1</Table.Cell>
//             <Table.Cell>Header 2</Table.Cell>
//         </Table.Row>
//     </Table.Head>

//     <Table.Body>
//         <Table.Row>
//             <Table.Cell>
//                 Conteúdo da Célula 1
//             </Table.Cell>
//             <Table.Cell>
//                 Conteúdo da Célula 2
//             </Table.Cell>
//         </Table.Row>

//         <Table.RowClickable action={() => { console.log('Linha clicável!'); }} link="/...">
//             <Table.Cell>
//                 Conteúdo da Célula 3
//             </Table.Cell>
//             <Table.Cell>
//                 Conteúdo da Célula 4
//             </Table.Cell>
//         </Table.RowClickable>
//     </Table.Body>
// </Table.Root>
