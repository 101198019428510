import * as XLSX from 'xlsx'

export const ReadExcelFile = async (file) => {
  try {
    // Lê o arquivo como um ArrayBuffer para processamento
    const data = await file.arrayBuffer()

    // Carrega o conteúdo do arquivo Excel
    const workbook = XLSX.read(data, { type: 'array' })

    // Objeto para armazenar os dados de cada planilha
    const sheetsData = {}

    // Loop por cada planilha no arquivo
    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName]

      // Converte a planilha em JSON com o cabeçalho como chaves do objeto
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

      // Armazena os dados da planilha no objeto `sheetsData` com o nome da planilha
      sheetsData[sheetName] = jsonData
    })

    return sheetsData // Retorna o objeto contendo os dados de todas as planilhas
  } catch (error) {
    console.error('Erro ao ler o arquivo Excel:', error)
    throw error
  }
}
