import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import {
  deleteSubDocument,
  getDocument,
  getSubDocuments,
  getSubDocumentsWithQuery,
} from '../../../../../firebase/firestore'

export const useClientSectors = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    // setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const sectorsDatas = dataContext?.sectors || {}
  const [search, setSearch] = useState('')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async () => {
    const getSectors = await getSubDocuments('clientes', clientId, 'sectors')

    // verifica se o setor está setado em algum usuario, caso sim ele bloqueia o botão de apagar
    const sectorsInUse = {}

    const promises = getSectors.map(async (sector) => {
      const getuser = await getSubDocumentsWithQuery(`clientes`, clientId, 'users', 'sectorId', '==', sector.id)

      if (getuser.length > 0) {
        sectorsInUse[sector.id] = true
      } else {
        sectorsInUse[sector.id] = false
      }
    })

    // Aguarda a resolução de todas as promessas
    await Promise.all(promises)

    // Cria uma nova lista de Setores com o campo 'blocked'
    const sectors = getSectors.map((setor) => {
      return { ...setor, blocked: sectorsInUse[setor?.id] || false }
    })

    // salva todas as informações coletadas no context.
    setDataContext({ sectors })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getGlobalPermissions = await getDocument('configs', 'clients_permissions')

    const globalPermissionsList = Object.keys(getGlobalPermissions).reduce((objetoFinal, title) => {
      Object.keys(getGlobalPermissions[title]).forEach((item) => {
        if (title !== 'id') {
          objetoFinal[`${title}_${item}`] = false
        }
      })
      return objetoFinal
    }, {})

    setInfosContext({ globalPermissions: getGlobalPermissions, globalPermissionsList })
  }

  const deleteSector = async (id) => {
    console.log(id)
    if (id === 'admin') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Setor Bloqueado',
          msg: 'Você não pode deletar o setor "admin", pois esta atrelado ao usuário responsavel da empresa',
        },
      ])
    }
    if (!user?.user?.permissions?.sectors_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar setores',
        },
      ])
    }
    // se o setor não estiver em uso ele deixa deletar, porém se estiver ele mostra uma mensagem no alerts.
    if (sectorsDatas.find((sector) => sector.id === id)?.blocked) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Setor Bloqueado',
          msg: 'O setor esta em uso por algum usuário, por esse motivo não é possivel deletar',
        },
      ])
    }

    await deleteSubDocument('clientes', clientId, 'sectors', id)
    await FetchGetDatas()
    await FetchGetInfos()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Setor Excluído',
        msg: 'Setor excluído da sua base de dados',
      },
    ])
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }
  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }
  const HandleDeleteSector = async (id) => {
    await TryCatch(
      async () => {
        deleteSector(id)
      },
      setLoading,
      setAlerts,
    )
  }

  const dataFiltered =
    search !== ''
      ? Object.values(sectorsDatas).filter((sector) => {
          const searchString = `${sector.nome}|${sector.descricao}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(sectorsDatas)

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [])

  return {
    loading,
    search,
    setSearch,
    dataFiltered,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteSector,
  }
}
