import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'

export const useClientFormEvents = (values, setValues, options, clientId = null) => {
  const { setLoading, setAlerts } = useGlobalContext()
  const vestmentList = [
    'camiseta',
    'calça',
    'bermuda',
    'vestido',
    'saia',
    'terno',
    'blazer',
    'social',
    'sapato',
    'tenis',
    'boné',
    'jaqueta',
    'colete',
  ]

  const addVestments = async (vestiment) => {
    console.log(vestiment)

    // Verifica se a vestimenta é inválida
    if (!vestiment) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vestimenta Inválida',
          msg: 'Por favor, selecione uma vestimenta válida para adicioná-la ao evento.',
        },
      ])
    }

    // Atualiza o estado com a nova vestimenta
    await setValues((prev) => {
      // Remove a chave 'select_Vestment' do estado anterior
      const { selectVestment, ...rest } = prev

      return {
        ...rest,
        submitVestments: [...(prev?.submitVestments || []), vestiment],
      }
    })
  }

  const delVestments = async (vestiment) => {
    let submitVestmentsCurrent = values?.submitVestments || []

    if (submitVestmentsCurrent) {
      submitVestmentsCurrent = submitVestmentsCurrent.filter((vests) => {
        return vests !== vestiment
      })
      await setValues((prev) => ({
        ...prev,
        submitVestments: submitVestmentsCurrent,
      }))
    }
  }

  const HandleAddVestments = async (vestments) => {
    await TryCatch(
      () => {
        addVestments(vestments)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeleteVestments = async (vestments) => {
    await TryCatch(
      () => {
        delVestments(vestments)
      },
      setLoading,
      setAlerts,
    )
  }

  useEffect(() => {
    console.log(values)
  }, [values])

  return { vestmentList, HandleAddVestments, HandleDeleteVestments }
}
