import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Button } from '../../../../components/ui/button'
import { useClientFormEvents } from '../_hooks/useClientFormEvents'
import { TransformationforMask } from '../../../../utils/masks'

export const ClientsFormEventsInfosLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  const { vestmentList, HandleAddVestments, HandleDeleteVestments } = useClientFormEvents(
    formContext,
    setFormContext,
    options,
    clientId,
  )

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome do Evento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'name'}
                required={true}
                disabled={disabledList?.name}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-3">
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Turno
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'round'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'1º turno'} title={'1º Turno'} />
                <Select.Option value={'2º turno'} title={'2º Turno'} />
                <Select.Option value={'3º turno'} title={'3º Turno'} />
                <Select.Option value={'4º turno'} title={'4º Turno'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Tipo do Registro
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'register'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={true} title={'Registro Intermitente'} />
                <Select.Option value={false} title={'Não Registrado'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Pagamento
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'payment'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'pix'} title={'Pix'} />
                <Select.Option value={'dinheiro'} title={'Dinheiro'} />
                <Select.Option value={'transferencia'} title={'Transferência Bancaria'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Data Pagamento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'dtPayment'}
                required={true}
                disabled={disabledList?.dtPayment}
                type="date"
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Endereço
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'endereco'}
                required={true}
                disabled={disabledList?.endereco}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-3">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Cidade/Municipio
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'city'}
                required={true}
                disabled={disabledList?.city}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-2">
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              UF
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'uf'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'AC'} title={'AC'} />
                <Select.Option value={'AL'} title={'AL'} />
                <Select.Option value={'AP'} title={'AP'} />
                <Select.Option value={'AM'} title={'AM'} />
                <Select.Option value={'BA'} title={'BA'} />
                <Select.Option value={'CE'} title={'CE'} />
                <Select.Option value={'DF'} title={'DF'} />
                <Select.Option value={'ES'} title={'ES'} />
                <Select.Option value={'GO'} title={'GO'} />
                <Select.Option value={'MA'} title={'MA'} />
                <Select.Option value={'MT'} title={'MT'} />
                <Select.Option value={'MS'} title={'MS'} />
                <Select.Option value={'MG'} title={'MG'} />
                <Select.Option value={'PA'} title={'PA'} />
                <Select.Option value={'PB'} title={'PB'} />
                <Select.Option value={'PR'} title={'PR'} />
                <Select.Option value={'PE'} title={'PE'} />
                <Select.Option value={'PI'} title={'PI'} />
                <Select.Option value={'RJ'} title={'RJ'} />
                <Select.Option value={'RN'} title={'RN'} />
                <Select.Option value={'RS'} title={'RS'} />
                <Select.Option value={'RO'} title={'RO'} />
                <Select.Option value={'RR'} title={'RR'} />
                <Select.Option value={'SC'} title={'SC'} />
                <Select.Option value={'SP'} title={'SP'} />
                <Select.Option value={'SE'} title={'SE'} />
                <Select.Option value={'TO'} title={'TO'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Local de Encontro
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'local_encontro'}
                required={true}
                disabled={disabledList?.cpf}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Alimentação
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'food'}
                disabled={disabledList?.food}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
                <Select.Option value={2} title={'Incluso no cachê'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.food === '2' && (
          <Column.Content>
            <Input.Root>
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Valor da alimentação
              </Input.Label>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt
                  className="is-rounded"
                  value={formContext}
                  setValue={setFormContext}
                  field={'foodValue'}
                  required={true}
                  disabled={disabledList?.foodValue}
                  type="number"
                ></Input.Prompt>
                <Input.Icon align="is-left">R$</Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        )}
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vestimenta
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'vestments'}
                disabled={disabledList?.vestments}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.vestments === '1' && (
          <Column.Content>
            <Column.Root>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Qual parte será fornecida?
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'selectVestment'}
                      disabled={disabledList?.vestments}
                    >
                      <Select.Option value={''} title={'Selecione uma vestimenta...'} />
                      {vestmentList
                        .filter((vest) => {
                          return !formContext?.submitVestments?.includes(vest)
                        })
                        .sort((a, b) => {
                          return a.localeCompare(b)
                        })
                        .map((vest) => {
                          return <Select.Option key={vest} value={vest} title={TransformationforMask(vest, 'title')} />
                        })}
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content size="is-2">
                <Button.Bt
                  className="mt-5 is-rounded"
                  color="is-dark"
                  loading={loading}
                  action={() => {
                    HandleAddVestments(formContext?.selectVestment)
                  }}
                >
                  Adicionar
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        )}
      </Column.Root>

      {formContext?.vestments === '1' && (
        <div className="mx-2">
          <Column.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vestimentas fornecidas
            </Input.Label>
          </Column.Root>
          <Column.Root className={'mx-5 mt-2'}>
            <div className="tags is-light is-medium is-rounded">
              {formContext?.submitVestments?.length > 0 &&
                formContext.submitVestments.map((vestment) => (
                  <span className="tag is-success is-rounded" key={vestment}>
                    {TransformationforMask(vestment, 'title')}
                    <button
                      className="delete is-small"
                      onClick={() => {
                        HandleDeleteVestments(vestment)
                      }}
                    ></button>
                  </span>
                ))}
            </div>
          </Column.Root>
        </div>
      )}

      <Column.Root className="mt-5">
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
              Informações Adicionais
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'infoAdditional'}
                required={false}
                disabled={disabledList?.infoAdditional}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
