import { useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TransformationforMask } from '../../../../utils/masks'

export const useCollaboratorsForm = () => {
  const { setAlerts, infosContext, formContext, setFormContext } = useGlobalContext()

  const [search, setSearch] = useState('')
  const emergencyContactsGroup = formContext?.emergencyContacts ?? [{ nome: '', parentesco: '', celular: '' }]
  const emergencyContactsGroupCount = emergencyContactsGroup.length

  // cargo
  const [position, setPosition] = useState('')
  const isPositionEmpty = position === ''
  const positionList = [...(infosContext?.cargos || [])].reduce((acc, item) => {
    acc[item.id] = { ...item }
    return acc
  }, {})
  const [mainPosition, setMainPosition] = useState(null)
  const positionsAdded = formContext?.cargos ?? {}

  const consents = formContext?.consentimentos ?? {}
  const documents = formContext?.documentos ?? {}

  const submitFormDisabled = Object.keys(positionsAdded).length > 0

  const handleEmergencyFieldsChange = (index, event) => {
    const { name, value } = event.target
    const newEmergencyContactsGroup = [...emergencyContactsGroup]

    newEmergencyContactsGroup[index][name] = TransformationforMask(value)

    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: newEmergencyContactsGroup,
      }
    })
  }

  // funções para os campos para lidar com os campos de emergência
  const handleAddEmergencyContact = () => {
    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: [...prevState.emergencyContacts, { nome: '', parentesco: '', celular: '' }],
      }
    })
  }

  const handleDeleteEmergencyContact = (index) => {
    if (emergencyContactsGroupCount > 1) {
      const updatedContacts = emergencyContactsGroup.filter((_, i) => i !== index)

      setFormContext((prevState) => {
        return {
          ...prevState,
          emergencyContacts: updatedContacts,
        }
      })
    }
  }

  const getPositionStatusType = (position) => {
    let positionType

    switch (position) {
      case 1:
        positionType = 'Verificado'
        break
      case 9:
        positionType = 'Bloqueado'
        break
      default:
        positionType = 'Em Análise'
        break
    }

    return positionType
  }

  const getConsentStatusType = (consent) => {
    let positionType

    switch (consent) {
      case 1:
        positionType = 'Assinado'
        break
      case 9:
        positionType = 'Revogado'
        break
      default:
        positionType = 'Pendente'
        break
    }

    return positionType
  }

  const getDocumentStatusType = (document) => {
    let documentType

    switch (document) {
      case true:
        documentType = 'Enviado'
        break
      default:
        documentType = 'Pendente'
    }

    return documentType
  }

  const addPositionToList = (key) => {
    const position = formContext?.cargos
    const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
    const currentPositionConsentIds = currentPosition?.consentimentos
    const currentPositionDocumentIds = currentPosition?.documentos

    currentPositionConsentIds.map((currentPositionId) => {
      const currentPositionConsent = infosContext?.consentimentos?.find((consent) => consent.id === currentPositionId)

      const currentPositionConsentContent = {
        nome: currentPositionConsent.nome,
        dataAssinatura: '-------',
        status: 0,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          consentimentos: {
            ...prevState.consentimentos,
            [currentPositionConsent.id]: {
              ...currentPositionConsentContent,
            },
          },
        }
      })

      return {
        currentPositionConsentContent,
      }
    })

    currentPositionDocumentIds.map((currentPositionId) => {
      const currentPositionDocument = infosContext?.documentos?.find((document) => document.id === currentPositionId)

      const currentPositionDocumentContent = {
        nome: currentPositionDocument.nome,
        dataEnvio: '-------',
        status: false,
      }

      setFormContext((prevState) => {
        return {
          ...prevState,
          documentos: {
            ...prevState.documentos,
            [currentPositionDocument.id]: {
              ...currentPositionDocumentContent,
            },
          },
        }
      })

      return {
        currentPositionDocumentContent,
      }
    })

    if (position && Object.keys(position).includes(key)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Cargo já adicionado',
          msg: 'É permitido inserir apenas cargos diferentes',
          type: 'error',
        },
      ])
    }

    return setFormContext((prevState) => {
      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            id: key,
            nome: positionList[key].nome,
            principal: false,
            status: 0,
            score: 0,
          },
        },
      }
    })
  }

  const approvePosition = (key) => {
    return setFormContext((prevState) => {
      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            ...prevState.cargos[key],
            status: 1,
          },
        },
      }
    })
  }

  const blockUnblockPosition = (key) => {
    return setFormContext((prevState) => {
      const currentPositionStatus = prevState.cargos[key].status
      const newPositionStatus = currentPositionStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa

      return {
        ...prevState,
        cargos: {
          ...prevState.cargos,
          [key]: {
            ...prevState.cargos[key],
            status: newPositionStatus,
          },
        },
      }
    })
  }

  const blockUnblockCollaborator = () => {
    return setFormContext((prevState) => {
      const currentCollaboratorStatus = prevState.status
      const newCollaboratorStatus = currentCollaboratorStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa
      return {
        ...prevState,
        status: newCollaboratorStatus,
      }
    })
  }

  const deletePositionFromList = (key) => {
    return setFormContext((prevState) => {
      const newPositionList = Object.fromEntries(
        Object.entries(prevState.cargos).filter(([entryKey]) => entryKey !== key),
      )

      const currentPosition = infosContext?.cargos?.find((currentPosition) => currentPosition.id === key)
      const currentPositionConsentIds = currentPosition?.consentimentos
      const currentPositionDocumentIds = currentPosition?.documentos
      let newConsentsList
      let newDocumentsList

      currentPositionConsentIds.map((currentPositionConsentId) => {
        return (newConsentsList = Object.fromEntries(
          Object.entries(prevState.consentimentos).filter(([entryKey]) => entryKey !== currentPositionConsentId),
        ))
      })

      currentPositionDocumentIds.map((currentPositionDocumentId) => {
        return (newDocumentsList = Object.fromEntries(
          Object.entries(prevState.documentos).filter(([entryKey]) => entryKey !== currentPositionDocumentId),
        ))
      })

      return {
        ...prevState,
        cargos: newPositionList,
        documentos: newDocumentsList,
        consentimentos: newConsentsList,
      }
    })
  }

  // Função para definir uma linha como principal e atualizar o contexto
  const handleMainPosition = (id) => {
    setMainPosition(id)

    // Atualiza o contexto, configurando a opção selecionada como principal
    setFormContext((prevState) => {
      const cargosAtualizados = Object.keys(prevState.cargos).reduce((acc, key) => {
        acc[key] = {
          ...prevState.cargos[key],
          principal: key === String(id), // Marca o item como principal se o ID for o mesmo
        }
        return acc
      }, {})

      return {
        ...prevState,
        cargos: cargosAtualizados,
      }
    })
  }

  return {
    search,
    setSearch,
    position,
    isPositionEmpty,
    setPosition,
    positionList,
    getPositionStatusType,
    getConsentStatusType,
    getDocumentStatusType,
    positionsAdded,
    blockUnblockPosition,
    blockUnblockCollaborator,
    consents,
    documents,
    addPositionToList,
    deletePositionFromList,
    approvePosition,
    submitFormDisabled,
    // handlePositionField,
    handleMainPosition,
    mainPosition,
    emergencyContactsGroup,
    // setEmergencyContactsGroup,
    emergencyContactsGroupCount,
    handleAddEmergencyContact,
    handleDeleteEmergencyContact,
    handleEmergencyFieldsChange,
    // handleClearEmergencyContacts,
  }
}
