import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { DataComTraçoParaDataComBarra } from '../../../../utils/dates'
import { Select } from '../../../../components/ui/select'
import { Input } from '../../../../components/ui/input'
import { Column } from '../../../../components/layouts/columns'
import { Table } from '../../../../components/ui/table'
import { Form } from '../../../../components/ui/form'
import { Image } from '../../../../components/ui/image'
export const ModalInviteCandidates = ({
  active = true,
  setActive,
  formContext,
  setFormContext,
  infosContext,
  teamsList,
  HandleInviteTeams,
}) => {
  if (active) {
    return (
      <Form.Root
        action={() => {
          HandleInviteTeams({ teams: teamsList, dateInvite: formContext?.dateInvite })
        }}
      >
        <Modal.Root active={active}>
          <Modal.Contents witdh={720}>
            <Modal.Header title={'Convidar Equipe'}>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>

            {(infosContext?.filters?.dates || []).length > 0 ? (
              <>
                <Modal.Body>
                  <Column.Root>
                    <Column.Content>
                      <Input.Root>
                        <Input.Label
                          className="ml-3 has-text-grey-light is-size-7"
                          style={{ marginBottom: 0 }}
                          required={true}
                        >
                          Qual Data?
                        </Input.Label>
                        <Select.Root>
                          <Select.Content
                            color="is-grey"
                            className="is-fullwidth is-rounded is-small"
                            value={formContext}
                            setValue={setFormContext}
                            field={'dateInvite'}
                            required={true}
                          >
                            <Select.Option value={null} title={''} />
                            <Select.Option value={'todos'} title={'Todos'} />
                            {infosContext?.filters?.dates.map((date) => {
                              return (
                                <Select.Option
                                  key={`date-${date}`}
                                  value={date}
                                  title={DataComTraçoParaDataComBarra(date)}
                                />
                              )
                            })}
                          </Select.Content>
                        </Select.Root>
                      </Input.Root>
                    </Column.Content>
                  </Column.Root>

                  <Column.Root>
                    <Column.Content>
                      <Input.Root>
                        <Input.Label
                          className="ml-3 has-text-grey-light is-size-7"
                          style={{ marginBottom: 0 }}
                          required={true}
                        >
                          Selecione a Equipe?
                        </Input.Label>
                        <Select.Root>
                          <Select.Content
                            color="is-grey"
                            className="is-fullwidth is-rounded is-small"
                            value={formContext}
                            setValue={setFormContext}
                            field={'teamId'}
                            required={true}
                          >
                            <Select.Option value={null} title={''} />
                            {infosContext?.teams?.map((team) => {
                              return <Select.Option key={`team-${team.id}`} value={team.id} title={team.name} />
                            })}
                          </Select.Content>
                        </Select.Root>
                      </Input.Root>
                    </Column.Content>
                  </Column.Root>

                  {(teamsList || []).length > 0 && (
                    <Table.Root className="is-fullwidth">
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell className="has-text-centered"></Table.Cell>
                          <Table.Cell className="has-text-centered">Nome</Table.Cell>
                          <Table.Cell className="has-text-centered">CPF</Table.Cell>
                          <Table.Cell className="has-text-centered">Cargo</Table.Cell>
                          <Table.Cell className="has-text-centered"></Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {(teamsList || []).map((colab, index) => {
                          console.log(colab)
                          return (
                            <Table.Row key={`teams${index}`}>
                              <Table.Cell className="is-vcentered">
                                <Image.Root size={'is-48x48'}>
                                  <Image.Content url={colab?.url_foto}></Image.Content>
                                </Image.Root>
                              </Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{colab?.name}</Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">{colab?.cpf}</Table.Cell>
                              <Table.Cell className="is-size-7 is-vcentered">
                                <Select.Root>
                                  <Select.Content
                                    disabled={!colab?.exist}
                                    className="is-rounded is-small"
                                    color="is-grey"
                                    value={colab.position}
                                    setValue={(value) => (colab.position = value)}
                                    required={true}
                                  >
                                    {(colab?.positions || [])
                                      .filter((idPosition) =>
                                        (infosContext?.filters?.positions || []).includes(idPosition),
                                      )
                                      .map((idPosition) => (
                                        <Select.Option
                                          key={`cargo_${idPosition}`}
                                          value={idPosition}
                                          title={infosContext?.positionsById[idPosition]}
                                        />
                                      ))}
                                  </Select.Content>
                                </Select.Root>
                              </Table.Cell>
                              {colab?.exist ? (
                                <Table.Cell className="is-size-7 is-vcentered">
                                  {(colab?.positions || []).every(
                                    (idPosition) => !(infosContext?.filters?.positions || []).includes(idPosition),
                                  ) && (
                                    <article className="message is-danger is-small">
                                      <div className="message-body">
                                        Colaborador não possui conhecimentos para as vagas desse evento, somente pode
                                        atuar como:
                                        {(colab?.positions || []).map((p, index) => {
                                          return (
                                            <strong
                                              key={`possibilidades${index}`}
                                            >{` ${infosContext?.positionsById[p]} ${
                                              index < (colab?.positions || []).length &&
                                              (colab?.positions || []).length > 1
                                                ? ', '
                                                : ''
                                            }`}</strong>
                                          )
                                        })}
                                        <br />
                                        Dica: Crie uma vaga, ou atualize sua equipe
                                      </div>
                                    </article>
                                  )}
                                </Table.Cell>
                              ) : (
                                <Table.Cell className="is-size-7 is-vcentered has-text-danger">
                                  Colaborador não encontrado
                                </Table.Cell>
                              )}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table.Root>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button.Root>
                    <Button.Content>
                      <Button.Bt
                        color="is-success"
                        colorText={'has-text-white'}
                        type="submit"
                        disabled={teamsList.length > 0 ? (teamsList || []).some((item) => item.exist === false) : true}
                      >
                        Convidar
                      </Button.Bt>
                    </Button.Content>
                  </Button.Root>
                </Modal.Footer>
              </>
            ) : (
              <Modal.Body>
                <article className="message is-danger">
                  <div className="message-body">
                    Este eventos não possui <strong>vagas criadas</strong>, para continuar, crie algumas vagas
                  </div>
                </article>
              </Modal.Body>
            )}
          </Modal.Contents>
        </Modal.Root>
      </Form.Root>
    )
  } else {
    return <></>
  }
}
