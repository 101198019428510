/**
 * Cria um componente de icone.
 * Site dos icones: https://iconscout.com/unicons/free-line-icon-fonts
 *
 * @param {String} [children=any] - Aceita somente String
 * @param {Number} [size=30] - define o tamanho do icone
 * @param {String} [className=""] - adiciona classe extra do bulma ao icone
 * @returns {JSX.Element} O componente React.
 */

export const Icon = ({ children, size = 30, className = '', margin = 0 }) => {
  switch (children) {
    case 'add':
      return <i className={'uil uil-plus ' + className} style={{ fontSize: size, margin }}></i>

    case 'calendar':
      return <i className={'uil uil-calendar-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'users':
      return <i className={'uil uil-users-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'chat-bubble-user':
      return <i className={'uil uil-chat-bubble-user ' + className} style={{ fontSize: size, margin }}></i>

    case 'info':
      return <i className={'uil uil-file-info-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'alert':
      return <i className={'uil uil-exclamation-triangle ' + className} style={{ fontSize: size, margin }}></i>

    case 'password':
      return <i className={'uil uil-key-skeleton ' + className} style={{ fontSize: size, margin }}></i>

    case 'user':
      return <i className={'uil uil-user ' + className} style={{ fontSize: size, margin }}></i>

    case 'checkin':
      return <i className={'uil uil-arrow-from-top ' + className} style={{ fontSize: size, margin }}></i>

    case 'checkout':
      return <i className={'uil uil-export ' + className} style={{ fontSize: size, margin }}></i>

    case 'arrowLeft':
      return <i className={'uil uil-angle-left ' + className} style={{ fontSize: size, margin }}></i>

    case 'arrowRight':
      return <i className={'uil uil-angle-right ' + className} style={{ fontSize: size, margin }}></i>

    case 'arrowDoubleRight':
      return <i className={'uil uil-angle-double-right ' + className} style={{ fontSize: size, margin }}></i>

    case 'arrowDoubleLeft':
      return <i className={'uil uil-angle-double-left ' + className} style={{ fontSize: size, margin }}></i>

    case 'gear':
      return <i className={'uil uil-setting ' + className} style={{ fontSize: size, margin }}></i>

    case 'backspace':
      return <i className={'uil uil-backspace ' + className} style={{ fontSize: size, margin }}></i>

    case 'suitcase':
      return <i className={'uil uil-suitcase ' + className} style={{ fontSize: size, margin }}></i>

    case 'trash':
      return <i className={'uil uil-trash-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'pen':
      return <i className={'uil uil-pen ' + className} style={{ fontSize: size, margin }}></i>

    case 'sync':
      return <i className={'uil uil-sync ' + className} style={{ fontSize: size, margin }}></i>

    case 'search':
      return <i className={'uil uil-search ' + className} style={{ fontSize: size, margin }}></i>

    case 'lock':
      return <i className={'uil uil-lock-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'unlock':
      return <i className={'uil uil-lock-open-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'bill':
      return <i className={'uil uil-bill ' + className} style={{ fontSize: size, margin }}></i>

    case 'history':
      return <i className={'uil uil-history ' + className} style={{ fontSize: size, margin }}></i>

    case 'print':
      return <i className={'uil uil-print ' + className} style={{ fontSize: size, margin }}></i>

    case 'selfie':
      return <i className={'uil uil-selfie ' + className} style={{ fontSize: size, margin }}></i>

    case 'sectors':
      return <i className={'uil uil-sign-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'doc':
      return <i className={'uil uil-files-landscapes-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'file-check':
      return <i className={'uil uil-file-check-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'job':
      return <i className={'uil uil-constructor ' + className} style={{ fontSize: size, margin }}></i>

    case 'block':
      return <i className={'uil uil-ban ' + className} style={{ fontSize: size, margin }}></i>

    case 'question':
      return <i className={'uil uil-comment-question ' + className} style={{ fontSize: size, margin }}></i>

    case 'infos':
      return <i className={'uil uil-map-marker-info ' + className} style={{ fontSize: size, margin }}></i>

    case 'download':
      return <i className={'uil uil-download-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'copy':
      return <i className={'uil uil-copy ' + className} style={{ fontSize: size, margin }}></i>

    case 'user-square':
      return <i className={'uil uil-user-square ' + className} style={{ fontSize: size, margin }}></i>

    case 'multiply':
      return <i className={'uil uil-multiply ' + className} style={{ fontSize: size, margin }}></i>

    case 'check':
      return <i className={'uil uil-check ' + className} style={{ fontSize: size, margin }}></i>

    case 'dollar':
      return <i className={'uil uil-dollar-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'fileExport':
      return <i className={'uil uil-file-export ' + className} style={{ fontSize: size, margin }}></i>

    case 'invite':
      return <i className={'uil uil-envelope-send ' + className} style={{ fontSize: size, margin }}></i>

    case 'favorite':
      return <i className={'uil uil-heart-alt ' + className} style={{ fontSize: size, margin }}></i>

    case 'x':
      return (
        <p className={className} style={{ fontSize: size, margin }}>
          x
        </p>
      )

    case 'loading':
      return (
        <nav className="level">
          <p className="level-item has-text-centered">
            <p className="custom-loader"></p>
          </p>
        </nav>
      )

    default:
      return <></>
  }
}

// site dos icones: https://iconscout.com/unicons/free-line-icon-fonts
