import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { deleteSubDocument, getSubDocuments } from '../../../../firebase/firestore'

export const useClientUsers = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    setLoading,
    loading,
  } = useGlobalContext()

  const sectors = infosContext?.sectors || {}
  const [search, setSearch] = useState('')
  const clientId = user.client.id

  const getDatas = async () => {
    const getTeams = await getSubDocuments('clientes', clientId, 'teams')
    console.log(getTeams)
    setDataContext({ teams: getTeams })
  }

  const deleteTeams = async (id) => {
    await deleteSubDocument('clientes', clientId, 'teams', id)
    await FetchGetDatas()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Equipe Deletada`,
        msg: `A equipe foi deleta da sua base de dados.`,
      },
    ])
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const HandleDeleteTeams = async (id) => {
    await TryCatch(
      () => {
        deleteTeams(id)
      },
      setLoading,
      setAlerts,
    )
  }

  const dataFiltered =
    search !== ''
      ? (dataContext?.teams || []).filter((user) => {
          const name = user?.name || ''
          const descricao = user?.description || ''

          const searchString = `${name}|${descricao}`.toLowerCase()
          return searchString.includes(search.toLocaleLowerCase())
        })
      : dataContext?.teams || []

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetDatas()
  }, [])

  return {
    loading,
    sectors,
    search,
    setSearch,
    dataContext,
    FetchGetDatas,
    HandleDeleteTeams,
    dataFiltered,
  }
}
