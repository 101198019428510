import React, { useState } from 'react'

import { Icon } from '../../icons'

export const ImageContent = ({ className, url }) => {
  const [erroCarregamento, setErroCarregamento] = useState(false)

  return erroCarregamento ? (
    // Renderiza o fallback como um componente quando a imagem não carrega
    <div
      className="has-background-grey-lighter"
      style={{
        height: 48,
        width: 48,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon className="has-text-grey-light">user</Icon>
    </div>
  ) : (
    <img
      className={`is-rounded ${className}`}
      src={url}
      alt="Foto do colaborador"
      onError={() => setErroCarregamento(true)} // Define o erro para true caso a imagem falhe ao carregar
    />
  )
}
