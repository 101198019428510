/**
 * Função para retornar o time atual neste formato: "day/month/year hours:minutes"
 *
 * @returns {string} - Time Atual"day/month/year hours:minutes".
 */

export const DateTimeNow = () => {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0') // Os meses são baseados em zero
  const year = String(now.getFullYear()) // Pega os últimos dois dígitos do ano
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}`
}

export const DateNow = () => {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0') // Os meses são baseados em zero
  const year = String(now.getFullYear())

  return `${year}-${month}-${day}`
}

export const TimestampNow = () => {
  return Date.now()
}

export const SomarDiasAData = (dias, data) => {
  const dataInicial = new Date(data)
  dataInicial.setDate(dataInicial.getDate() + Number(dias))
  return dataInicial.toISOString().split('T')[0]
}

export const DataComTraçoParaDataComBarra = (data) => {
  const dataSplit = String(data).split('-')
  console.log('dataSplit: ', dataSplit)
  return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`
}
