export const getCollaboratorStatusType = (collaboratorStatusNumber) => {
  let statusType

  switch (collaboratorStatusNumber) {
    case 0:
      statusType = 'Em Análise'
      break
    case 1:
      statusType = 'Ativo'
      break
    case 2:
      statusType = 'Pendente'
      break
    default:
      statusType = 'Bloqueado'
      break
  }

  return statusType
}
