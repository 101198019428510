import React from 'react'

import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Select } from '../../../components/ui/select'
import { Path } from '../../../router/paths'

export const ConcentLayout = ({ values, setValues, loading, disabledList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              {' '}
              Nome{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              {' '}
              Descricão{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'descricao'}
                required={true}
                disabled={disabledList?.descricao}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              {' '}
              Termo{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'termo'}
                required={true}
                disabled={disabledList?.termo}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Assinatura
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'type_assinatura'}
                disabled={disabledList?.type_assinatura}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'check'} title={'Aceite de Termos'} />
                <Select.Option value={'digital'} title={'Assinatura Digital'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Revogar em
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'revogar'}
                disabled={disabledList?.revogar}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'30'} title={'30 Dias'} />
                <Select.Option value={'180'} title={'180 Dias'} />
                <Select.Option value={'365'} title={'365 Dias'} />
                <Select.Option value={'not_expired'} title={'Não Revogar'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AConsents} loading={loading}>
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
