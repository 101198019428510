import { Pagination } from '.'
import { Icon } from '../../icons'
import { Column } from '../../layouts/columns'
import { usePaginationFuntional } from './_hooks.js/usePaginationFunctional'

/**
 * Cria um componente de paginação funcional com controle de navegação e entrada de página.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.paginationData - Dados de paginação incluindo o total de itens e número de páginas.
 * @param {number} props.valuePage - Página atual selecionada.
 * @param {Function} props.setValuePage - Função para atualizar a página selecionada.
 * @param {Function} [props.setFetchData] - Função opcional para disparar novas buscas ao mudar de página.
 *
 * @returns {JSX.Element} Componente de paginação.
 *
 * ## Exemplo de Uso:
 * ```jsx
 * <PaginationFunctional
 *   paginationData={paginationData}
 *   valuePage={1}
 *   setValuePage={setValuePage}
 *   setFetchData={fetchData}
 * />
 * ```
 */
export const PaginationFunctional = ({ paginationData, valuePage, setValuePage, setFetchData = () => {} }) => {
  const { currentPage, totalPages, totalItems, nextPage, PreviousPage } = usePaginationFuntional(
    paginationData,
    valuePage,
    setValuePage,
    setFetchData,
  )

  return (
    <>
      <Column.Root className="has-text-centered mt-5">
        <Column.Content></Column.Content>
        <Column.Content size="is-5">
          <Pagination.Root>
            {currentPage > 1 && (
              <Pagination.Previous
                action={async () => {
                  console.log('previuos!')
                  PreviousPage()
                }}
              >
                <Icon className={'has-text-grey'}>arrowDoubleLeft</Icon>
              </Pagination.Previous>
            )}
            {currentPage < totalPages && (
              <Pagination.Next
                action={() => {
                  console.log('next!')
                  nextPage()
                }}
              >
                <Icon className={'has-text-grey'}>arrowDoubleRight</Icon>
              </Pagination.Next>
            )}

            <Pagination.List className={'is-justify-content-center'}>
              <p className="mr-3">
                <strong>{totalItems}</strong> Itens
              </p>
              <Pagination.Input
                setValue={setValuePage}
                value={valuePage}
                totalValue={totalPages}
                disabled={totalPages === 1}
                placeholder={currentPage}
              ></Pagination.Input>
              <p>de {totalPages}</p>
            </Pagination.List>
          </Pagination.Root>
        </Column.Content>
        <Column.Content></Column.Content>
      </Column.Root>
    </>
  )
}
