import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Table } from '../../../../components/ui/table'

export const ClientsFormModalInfoCandidacyLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  console.log(formContext)

  return (
    <div>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
            Data
          </Input.Label>
          <Input.ReadOnly className="is-rounded" color="is-grey">
            {formContext?.date}
          </Input.ReadOnly>
        </Column.Content>
        <Column.Content>
          <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
            Vaga
          </Input.Label>
          <Input.ReadOnly className="is-rounded" color="is-grey">
            {formContext?.positionName}
          </Input.ReadOnly>
        </Column.Content>
      </Column.Root>

      <Column.Root className="is-mobile">
        <Column.Content className="has-text-centered">
          <img
            alt="url_foto"
            src={formContext?.url_foto}
            style={{ width: '50%', borderRadius: '50%' }}
            className="has-shadow"
          ></img>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome completo
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.name}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              CPF
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.cpf}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              E-mail
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.email}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Telefone
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.contact?.celular}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content></Column.Content>
      </Column.Root>

      <p className="subtitle mt-6 has-text-grey">Dados Bancários</p>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Banco
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.payment?.banco}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Agência
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.payment?.agencia}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Conta
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.payment?.conta}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Pix
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.payment?.pix}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <p className="subtitle mt-6 has-text-grey">Contato de Emergência</p>
      <Table.Root className="is-fullwidth">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Nome</Table.Cell>
            <Table.Cell>Parentesco</Table.Cell>
            <Table.Cell>Telefone</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {formContext?.contact?.emergencia.map((contact, index) => {
            return (
              <Table.Row key={String(index) + 'contato_emergencia'}>
                <Table.Cell>{contact.nome}</Table.Cell>
                <Table.Cell>{contact.parentesco}</Table.Cell>
                <Table.Cell>{contact.celular}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </div>
  )
}
