import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Icon } from '../../../../components/icons'
import { Form } from '../../../../components/ui/form'
import { Table } from '../../../../components/ui/table'
import { Button } from '../../../../components/ui/button'
import { Select } from '../../../../components/ui/select'
import { useClientFormTeams } from '../_hooks/useClientFormTeams'

export const ClientsFormAddteams = ({ formContext, setFormContext, disabledList }) => {
  const {
    loading,
    search,
    setSearch,
    dataContext,
    infosContext,
    FetchGetDataQuery,
    HandleAddCollaboratorInTeams,
    HandleDeleteCollaboratorInTeams,
  } = useClientFormTeams()

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'name'}
                required={true}
                disabled={disabledList?.name}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Descrição
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'description'}
                disabled={disabledList?.description}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <div style={{ maxHeight: 250, minHeight: 250 }}>
        <Column.Root>
          <Column.Content size="is-4 box">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Colaboradores Favoritados
            </Input.Label>

            <Form.Root
              action={() => {
                FetchGetDataQuery()
              }}
            >
              <Input.Root className="mt-2">
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Form.Root>
            {dataContext?.data && !loading ? (
              dataContext?.data.length > 0 ? (
                <Table.Root className="is-fullwidth">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell></Table.Cell>
                      <Table.Cell className="has-text-centered">Nome</Table.Cell>
                      <Table.Cell className="has-text-centered">CPF</Table.Cell>
                      <Table.Cell className="has-text-centered">Ação</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {dataContext?.data.map((colab, index) => {
                      return (
                        <Table.Row key={`colabquery${index}`}>
                          <Table.Cell className="is-vcentered">
                            <figure className="image is-48x48 mr-3 mt-1">
                              <img className="is-rounded" src={colab.url_foto} alt="url_foto..." />
                            </figure>
                          </Table.Cell>
                          <Table.Cell className="is-vcentered">{colab.name}</Table.Cell>
                          <Table.Cell className="is-vcentered">{colab.cpf}</Table.Cell>
                          <Table.Cell className="is-vcentered">
                            <Button.Bt
                              color="is-success"
                              size={'is-small'}
                              colorText={'has-text-white'}
                              loading={loading}
                              action={() => {
                                HandleAddCollaboratorInTeams(colab)
                              }}
                            >
                              +
                            </Button.Bt>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              ) : (
                <p className="mt-5 has-text-grey">Ops, Nenhum colaborador favoritado encontrado...</p>
              )
            ) : (
              <Column.Root className="mt-5">
                <Column.Content></Column.Content>
                <Column.Content>
                  <Icon>loading</Icon>
                </Column.Content>
                <Column.Content></Column.Content>
              </Column.Root>
            )}
          </Column.Content>
          <Column.Content>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Equipe
            </Input.Label>
            <div className="ml-2  box">
              <Table.Root className="is-fullwidth">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Foto</Table.Cell>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>CPF</Table.Cell>
                    <Table.Cell>Cargo</Table.Cell>
                    <Table.Cell>Ação</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {formContext?.teams &&
                    infosContext?.positionById &&
                    Object.keys(formContext?.teams || {}).map((uuid, index) => {
                      const colab = formContext?.teams?.[uuid] || {}
                      return (
                        <Table.Row key={`teams${index}`}>
                          <Table.Cell className="is-vcentered">
                            <figure className="image is-48x48 mr-3 mt-1">
                              <img className="is-rounded" src={colab?.url_foto} alt="url_foto..." />
                            </figure>
                          </Table.Cell>
                          <Table.Cell>{colab?.name}</Table.Cell>
                          <Table.Cell>{colab?.cpf}</Table.Cell>
                          <Table.Cell>
                            <Select.Root>
                              <Select.Content
                                className="is-rounded is-fullwidth"
                                color="is-grey"
                                value={colab?.position}
                                setValue={(value) => {
                                  setFormContext((prev) => ({
                                    ...prev,
                                    teams: {
                                      ...(prev?.teams || {}),
                                      [uuid]: { ...(prev?.teams[uuid] || {}), position: value },
                                    },
                                  }))
                                }}
                              >
                                {(colab?.positions || []).map((idPosition) => {
                                  return (
                                    <Select.Option
                                      key={`cargo_${idPosition}`}
                                      value={idPosition}
                                      title={infosContext?.positionById[idPosition]}
                                    />
                                  )
                                })}
                              </Select.Content>
                            </Select.Root>
                          </Table.Cell>
                          <Table.Cell>
                            <Button.Bt
                              color="is-success"
                              size={'is-danger'}
                              colorText={'has-text-white'}
                              loading={loading}
                              action={() => {
                                HandleDeleteCollaboratorInTeams(colab)
                              }}
                            >
                              <Icon size={15}>trash</Icon>
                            </Button.Bt>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table.Root>
            </div>
          </Column.Content>
        </Column.Root>
      </div>
    </div>
  )
}
