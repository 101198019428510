/**
 * objeto que controla os caminhos de cada pagina
 *
 * Dependendo da kei passada, ele será uma function, e vc precisa passar os valores para gerar o path
 */

export const Path = {
  Login: '/',
  PasswordRecovery: 'esquecisenha',
  FirstAccess: (email) => `primeiroacesso/${email}`,
  SelectAccount: 'selecaoConta',

  // ADMINISTRATION
  AdministrationHome: '/administracao',

  // SETTINGS
  ASettings: '/administracao/configuracoes',

  // USERS
  AUsers: '/administracao/configuracoes/usuarios',
  AAddUsers: '/administracao/configuracoes/usuarios/adicionar',
  AEditUsers: (uid) => `/administracao/configuracoes/usuarios/${uid}`,

  // PLANS
  APlans: '/administracao/configuracoes/planos',
  AAddPlans: '/administracao/configuracoes/planos/adicionar',
  AEditPlans: (id) => `/administracao/configuracoes/planos/${id}`,

  // SECTORS
  ASectors: '/administracao/configuracoes/setores',
  AAddScetors: '/administracao/configuracoes/setores/adicionar',
  AEditSectors: (uid) => `/administracao/configuracoes/setores/${uid}`,

  // DOCUMENTS
  ADocuments: '/administracao/configuracoes/documentos',
  AAddDocuments: '/administracao/configuracoes/documentos/adicionar',
  AEditDocuments: (id) => `/administracao/configuracoes/documentos/${id}`,

  // CONSENTS
  AConsents: '/administracao/configuracoes/consentimentos',
  AAddConsents: '/administracao/configuracoes/consentimentos/adicionar',
  AEditConsents: (id) => `/administracao/configuracoes/consentimentos/${id}`,

  // CARGOS
  ACargos: '/administracao/configuracoes/cargos',
  AAddCargos: '/administracao/configuracoes/cargos/add',
  AEditCargos: (id) => `/administracao/configuracoes/cargos/${id}`,

  // CLIENTS
  AClients: '/administracao/clientes',
  AAdicionarClientes: '/administracao/clientes/adicionar',
  AdminClientEdit: (id) => `/administracao/clientes/editar/${id}`,

  // CONTRIBUTOR
  AdminCollaborators: '/administracao/colaboradores',
  AddAdminCollaborators: '/administracao/colaboradores/adicionar',
  EditAdminCollaborator: (id) => `/administracao/colaboradores/editar/${id}`,

  // Area do Cliente
  ClientHome: '/cliente',

  ClientSettings: '/cliente/configuracoes',

  ClientPayments: '/cliente/configuracoes/pagamentos',

  ClientInformations: '/cliente/configuracoes/informacoes',

  ClientUsers: '/cliente/configuracoes/usuarios',
  ClientAddUsers: '/cliente/configuracoes/usuarios/adicionar',
  ClientEditUsers: (uid) => `/cliente/configuracoes/usuarios/editar/${uid}`,

  ClientSectors: '/cliente/configuracoes/setores',
  ClientAddSectors: '/cliente/configuracoes/setores/adicionar',
  ClientEditSectors: (uid) => `/cliente/configuracoes/setores/editar/${uid}`,

  ClientEvents: '/cliente/eventos',
  ClientAddEvents: '/cliente/eventos/adicionar',
  ClientEditEvents: (id) => `/cliente/eventos/${id}`,
  ClientjobOffersEvents: (id) => `/cliente/eventos/${id}/vagas`,
  ClientStaffEvents: (id) => `/cliente/eventos/${id}/staff`,
  ClientHistoryEvent: (id) => `/cliente/eventos/${id}/historico`,
  ClientStaffFinally: (id) => `/cliente/eventos/${id}/finalizar`,

  ClientTeams: '/cliente/equipes',
  ClientAddTeams: '/cliente/equipes/adicionar',
  ClientEditTeams: (id) => `/cliente/equipes/editar/${id}`,
}
