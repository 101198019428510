import React from 'react'

export const InputContentIcon = ({ children, align = 'is-left' }) => {
  // zindex -1 para que o icone não fique na frente de alguns objetos na tela
  return (
    <span className={`icon ${align}`} style={{ zIndex: 1 }}>
      {children}
    </span>
  )
}
